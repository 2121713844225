import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from "@hello-pangea/dnd"
import type { FC } from "react"
import { useReducer } from "react"

import defaultImageUrl from "../../assets/img/culturespaces-squarelogo.png"
import styled from "../../styled-components"
import CECarouselItem from "./CECarouselItem"

// TYPES
export interface CarouselItem {
  id?: string
  imageUrl?: string
  alt?: string
}

interface CECarouselProps {
  items: Array<CarouselItem>
  activeId?: string | null
  droppableId: string
  isDragDisabled?: boolean
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
  onClick: (carouselItemId: string) => void
}

// STYLED COMPONENTS
export const DraggablesContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 6px;
`

const CarouselItemContainer = styled.div`
  margin-right: 8px;
`

/**
 * Horizontal scrollable list of <CECarouselItem />s that can be reordered using drag & drop
 * @param props CECarouselProps
 */
const initialState = { isDrag: false }

const CECarousel: FC<CECarouselProps> = ({
  items,
  activeId,
  droppableId,
  isDragDisabled = false,
  onDragEnd,
  onClick,
}) => {
  const [{ isDrag }, dispatch] = useReducer(
    (
      state: { isDrag: boolean },
      action: { type: "onDragStart" | "onDragEnd" }
    ) => {
      switch (action.type) {
        case "onDragStart":
          return { ...state, isDrag: true }
        case "onDragEnd":
          return { ...state, isDrag: false }
      }
    },
    initialState
  )
  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        onDragEnd(result, provided)
        dispatch({ type: "onDragEnd" })
      }}
      onDragStart={() => {
        dispatch({ type: "onDragStart" })
      }}
    >
      <Droppable droppableId={droppableId} direction="horizontal">
        {(provided) => (
          <DraggablesContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item, index) => {
              // PARAMETERS
              const id = item.id || `${droppableId}-${index}`
              const imageUrl = item.imageUrl || defaultImageUrl
              const alt = item.alt || `${droppableId} n°${index}`
              const active = id === activeId

              // RENDER
              return (
                <Draggable
                  key={id}
                  draggableId={id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => {
                    return (
                      <CarouselItemContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CECarouselItem
                          id={id}
                          imageUrl={imageUrl}
                          alt={alt}
                          active={active}
                          onClick={(id) => {
                            if (!isDrag) {
                              onClick(id)
                            }
                          }}
                        />
                      </CarouselItemContainer>
                    )
                  }}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </DraggablesContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default CECarousel
