import type { FC } from "react"
import { Link } from "react-router-dom"

import CulturespacesLogo from "../../assets/img/culturespaces-squarelogo.png"
import strings from "../../assets/strings"
import { useLogoutMutation } from "../../services/useQueries"
import "../../assets/scss/header.scss"
import styled from "../../styled-components"
import { LogOut } from "../icons"

export const CEHeader: FC = () => {
  const s = strings.CEHeader

  const { mutateAsync: logout } = useLogoutMutation()

  const handleLogout = async () => {
    await logout()
  }

  return (
    <div className="header-container">
      <div className="header-title-container">
        <div className="header-logo-container">
          <Link to="/">
            <img
              className="header-logo"
              src={CulturespacesLogo}
              alt={s.logoAlt}
              title={s.logoTitle}
            />
          </Link>
        </div>
        <h2 className="title header-title">{s.title}</h2>
      </div>

      <CustomNav>
        <CustomLink to={"/gestion/pays"}>{s.countryTitle}</CustomLink>
        <CustomLink to={"/gestion/langue"}>{s.languageTitle}</CustomLink>
        <CustomLink to={"/gestion/version"}>{s.versionTitle}</CustomLink>
        <button
          className="header-icon-btn"
          onClick={handleLogout}
          title={s.logoutTitle}
        >
          <LogOut stroke={"white"} />
        </button>
      </CustomNav>
    </div>
  )
}

const CustomLink = styled(Link)`
  font-family: Poppins-SemiBold;
  font-size: 16px;
  text-decoration: none;
  margin: 0 10px;
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 10px;
`
const CustomNav = styled.nav`
  display: flex;
  align-items: center;
`
