import { useState, type FC } from "react"
import styled from "styled-components"

import type { DtoArtwork } from "../../@types/dto/DtoObjects"
import defaultImageUrl from "../../assets/img/culturespaces-squarelogo.png"
import strings from "../../assets/strings"
import theme from "../../styled-components/theme"
import { Picture } from "../icons"
import { Image } from "./CECarouselItem"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

export const CEArtworksForTourRouteModal: FC<{
  isOpen: boolean
  artworks: DtoArtwork[]
  onClick: (artwork: DtoArtwork) => void
  onClose: () => void
}> = ({ isOpen, artworks, onClick, onClose }) => {
  const [list, setList] = useState(artworks)
  const s = strings

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={s.CEArtworksForTourRouteModal.headerTitle}
        onRequestClose={onClose}
        Icon={Picture}
      />
      <CEModalBody>
        <ListContainer>
          {list.map((artwork) => (
            <ArtworkContainer
              key={artwork.id}
              onClick={() => {
                onClick(artwork)
                setList(list.filter((item) => item.id !== artwork.id))
              }}
            >
              <Image
                src={artwork.artworkUrl || defaultImageUrl}
                style={{ backgroundColor: theme.colors.lightGrey }}
              />
              <ArtworkName>{artwork.artworkName?.fr}</ArtworkName>
              <ArtistName>{artwork.artistName}</ArtistName>
            </ArtworkContainer>
          ))}
        </ListContainer>
      </CEModalBody>
    </CEModal>
  )
}

const ListContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
`

const ArtworkContainer = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`

const ArtworkName = styled.div`
  width: 140px;
  font-size: 12px;
`

const ArtistName = styled.div`
  width: 140px;
  font-size: 12px;
  color: ${theme.colors.grey};
`
