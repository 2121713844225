import type { FC } from "react"
import { useNavigate } from "react-router-dom"

import strings from "../../assets/strings"
import styled from "../../styled-components"
import { CETextPoppins, CEButton } from "../ui"

// STYLED COMPONENTS
const Root = styled.div`
  padding: ${({ theme }) => theme.padding.root};
`

const ErrorTitleContainer = styled.div``

const ErrorButtonContainer = styled.div``

/**
 * Screen displayed when an api call `getExpositions` or `getExpositionById` fails
 */
const ErrorScreen: FC = () => {
  // STRINGS
  const s = strings.ErrorScreen
  const navigate = useNavigate()

  // FUNCTION
  const handleReconnection = () => {
    navigate("/login")
  }

  // RENDER
  return (
    <Root>
      <ErrorTitleContainer>
        <CETextPoppins>{s.errorTitle}</CETextPoppins>
      </ErrorTitleContainer>
      <ErrorButtonContainer>
        <CEButton title={s.reconnection} onClick={handleReconnection} />
      </ErrorButtonContainer>
    </Root>
  )
}

export default ErrorScreen
