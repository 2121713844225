import type { FC } from "react"

import styled from "../../styled-components"

// TYPES
interface CECarouselItemProps {
  id: string
  imageUrl: string
  alt: string
  active?: boolean
  onClick: (carouselItemId: string) => void
}

interface ImageProps {
  active?: boolean
}

// STYLED COMPONENTS
export const Image = styled.img<ImageProps>`
  width: 100px;
  height: 100px;
  object-fit: cover;

  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.secondary.main : "transparent"};
  border-radius: 10%;

  box-shadow: ${(props) =>
    props.active ? `0 0 10px ${props.theme.colors.secondary.main}` : "0"};
  overflow: hidden;

  &:hover {
    cursor: ${(props) => (props.active ? "auto" : "pointer")};
    opacity: ${(props) => (props.active ? "1" : "0.9")};
  }

  &:active {
    opacity: ${(props) => (props.active ? "1" : "0.8")};
  }

  pointer-events: ${(props) => (props.active ? "none" : "auto")};
`

/**
 * Clicable image with rounded corners
 * The image has an id, and the callback when clicked gives access to this id
 * When the "selected" prop is true, a secondary color border & shadow is displayed around the image
 * @param props {CECarouselItemProps}
 */
const CECarouselItem: FC<CECarouselItemProps> = ({
  id,
  imageUrl,
  alt,
  active = false,
  onClick,
}) => {
  // HANDLE CLICK
  const handleClick = () => {
    onClick(id)
  }

  // RENDER
  return (
    <Image src={imageUrl} alt={alt} onClick={handleClick} active={active} />
  )
}

export default CECarouselItem
