import type { FC } from "react"

import styled from "../../styled-components"
import theme from "../../styled-components/theme"
import Plus from "../icons/Plus"

// TYPES
interface CEAddButtonProps {
  onClick: () => void
  small?: boolean // For adding CEArtworkListItem or CEPlaylistListItem
}

// STYLED COMPONENTS
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary.main};
  padding: 5px;
  border-radius: 100%;
  outline: none;
  overflow: hidden;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`

/**
 * Round button including a '+' svg icon
 * By default the icon is 72 x 72 px; it can be reduced to 24 x 24 px by giving a prop small={true}
 * The default button is used to add an artwork or add a playlist
 * The small icon is used to add an anecdote or add a song
 * @param props {CEAddButtonProps}
 */
const CEAddButton: FC<CEAddButtonProps> = ({ onClick, small }) => {
  return (
    <Button onClick={onClick} type="button">
      <Plus
        width={small ? "24" : "72"}
        height={small ? "24" : "72"}
        stroke={theme.colors.white}
        strokeWidth={small ? "2" : "1.2"}
        fill={theme.colors.primary.main}
      />
    </Button>
  )
}

export default CEAddButton
