import type { FC } from "react"
import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"

import strings from "../assets/strings/fr"
import { TabActiveContext } from "../components/contexts/TabActiveContext"
import CENavigationMenu from "../components/layout/CENavigationMenu"
import { Main } from "../components/layout/Main"
import ErrorScreen from "../components/screens/ErrorScreen"
import { TabMedias } from "../components/screens/TabMedias/TabMedias"
import CETextPoppins from "../components/ui/CETextPoppins"
import { useGetExposition } from "../services/useQueries"

export const MediasPage: FC = () => {
  const { expoId } = useParams()

  const { tabActive, updateTabActive } = useContext(TabActiveContext)

  const {
    data: exposition,
    isLoading,
    isError: isErrorExposition,
  } = useGetExposition(expoId)

  useEffect(() => {
    if (tabActive !== "medias") {
      updateTabActive("medias")
    }
  }, [expoId, exposition, tabActive, updateTabActive])

  if (!exposition && isLoading) {
    return <CETextPoppins>{strings.general.loading}</CETextPoppins>
  }
  if (isErrorExposition || !exposition) {
    return <ErrorScreen />
  }

  return (
    <Main>
      <div className="row-container">
        <CENavigationMenu />
        <TabMedias exposition={exposition} isLoading={isLoading} />
      </div>
    </Main>
  )
}
