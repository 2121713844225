import type { FC } from "react"

import type { DtoTranslateObject } from "../../@types/dto/DtoObjects"
import styled, { css } from "../../styled-components"
import CETextPoppins from "./CETextPoppins"

// TYPES
interface CESongListItemProps {
  id: string
  trackArtist: string
  trackTitle?: DtoTranslateObject
  trackUrl?: string
  onClick: (id: string, trackUrl?: string) => void
}

const Root = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  padding: 12px;
  border: 1px solid black;
  border-radius: 20px;
  box-shadow: 3px 3px 4px ${(props) => props.theme.colors.shadow};

  outline: none;

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      `${props.theme.colors.secondary.light}${props.theme.alpha.buttonHover}`};
  }

  &:active {
    background-color: ${(props) =>
      `${props.theme.colors.secondary.light}${props.theme.alpha.buttonActive}`};
  }

  /* Display edit icon on hover */
  &:hover div {
    opacity: 1;
  }
`

const TextContainer = styled.div`
  min-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* TODO: MAKE ELLIPSIS WORK */
`

const titleStyles = css`
  text-transform: uppercase;
  padding-right: 10px;
`

const anecdoteExplanationStyles = css``

/**
 * List item for a single song. Display a summary of the song info, and accepts an onClick (in <TabPlaylistsForm />: open song modal)
 */
const CESongListItem: FC<CESongListItemProps> = ({
  id,
  trackArtist,
  trackTitle,
  trackUrl,
  onClick,
}) => {
  return (
    <Root onClick={() => onClick(id, trackUrl)}>
      <TextContainer>
        <CETextPoppins styles={titleStyles} fontType={"SemiBold"}>
          {trackArtist}
        </CETextPoppins>
      </TextContainer>
      <TextContainer>
        <CETextPoppins styles={anecdoteExplanationStyles}>
          {`${trackTitle?.fr || ""} / ${trackTitle?.en || ""}`}
        </CETextPoppins>
      </TextContainer>
    </Root>
  )
}

export default CESongListItem
