import { FC, FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import type {
  DtoLanguage,
  DtoLanguageSummary,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import type { ConfigurationLanguage } from "../../../pages/ManageLanguagesPage"
import {
  useCreateLanguages,
  useDeleteLanguage,
} from "../../../services/useQueries"
import { CEButton, CECountryLanguageItem, CELabel } from "../../ui"
import {
  ListContainer,
  ListItemContainer,
} from "../TabArtworks/TabArtworksForm"
import {
  InputContainer,
  InputSectionContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"

interface LanguageScreenProps {
  availableLanguages: ConfigurationLanguage
  usedLanguages: DtoLanguageSummary[]
  isLoading: boolean
}

export const LanguageScreen: FC<LanguageScreenProps> = ({
  usedLanguages,
  availableLanguages,
  isLoading,
}) => {
  const navigate = useNavigate()
  const [selectedLanguages, setSelectedLanguages] = useState<
    (DtoLanguage & {
      isUsed?: boolean
    })[]
  >([])

  const [search, setSearch] = useState("")
  const [searchLanguages, setSearchLanguages] = useState<DtoLanguage[]>([])

  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingSave } =
    useCreateLanguages()
  const { mutateAsync: mutateAsyncDelete } = useDeleteLanguage()

  const handleLanguageClick = (item: DtoLanguage) => {
    setSearchLanguages([])
    setSearch("")
    setSelectedLanguages([...selectedLanguages, item])
  }

  const handleDeleteLanguage = async (
    language: DtoLanguage | DtoLanguageSummary
  ) => {
    if (language.id) {
      await mutateAsyncDelete(language.id)
    } else {
      setSearchLanguages(
        selectedLanguages.filter((i) => i.code !== language.code)
      )
    }
  }

  const handleOnCancel = () => {
    setSelectedLanguages([])
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedLanguages.length) {
      await mutateAsyncPost(
        selectedLanguages.map((language) => ({
          code: language.code.toLocaleLowerCase(),
          label: language.label,
        }))
      )
    }
  }

  useEffect(() => {
    if (search === "") {
      setSearchLanguages([])
      return
    }

    const languages: DtoLanguage[] = []

    for (const k in availableLanguages) {
      const isAlreadyInList = [...usedLanguages, ...selectedLanguages].some(
        (i) => i.code.toLocaleLowerCase() === k.toLocaleLowerCase()
      )

      if (
        !isAlreadyInList &&
        availableLanguages[k].label
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        languages.push({
          label: availableLanguages[k].label,
          code: k,
        })
      }
      setSearchLanguages(languages)
    }
  }, [availableLanguages, search, selectedLanguages, usedLanguages])

  useEffect(() => {
    if (!isLoading && usedLanguages.length) {
      setSelectedLanguages([])
    }
  }, [usedLanguages, isLoading])

  return (
    <Root>
      <Form onSubmit={handleOnSubmit}>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={strings.TabOptions.languagesTitle}
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          <SearchInput
            type="text"
            placeholder={strings.TabOptions.searchPlaceholder.language}
            value={search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setSearch(e.currentTarget.value)
            }
          />
          {searchLanguages.length > 0 && (
            <LanguageSearchResultContainer>
              {searchLanguages.map((language) => (
                <LanguageSearchResultItem
                  key={language.code}
                  onClick={() => handleLanguageClick(language)}
                >
                  {language.label}
                </LanguageSearchResultItem>
              ))}
            </LanguageSearchResultContainer>
          )}
          <ListContainer>
            {[...usedLanguages, ...selectedLanguages].map((language) => {
              return (
                <ListItemContainer key={language.code}>
                  <CECountryLanguageItem
                    item={language}
                    key={language.code}
                    canBeDeleted={!language.isUsed}
                    onClick={(item) => handleDeleteLanguage(item)}
                  />
                </ListItemContainer>
              )
            })}
          </ListContainer>
        </InputSectionContainer>
        <ButtonsContainer>
          <CEButton title={strings.button.back} onClick={() => navigate(-1)} />
          <Separator />
          <CEButton
            title={strings.LanguagesScreen.submit}
            disabled={!selectedLanguages.length || isLoadingSave || isLoading}
          />
          <Separator />
          <CEButton
            title={strings.button.cancel}
            disabled={!selectedLanguages.length || isLoadingSave || isLoading}
            onClick={handleOnCancel}
          />
        </ButtonsContainer>
      </Form>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: ${(props) => props.theme.padding.root};
`
const Form = styled.form`
  margin: 20px 15px;
`

const SearchInput = styled.input`
  margin: 10px 0 20px 0;
  width: 350px;
  height: 30px;
  border-radius: 5px;
  padding: 5px 10px;
  border-width: 1px;
  font-family: Poppins-SemiBold, serif;
  font-size: 16px;
`
const LanguageSearchResultContainer = styled.ul`
  list-style: none;
  position: absolute;
  top: 110px;
  background-color: white;
  padding: unset;
  width: 370px;
  min-height: 100px;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 5px;
  font-family: Poppins-SemiBold, serif;
  font-size: 16px;
  z-index: 10;
`
const LanguageSearchResultItem = styled.li`
  line-height: 1.8;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background-color: #9e0231;
    color: white;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
`

const Separator = styled.div`
  margin-left: 10px;
`
