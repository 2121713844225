import type { FC, SVGProps } from "react"

import styled, { css } from "../../styled-components"
import theme from "../../styled-components/theme"
import { X } from "../icons"
import CETextPoppins from "./CETextPoppins"

// DATA
const closeIconWidth = 20

// TYPES
interface CEModalHeaderProps {
  title: string
  Icon?: FC<SVGProps<SVGSVGElement>>
  onRequestClose: () => void
  disableClose?: boolean
}

// STYLED COMPONENTS
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary.main};
  min-width: 400px;
  border: 1px solid ${(props) => props.theme.colors.primary.main};
  border-top-left-radius: ${(props) => props.theme.borderRadius.modal};
  border-top-right-radius: ${(props) => props.theme.borderRadius.modal};
  padding: 24px;
  box-shadow: 0 4px 4px ${(props) => props.theme.colors.shadow};
`

const CloseContainer = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  background-color: transparent;

  border: none;
  border-radius: 100%;
  padding: 5px;

  overflow: hidden;
  outline: none;
  opacity: ${(props) =>
    props.disabled ? props.theme.opacity.buttonDisabled : "1"};

  &:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    opacity: ${(props) =>
      props.disabled ? props.theme.opacity.buttonDisabled : "0.8"};
  }

  &:active {
    opacity: ${(props) => props.theme.opacity.buttonDisabled};
  }
`

/**
 * For an unknown reason, the button around the 24 * 24 px svg had a content of 24 * 26 px
 * This div crop its content to 24 * 24 px
 */
const CloseCrop = styled.div`
  width: ${closeIconWidth}px;
  height: ${closeIconWidth}px;
  overflow: hidden;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const headerTitleStyles = css`
  font-size: ${(props) => props.theme.fontSize.modalHeader};
  color: ${(props) => props.theme.colors.white};
`

/**
 * Modal header with custom style
 */
const CEModalHeader: FC<CEModalHeaderProps> = ({
  title,
  Icon,
  onRequestClose,
  disableClose,
}) => {
  return (
    <HeaderContainer>
      {/* CLOSE ICON */}
      <CloseContainer
        onClick={disableClose ? () => {} : onRequestClose}
        disabled={disableClose}
      >
        <CloseCrop>
          <X
            width={closeIconWidth}
            height={closeIconWidth}
            stroke={theme.colors.white}
          />
        </CloseCrop>
      </CloseContainer>

      {/* ICON */}
      {Icon && (
        <IconContainer>
          <Icon
            width="36"
            height="36"
            stroke={theme.colors.white}
            strokeWidth="1.5"
          />
        </IconContainer>
      )}

      {/* HEADER TITLE */}
      <CETextPoppins styles={headerTitleStyles}>{title}</CETextPoppins>
    </HeaderContainer>
  )
}

export default CEModalHeader
