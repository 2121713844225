import { useRecoilState } from "recoil"

import strings from "../assets/strings"
import { errorsState } from "../store/errors.store"

export type PublishExpositionError = PublishExternalError | PublishInternalError

export interface ValidationErrorMessage {
  [key: string]: string
}

interface PublishExternalError {
  title?: ValidationErrorMessage[]
  startDate?: ValidationErrorMessage[]
  endDate?: ValidationErrorMessage[]
  addressLocation?: ValidationErrorMessage[]
  addressCountry?: ValidationErrorMessage[]
  websiteUrl?: ValidationErrorMessage[]
  websiteTitle?: ValidationErrorMessage[]
  thumbnailMedia?: ValidationErrorMessage[]
}

interface PublishInternalError extends PublishExternalError {
  directors?: ValidationErrorMessage[]
  schedule?: ValidationErrorMessage[]
  media?: ValidationErrorMessage[]
  websiteUrl?: ValidationErrorMessage[]
  websiteTitle?: ValidationErrorMessage[]
  scheduleComplement?: ValidationErrorMessage[]
  latitude?: ValidationErrorMessage[]
  longitude?: ValidationErrorMessage[]
  additionalInformations?: ValidationErrorMessage[]
}

interface PublishArtworkError {
  artworkName?: ValidationErrorMessage[]
  artworkTrivia?: ValidationErrorMessage[]
  artworkLocation?: ValidationErrorMessage[]
  artwork?: ValidationErrorMessage[]
  artworkThumbnail?: ValidationErrorMessage[]
  date?: ValidationErrorMessage[]
  factualExplanation?: ValidationErrorMessage[]
  copyright?: ValidationErrorMessage[]
}

interface PublishAnecdoteError {
  title?: ValidationErrorMessage[]
  anecdoteExplanation?: ValidationErrorMessage[]
}

interface PublishSongError {
  trackArtist?: ValidationErrorMessage[]
  trackTitle?: ValidationErrorMessage[]
  trackUrl?: ValidationErrorMessage[]
}

interface PublishPlaylistError {
  media?: ValidationErrorMessage[]
  expositionMoment?: ValidationErrorMessage[]
}

type Error = PublishExpositionError & {
  artworks?: { [key: string]: PublishArtworkError }
  anecdotes?: { [key: string]: PublishAnecdoteError }
  songs?: { [key: string]: PublishSongError }
  playlist?: { [key: string]: PublishPlaylistError }
}

interface ErrorListMessage {
  exposition?: { [key: string]: string[] }
  artworks?: { [key: string]: string[] }
  anecdotes?: { [key: string]: string[] }
  songs?: { [key: string]: string[] }
  playlist?: { [key: string]: string[] }
}

export const useErrorsModal = () => {
  const [state, setstate] = useRecoilState(errorsState)

  const pushErrors = (errorsList: Error) => {
    let errorsKeys: ErrorListMessage = {}

    for (const key of Object.keys(errorsList)) {
      if (
        key === "artworks" ||
        key === "anecdotes" ||
        key === "songs" ||
        key === "playlist"
      ) {
        const errorsKeyList = errorsList[key]!
        const errors = Object.keys(errorsKeyList)
          .map((key) => errorsKeyList[key])
          .reduce((r, v) => {
            for (const k of Object.keys(v)) {
              const values = v[k as keyof typeof v]
              r = {
                ...r,
                [k]: r[k]?.length
                  ? [...new Set([...r[k], ...getErrorMessageList(values)])]
                  : getErrorMessageList(values),
              }
            }

            return r
          }, {} as { [key: string]: string[] })
        errorsKeys = { ...errorsKeys, [translateKeyErrorMessage(key)]: errors }
      } else {
        const values = errorsList[key as keyof PublishExpositionError]
        if (values) {
          const keyValues = (
            errorsKeys[key as keyof ErrorListMessage]?.length
              ? errorsKeys[key as keyof ErrorListMessage]
              : []
          ) as string[]
          errorsKeys = {
            ...errorsKeys,
            exposition: {
              ...errorsKeys.exposition,
              [key]: keyValues.length
                ? [...new Set([...keyValues, ...getErrorMessageList(values)])]
                : getErrorMessageList(values),
            },
          }
        }
      }
    }
    setstate({ isOpen: true, errors: errorsKeys })
  }

  const onClose = () => setstate((prev) => ({ ...prev, isOpen: false }))

  return {
    isOpen: state.isOpen,
    errors: state.errors,
    onClose,
    pushErrors,
  }
}

const getErrorMessageList = (value: { [key: "value" | string]: string }[]) => {
  const errorsListMessage: string[] = []

  value.forEach((v) => {
    Object.keys(v).map((key) => {
      errorsListMessage.push(
        key === "value"
          ? getErrorMessage(v[key])
          : `[${key.toUpperCase()}] ${getErrorMessage(v[key])}`
      )
    })
  })

  return errorsListMessage
}

const getErrorMessage = (codeMessage: string) => {
  switch (codeMessage) {
    case "LANGUAGE_IS_REQUIRED":
      return strings.CEValidationMessages.languageIsRequired
    case "VALUE_IS_REQUIRED":
      return strings.CEValidationMessages.fieldIsRequired
    default:
      return strings.CEValidationMessages.unknownError
  }
}

export const translateKeyErrorMessage = (key: string) => {
  return (
    strings.CEValidationMessages.keys[
      key as keyof typeof strings.CEValidationMessages.keys
    ] ?? key
  )
}
