import type { FC, FormEvent } from "react"
import { useState } from "react"
import styled, { css } from "styled-components"

import type {
  DtoArtwork,
  DtoArtworkForm,
  DtoLanguage,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings/fr"
import {
  useDeleteArtwork,
  useUpdateArtwork,
} from "../../../services/useQueries"
import {
  CEDropzone,
  CEHtmlInput,
  CEIconTooltipCode,
  CEInput,
  CELabel,
  CETextPoppins,
} from "../../ui"
import { CEConfirmModal } from "../../ui/CEConfirmModal"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
  InputSectionContainer,
  LabelWithIconContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"
import { AnecdotesListForm } from "./AnecdotesListForm"

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Root = styled.div`
  display: flex;
  position: relative;
`

export const ContainerHalfWidth = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 50%;
`
export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

export const UploadTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`

const ArtworkImageDropzoneContainer = styled.div`
  width: 300px;
  height: 400px;
`

export const ThumbnailImageDropzoneContainer = styled.div`
  width: 300px;
  height: 200px;
`

const FactualExplanationLabelContainer = styled.div`
  margin-bottom: 8px;
`

const initialArtworkFormState: DtoArtworkForm = {
  id: undefined,
  artworkLocation: undefined,
  artworkName: undefined,
  artworkTrivia: undefined,
  artistName: undefined,
  copyright: undefined,
  factualExplanation: undefined,
  date: undefined,
  anecdotesIds: [],
}

export const TabArtworksForm: FC<{
  isPublishedExposition: boolean
  artwork?: DtoArtwork
  expositionId: string
  onCancel: () => void
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
}> = ({
  artwork,
  languagesAvailable,
  isLoading,
  onCancel,
  expositionId,
  isPublishedExposition,
}) => {
  const [artworkFormState, setArtworkFormState] = useState<DtoArtworkForm>(
    artwork?.id
      ? {
          date: artwork.date,
          artworkName: artwork.artworkName,
          artworkLocation: artwork.artworkLocation,
          artworkTrivia: artwork.artworkTrivia,
          copyright: artwork.copyright,
          factualExplanation: artwork.factualExplanation,
          artistName: artwork.artistName || undefined,
          id: artwork.id,
          anecdotesIds: artwork.anecdotes?.map((a) => a.id!),
        }
      : initialArtworkFormState
  )
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  const { mutateAsync: updateArtwork, isLoading: isUpdateOnProgress } =
    useUpdateArtwork()

  const { mutateAsync: deleteArtwork } = useDeleteArtwork()
  const s = strings.TabArtworksForm

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = await updateArtwork({
      artwork: artworkFormState,
      expoId: expositionId,
    })
    setArtworkFormState({
      date: data.date,
      artworkName: data.artworkName,
      artworkLocation: data.artworkLocation,
      artworkTrivia: data.artworkTrivia,
      copyright: data.copyright,
      factualExplanation: data.factualExplanation,
      artistName: data.artistName || undefined,
      id: data.id,
      anecdotesIds: data.anecdotes?.map((a) => a.id!),
    })
  }

  return (
    <>
      <Root>
        <ContainerHalfWidth>
          <form onSubmit={onSubmit}>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.artworkNameLabel}
                  htmlFor="artworkNameFr"
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.artworkNameFrPlaceholder}
                    value={artworkFormState.artworkName?.[language.code] || ""}
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        artworkName: {
                          ...prev.artworkName,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.artistNameLabel}
                  htmlFor="artistName"
                  styles={underlinedLabelStyles}
                />
                <CEInput
                  id="artistNameInput"
                  placeholder={s.artistNamePlaceholder}
                  value={artworkFormState.artistName || ""}
                  onChange={(value) =>
                    setArtworkFormState((prev) => ({
                      ...prev,
                      artistName: value !== "" ? value : undefined,
                    }))
                  }
                />
              </InputContainer>
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel title={s.dateLabel} styles={underlinedLabelStyles} />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.dateFrPlaceholder}
                    value={artworkFormState.date?.[language.code] || ""}
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        date: {
                          ...prev.date,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.artworkTriviaLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.artworkTriviaFrPlaceholder}
                    value={
                      artworkFormState.artworkTrivia?.[language.code] || ""
                    }
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        artworkTrivia: {
                          ...prev.artworkTrivia,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.artworkLocationLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.artworkLocationFrPlaceholder}
                    value={
                      artworkFormState.artworkLocation?.[language.code] || ""
                    }
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        artworkLocation: {
                          ...prev.artworkLocation,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <AnecdotesListForm
                isPublishedExposition={isPublishedExposition}
                expositionId={expositionId}
                artworkId={artwork!.id!}
                anecdotes={artwork?.anecdotes || []}
                languagesAvailable={languagesAvailable}
                isArtworkLoading={isLoading}
              />
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.copyrightLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.copyrightFrPlaceholder}
                    value={artworkFormState.copyright?.[language.code] || ""}
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        copyright: {
                          ...prev.copyright,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.factualExplanationLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} column indent={1}>
                  <FactualExplanationLabelContainer>
                    <LabelWithIconContainer>
                      <CEIconTooltipCode
                        id={language.code}
                        tags={["br", "b", "i"]}
                        top
                      />
                      <CELabel title={language.label} />
                    </LabelWithIconContainer>
                  </FactualExplanationLabelContainer>
                  <CEHtmlInput
                    placeholder={s.copyrightFrPlaceholder}
                    value={
                      artworkFormState.factualExplanation?.[language.code] || ""
                    }
                    onChange={(value) =>
                      setArtworkFormState((prev) => ({
                        ...prev,
                        factualExplanation: {
                          ...prev.factualExplanation,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <ButtonsContainer>
              <ButtonContainer>
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault()
                    onCancel()
                  }}
                >
                  {strings.button.cancel}
                </button>
              </ButtonContainer>
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  type="submit"
                  disabled={isLoading || isUpdateOnProgress}
                >
                  {strings.button.save}
                </button>
              </ButtonContainer>{" "}
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsConfirmDeleteOpen(true)
                  }}
                  disabled={isLoading || isUpdateOnProgress}
                >
                  {strings.button.delete}
                </button>
              </ButtonContainer>
            </ButtonsContainer>
          </form>
        </ContainerHalfWidth>
        <ContainerHalfWidth>
          <UploadSection>
            <UploadTitleContainer>
              <CETextPoppins>{s.artworkImageTitle}</CETextPoppins>
            </UploadTitleContainer>

            <UploadSpecificationsContainer>
              <CETextPoppins styles={uploadSpecificationStyles}>
                {s.imageSpecifications}
              </CETextPoppins>
            </UploadSpecificationsContainer>

            <ArtworkImageDropzoneContainer>
              <CEDropzone
                type="image"
                entity={{
                  type: "artworks",
                  field: "media",
                  id: artworkFormState.id!,
                }}
                isEntityLoading={isLoading}
                defaultFileUrl={artwork!.artworkUrl}
              />
            </ArtworkImageDropzoneContainer>
          </UploadSection>

          <UploadSection>
            <UploadTitleContainer>
              <CETextPoppins>{s.artworkThumbnailImageTitle}</CETextPoppins>
            </UploadTitleContainer>

            <UploadSpecificationsContainer>
              <CETextPoppins styles={uploadSpecificationStyles}>
                {s.imageThumbnailSpecifications}
              </CETextPoppins>
            </UploadSpecificationsContainer>

            <ThumbnailImageDropzoneContainer>
              <CEDropzone
                type="image"
                entity={{
                  type: "artworks",
                  field: "mediaThumbnail",
                  id: artworkFormState.id!,
                }}
                isEntityLoading={isLoading}
                defaultFileUrl={artwork!.artworkUrlThumbnail}
              />
            </ThumbnailImageDropzoneContainer>
          </UploadSection>
        </ContainerHalfWidth>
      </Root>
      {isConfirmDeleteOpen && artworkFormState.id && (
        <CEConfirmModal
          isOpen={isConfirmDeleteOpen}
          onCancel={() => {
            setIsConfirmDeleteOpen(false)
          }}
          onConfirm={async () => {
            setIsConfirmDeleteOpen(false)
            await deleteArtwork({
              artworkId: artworkFormState.id!,
              expoId: expositionId,
            })
          }}
          title={strings.CEConfirmModal.deleteArtworkTitle}
          message={strings.CEConfirmModal.deleteArtwork}
        />
      )}
    </>
  )
}

export const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`

export const UploadSpecificationsContainer = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 8px;
  text-align: center;
`
export const uploadSpecificationStyles = css`
  font-size: ${({ theme }) => theme.fontSize.caption};
`
