import { FC, FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import type {
  DtoCountry,
  DtoCountrySummary,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import type { ConfigurationCountry } from "../../../pages/ManageCountriesPage"
import {
  useDeleteCountry,
  useSaveCountries,
  useSaveOrderCountries,
} from "../../../services/useQueries"
import { CEAlert, CEButton, CECountryLanguageItem, CELabel } from "../../ui"
import { CEVerticalDraggableList } from "../../ui/CEVerticalDraggableList"
import {
  ListContainer,
  ListItemContainer,
} from "../TabArtworks/TabArtworksForm"
import {
  InputContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"

interface CountriesScreenProps {
  availableCountries: ConfigurationCountry
  usedCountries: DtoCountrySummary[]
  isLoading: boolean
}

export const CountriesScreen: FC<CountriesScreenProps> = ({
  availableCountries,
  usedCountries,
  isLoading,
}) => {
  const navigate = useNavigate()

  const [selectedCountries, setSelectedCountries] = useState<
    (DtoCountry & { isUsed?: boolean })[]
  >([])
  const [orderedCountries, setOrderedCountries] =
    useState<DtoCountrySummary[]>(usedCountries)

  const [search, setSearch] = useState("")
  const [searchCountries, setSearchCountries] = useState<DtoCountry[]>([])
  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingSave } =
    useSaveCountries()
  const { mutateAsync: mutateAsyncDelete } = useDeleteCountry()
  const { mutateAsync: mutateAsyncSaveOrder } = useSaveOrderCountries()

  const { dispatch, isOrderChanged } = useOrderChanged()

  useEffect(() => {
    if (isOrderChanged) {
      mutateAsyncSaveOrder(orderedCountries.map((i) => i.id))
      dispatch("ORDER_SAVED")
    }
  }, [dispatch, isOrderChanged, mutateAsyncSaveOrder, orderedCountries])

  const handleDeleteCountry = async (
    country: DtoCountry | DtoCountrySummary
  ) => {
    if (country?.id) {
      await mutateAsyncDelete(country.id)
    } else {
      setSelectedCountries(
        selectedCountries.filter((i) => i.code !== country.code)
      )
    }
  }

  const handleOnDragEnd = (countries: DtoCountrySummary[]) => {
    setOrderedCountries(countries)
    dispatch("ORDER_CHANGED")
  }

  const handleCountryClick = (item: DtoCountry) => {
    setSearchCountries([])
    setSearch("")
    setSelectedCountries([...selectedCountries, item])
  }

  const handleOnCancel = () => {
    setSelectedCountries([])
  }

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedCountries.length) {
      await mutateAsyncPost(
        selectedCountries.map((item) => ({
          code: item.code,
          label: item.label,
        }))
      )
      setSelectedCountries([])
    }
  }

  useEffect(() => {
    if (search === "") {
      setSearchCountries([])
      return
    }

    const countries: DtoCountry[] = []

    for (const k in availableCountries) {
      const isAlreadyInList = [...orderedCountries, ...selectedCountries].some(
        (i) => i.code.toLocaleLowerCase() === k.toLocaleLowerCase()
      )
      if (
        !isAlreadyInList &&
        availableCountries[k].fr
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        countries.push({
          label: availableCountries[k].fr,
          code: k,
        })
      }
    }
    setSearchCountries(countries)
  }, [availableCountries, selectedCountries, search, orderedCountries])

  useEffect(() => {
    if (!isLoading) {
      setOrderedCountries(usedCountries)
    }
  }, [usedCountries, isLoading])

  return (
    <Root>
      <Form onSubmit={handleOnSubmit}>
        <InputSectionContainer>
          <InputContainer>
            <CELabel
              title={strings.TabOptions.countriesTitle}
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {!!selectedCountries.length && (
            <CEAlert
              type="info"
              message={strings.TabOptions.saveBeforeDragMessage}
            />
          )}

          <SearchInput
            type="text"
            placeholder={strings.TabOptions.searchPlaceholder.country}
            value={search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setSearch(e.currentTarget.value)
            }
          />
          {searchCountries.length > 0 && (
            <CountrySearchResultContainer>
              {searchCountries.map((country) => (
                <CountrySearchResultItem
                  key={country.code}
                  onClick={() => handleCountryClick(country)}
                >
                  {country.label}
                </CountrySearchResultItem>
              ))}
            </CountrySearchResultContainer>
          )}
          {!selectedCountries.length ? (
            <CEVerticalDraggableList
              droppableId={"droppablecountries"}
              onDragEnd={handleOnDragEnd}
              list={orderedCountries}
              renderItem={(item) => (
                <CECountryLanguageItem
                  item={item}
                  onClick={(item) => handleDeleteCountry(item)}
                  key={item.code}
                  canBeDeleted={!item.isUsed}
                />
              )}
              listIntent={0}
              containerWidth={"100%"}
            />
          ) : (
            <ListContainer>
              {[...orderedCountries, ...selectedCountries].map((item) => (
                <ListItemContainer key={item.code}>
                  <CECountryLanguageItem
                    item={item}
                    onClick={(item) => handleDeleteCountry(item)}
                    canBeDeleted={!item.isUsed}
                  />
                </ListItemContainer>
              ))}
            </ListContainer>
          )}
        </InputSectionContainer>
        <ButtonsContainer>
          <CEButton title={strings.button.back} onClick={() => navigate(-1)} />
          <Separator />
          <CEButton
            title={strings.LanguagesScreen.submit}
            disabled={!selectedCountries.length || isLoadingSave || isLoading}
          />
          <Separator />
          <CEButton
            title={strings.button.cancel}
            disabled={!selectedCountries.length || isLoadingSave || isLoading}
            onClick={handleOnCancel}
          />
        </ButtonsContainer>
      </Form>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: ${(props) => props.theme.padding.root};
`

const Form = styled.form`
  margin: 20px 15px;
`

const InputSectionContainer = styled.div`
  margin-bottom: 50px;
`

const SearchInput = styled.input`
  margin: 10px 0 20px 0;
  width: 350px;
  height: 30px;
  border-radius: 5px;
  padding: 5px 10px;
  border-width: 1px;
  font-family: Poppins-SemiBold;
  font-size: 16px;
`

const CountrySearchResultContainer = styled.ul`
  list-style: none;
  position: absolute;
  top: 110px;
  background-color: white;
  padding: unset;
  width: 370px;
  min-height: 100px;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 5px;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  z-index: 10;
`
const CountrySearchResultItem = styled.li`
  line-height: 1.8;
  padding: 5px 10px;
  cursor: pointer;
  :hover {
    background-color: #9e0231;
    color: white;
  }
`
const ButtonsContainer = styled.div`
  margin-top: 10px;
  display: flex;
`

const Separator = styled.div`
  margin-left: 10px;
`
