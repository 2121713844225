import type { FC } from "react"

import strings from "../../assets/strings"
import { Picture } from "../icons"
import {
  ButtonsContainer,
  InputSectionContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"
import { CETextPoppins } from "./index"

export const CEConfirmModal: FC<{
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  title: string
  message: string
}> = ({ isOpen, onCancel, onConfirm, message, title }) => {
  return (
    <CEModal isOpen={isOpen} onRequestClose={onCancel}>
      <CEModalHeader title={title} onRequestClose={onCancel} Icon={Picture} />
      <CEModalBody>
        <InputSectionContainer>
          <CETextPoppins>{message}</CETextPoppins>
        </InputSectionContainer>
        <ButtonsContainer>
          <button className={"btn"} onClick={onCancel}>
            {strings.button.cancel}
          </button>
          <button className={"btn"} onClick={onConfirm}>
            {strings.button.confirm}
          </button>
        </ButtonsContainer>
      </CEModalBody>
    </CEModal>
  )
}
