import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import type {
  DtoAnecdote,
  DtoArtworkForm,
  DtoCountry,
  DtoCreateExposition,
  DtoDraftExpositionForm,
  DtoExpositionForm,
  DtoLanguage,
  DtoLogin,
  DtoPlaylist,
  DtoSong,
  EntityField,
  EntityType,
  ExpositionStatusType,
} from "../@types/dto/DtoObjects"
import { useAuth } from "../hooks/useAuth"
import { useErrorsModal } from "../hooks/useErrorsModal"
import { useFlashNotification } from "../hooks/useFlashNotification"
import {
  anecdoteApi,
  artworkApi,
  authApi,
  countryApi,
  expositionApi,
  fileApi,
  fullVideosApi,
  languageApi,
  playlistApi,
  songApi,
  tourRouteApi,
  versionApi,
} from "./api"

export const queryKey = {
  getExpos: "getExpos",
  getCountries: "getCountries",
  getCountry: "getCountry",
  getLanguages: "getLanguages",
  getCurrentVersion: "getCurrentVersion",
  getExposition: "getExposition",
  getFullVideos: "getFullVideos",
  getTourRoutes: "getTourRoutes",
}

// QUERIES
export const useGetAllLanguages = () =>
  useQuery(
    [queryKey.getLanguages],
    async () => await languageApi.fetchLanguages()
  )

export const useGetCurrentVersion = () =>
  useQuery([queryKey.getCurrentVersion], async () => await versionApi.get())

export const useGetAllCountries = () =>
  useQuery([queryKey.getCountries], async () => await countryApi.getAll())

export const useGetAllExpositions = () =>
  useQuery([queryKey.getExpos], async () => await expositionApi.getAll())

export const useGetExposition = (id?: string) =>
  useQuery(
    [queryKey.getExposition, id],
    async () =>
      id ? await expositionApi.fetchExposition(id) : Promise.resolve(undefined),

    { enabled: !!id }
  )

export const useGetFullVideos = (expoId?: string) =>
  useQuery(
    [queryKey.getFullVideos, expoId],
    async () =>
      expoId ? await fullVideosApi.getAll(expoId) : Promise.resolve(undefined),

    { enabled: !!expoId }
  )

export const useGetTourRoutes = (expoId?: string) =>
  useQuery(
    [queryKey.getTourRoutes, expoId],
    async () =>
      expoId ? await tourRouteApi.getAll(expoId) : Promise.resolve(undefined),
    { enabled: !!expoId }
  )

// MUTATIONS

export const useLoginMutation = () => {
  const { login } = useAuth()
  const navigate = useNavigate()

  return useMutation((credentials: DtoLogin) => authApi.login(credentials), {
    onSuccess: (data) => {
      login(data)
      navigate("/", { replace: true })
    },
  })
}

export const useLogoutMutation = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()

  return useMutation(authApi.logout, {
    onSuccess: () => {
      logout()
      navigate("/", { replace: true })
    },
    onError: () => {
      logout()
      navigate("/", { replace: true })
    },
  })
}

export const useSaveCountries = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (countries: DtoCountry[]) =>
      await countryApi.postCountries(countries),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getCountries])
        notification({
          type: "success",
          entity: "country",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "country",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useDeleteCountry = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (countryId: string) => await countryApi.delete(countryId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getCountries])
        notification({
          type: "success",
          entity: "country",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "country",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSaveOrderCountries = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (countryIds: string[]) => await countryApi.saveOrder(countryIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getCountries])
        notification({
          type: "success",
          entity: "country",
          action: "update",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "country",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useDuplicateExposition = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (id: string) => await expositionApi.duplicateExposition(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        notification({
          type: "success",
          entity: "exposition",
          action: "duplicate",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "exposition",
          action: "duplicate",
          error,
        })
      },
    }
  )
}

export const useCreateLanguages = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (languages: DtoLanguage[]) =>
      await languageApi.postLanguage(languages),

    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getLanguages])
        notification({
          type: "success",
          entity: "language",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "language",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useUpdateMinimumVersion = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()

  return useMutation(
    async (version: string) => await versionApi.update(version),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getCurrentVersion])
        notification({
          type: "success",
          entity: "version",
          action: "update",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "version",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useDeleteLanguage = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (languageId: string) => await languageApi.delete(languageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getLanguages])
        notification({
          type: "success",
          entity: "language",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "language",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSavePublishedExposition = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    async ({
      exposition,
      isExternal,
    }: {
      exposition: DtoExpositionForm
      isExternal: boolean
    }) => await expositionApi.putPublishedExposition(exposition, isExternal),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "exposition",
          action: "update",
        })
      },
      onError: (error: any) => {
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "exposition",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useSaveDraftExposition = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async ({
      exposition,
      isExternal,
    }: {
      exposition: DtoDraftExpositionForm
      isExternal: boolean
    }) => await expositionApi.putDraftExposition(exposition, isExternal),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "exposition",
          action: "update",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "exposition",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useSaveExpositionLanguages = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ id, languageIds }: { id: string; languageIds: string[] }) =>
      expositionApi.putExpositionLanguages(id, languageIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "exposition",
          action: "update",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "exposition",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useCreateExposition = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (exposition: DtoCreateExposition) =>
      await expositionApi.postExposition(exposition),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        notification({
          type: "success",
          entity: "exposition",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "exposition",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useUploadFile = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async ({
      formData,
      entity,
    }: {
      formData: FormData
      entity: { type: EntityType; field: EntityField; id: string }
    }) => {
      if (entity.field === "fullVideos") {
        await fullVideosApi.upload(formData, entity.id)
      } else {
        await fileApi.upload(formData, entity)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getExposition"])
        queryClient.invalidateQueries(["getFullVideos"])
        notification({
          type: "success",
          entity: "file",
          action: "upload",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "file",
          action: "upload",
          error,
        })
      },
    }
  )
}

export const useUpdateVideoTitle = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async ({
      videoId,
      title,
      order,
    }: {
      videoId: string
      title: string
      order: string
    }) => {
      await fullVideosApi.update(videoId, title, order)
      await fullVideosApi.delete(videoId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getFullVideos"])
        notification({
          type: "success",
          entity: "file",
          action: "upload",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "file",
          action: "upload",
          error,
        })
      },
    }
  )
}

export const useDeleteFullVideo = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    async (videoId: string) => await fullVideosApi.delete(videoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getFullVideos"])
        notification({
          type: "success",
          entity: "file",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "file",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSaveNewArtwork = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ newArtwork, expoId }: { newArtwork: DtoArtworkForm; expoId: string }) =>
      artworkApi.create(newArtwork, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "artwork",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "artwork",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useUpdateArtwork = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    ({ artwork, expoId }: { artwork: DtoArtworkForm; expoId: string }) =>
      artworkApi.update(artwork, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "artwork",
          action: "update",
        })
      },
      onError: (error: any) => {
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "artwork",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useDeleteArtwork = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ artworkId, expoId }: { artworkId: string; expoId: string }) =>
      artworkApi.delete(artworkId, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "artwork",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "artwork",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSaveExpositionArtworksOrder = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      expositionId,
      artworkIds,
    }: {
      expositionId: string
      artworkIds: string[]
    }) => expositionApi.updateArtworksOrder(expositionId, { artworkIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "artwork",
          action: "updateOrder",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "artwork",
          action: "updateOrder",
          error,
        })
      },
    }
  )
}

export const useSaveExpositionPlaylistOrder = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      expositionId,
      playlistIds,
    }: {
      expositionId: string
      playlistIds: string[]
    }) => expositionApi.updatePlaylistsOrder(expositionId, { playlistIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "playlist",
          action: "updateOrder",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "playlist",
          action: "updateOrder",
          error,
        })
      },
    }
  )
}

export const useSaveTourRoutesOrder = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()

  return useMutation(
    ({
      expositionId,
      tourRoutesOrdered,
    }: {
      expositionId: string
      tourRoutesOrdered: { courseId: string; order: number }[]
    }) => tourRouteApi.updateTourRoutesOrder(expositionId, tourRoutesOrdered),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "tourRoute",
          action: "updateOrder",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "tourRoute",
          action: "updateOrder",
          error,
        })
      },
    }
  )
}

export const useSaveArtworkAnecdotesOrder = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      expositionId,
      artworkId,
      anecdoteIds,
    }: {
      expositionId: string
      artworkId: string
      anecdoteIds: string[]
    }) =>
      artworkApi.updateAnecdotesOrder(expositionId, artworkId, { anecdoteIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "anecdote",
          action: "updateOrder",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "anecdote",
          action: "updateOrder",
          error,
        })
      },
    }
  )
}

export const useSaveAnecdote = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    ({
      anecdote,
      expositionId,
      artworkId,
    }: {
      anecdote: DtoAnecdote
      expositionId: string
      artworkId: string
    }) => anecdoteApi.save(anecdote, artworkId, expositionId),
    {
      onSuccess: (_, params) => {
        const isUpdate = !!params.anecdote.id
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "anecdote",
          action: !isUpdate ? "create" : "update",
        })
      },
      onError: (error: any, params) => {
        const isUpdate = params.anecdote.id
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "anecdote",
          action: !isUpdate ? "create" : "update",
          error,
        })
      },
    }
  )
}

export const useDeleteAnecdote = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      anecdoteId,
      expositionId,
      artworkId,
    }: {
      anecdoteId: string
      expositionId: string
      artworkId: string
    }) => anecdoteApi.delete(anecdoteId, artworkId, expositionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "anecdote",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "anecdote",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useChangeExpoStatus = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    async ({ id, status }: { id: string; status: ExpositionStatusType }) =>
      await expositionApi.switchStatus(id, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "expositionStatus",
          action: "update",
        })
      },
      onError: (error: any) => {
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }

        notification({
          type: "errors",
          entity: "expositionStatus",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useSaveNewPlaylist = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ newPlaylist, expoId }: { newPlaylist: DtoPlaylist; expoId: string }) =>
      playlistApi.create(newPlaylist, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "playlist",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "playlist",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useUpdatePlaylist = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    ({ playlist, expoId }: { playlist: DtoPlaylist; expoId: string }) =>
      playlistApi.update(playlist, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "playlist",
          action: "update",
        })
      },
      onError: (error: any) => {
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "playlist",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useDeletePlaylist = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ playlistId, expoId }: { playlistId: string; expoId: string }) =>
      playlistApi.delete(playlistId, expoId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExpos])
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "playlist",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "playlist",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSavePlaylistSongOrder = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      expositionId,
      playlistId,
      songIds,
    }: {
      expositionId: string
      playlistId: string
      songIds: string[]
    }) =>
      playlistApi.updateSongsOrder(expositionId, playlistId, {
        songIds,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "song",
          action: "updateOrder",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "song",
          action: "updateOrder",
          error,
        })
      },
    }
  )
}

export const useSaveSong = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()

  return useMutation(
    ({
      song,
      expositionId,
      playlistId,
    }: {
      song: DtoSong
      expositionId: string
      playlistId: string
    }) => songApi.save(song, playlistId, expositionId),
    {
      onSuccess: (_, params) => {
        const isUpdate = !!params.song.id
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "song",
          action: isUpdate ? "update" : "create",
        })
      },
      onError: (error: any, params) => {
        const isUpdate = !!params.song.id
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "song",
          action: isUpdate ? "update" : "create",
          error,
        })
      },
    }
  )
}

export const useDeleteSong = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({
      songId,
      expositionId,
      playlistId,
    }: {
      songId: string
      expositionId: string
      playlistId: string
    }) => songApi.delete(songId, playlistId, expositionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getExposition])
        notification({
          type: "success",
          entity: "song",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "song",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useSaveNewTourRoute = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ formData }: { formData: FormData }) => tourRouteApi.create(formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getTourRoutes])
        notification({
          type: "success",
          entity: "tourRoute",
          action: "create",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "tourRoute",
          action: "create",
          error,
        })
      },
    }
  )
}

export const useUpdateTourRoute = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  const { pushErrors } = useErrorsModal()
  return useMutation(
    ({ formData, tourRouteId }: { formData: FormData; tourRouteId: string }) =>
      tourRouteApi.update(formData, tourRouteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getTourRoutes])
        notification({
          type: "success",
          entity: "tourRoute",
          action: "update",
        })
      },
      onError: (error: any) => {
        if (
          error &&
          error.data?.data &&
          error.message === "FORM_VALIDATION_FAILED"
        ) {
          pushErrors(error.data.data)
        }
        notification({
          type: "errors",
          entity: "tourRoute",
          action: "update",
          error,
        })
      },
    }
  )
}

export const useDeleteTourRoute = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ tourRouteId }: { tourRouteId: string }) =>
      tourRouteApi.delete(tourRouteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getTourRoutes])
        notification({
          type: "success",
          entity: "tourRoute",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "tourRoute",
          action: "delete",
          error,
        })
      },
    }
  )
}

export const useDeleteArtworkTourRoute = () => {
  const queryClient = useQueryClient()
  const notification = useFlashNotification()
  return useMutation(
    ({ tourRouteId, artworkId }: { tourRouteId: string; artworkId: string }) =>
      tourRouteApi.deleteArtwork(tourRouteId, artworkId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.getTourRoutes])
        notification({
          type: "success",
          entity: "artwork",
          action: "delete",
        })
      },
      onError: (error: any) => {
        notification({
          type: "errors",
          entity: "artwork",
          action: "delete",
          error,
        })
      },
    }
  )
}
