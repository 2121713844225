import { useReducer } from "react"

interface OrderChangedState {
  isOrderChanged: boolean
}

type ACTION = "ORDER_CHANGED" | "ORDER_SAVED" | "ORDER_CANCELED"

export const useOrderChanged = () => {
  const initialState: OrderChangedState = { isOrderChanged: false }

  const [{ isOrderChanged }, dispatch] = useReducer(
    (state: OrderChangedState, action: ACTION) => {
      switch (action) {
        case "ORDER_CHANGED": {
          return {
            isOrderChanged: true,
          }
        }
        case "ORDER_CANCELED":
        case "ORDER_SAVED": {
          return {
            isOrderChanged: false,
          }
        }
        default:
          return state
      }
    },
    initialState
  )

  return {
    dispatch,
    isOrderChanged,
  }
}
