import type { FC, SVGProps } from "react"

import styled, { css } from "../../styled-components"
import CETextPoppins from "./CETextPoppins"

// TYPES
interface CENavigationMenuButtonProps {
  title: string
  Icon?: FC<SVGProps<SVGSVGElement>> // React component returning a svg, and that can have props to overload the svg behavior
  selected?: boolean // True if the user is focusing this tab of the <CENavigationMenu />
  onClick: () => void
}

interface ButtonProps {
  selected?: boolean
}

// STYLED COMPONENTS
const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: flex-end;
  width: 100%;

  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary.main : "transparent"};
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white
      : props.theme.colors.primary.main};

  padding: 10px;
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.colors.primary.main : "transparent"};
  border-radius: 10px;
  outline: none;
  pointer-events: ${(props) => (props.selected ? "none" : "auto")};

  &:hover {
    cursor: ${(props) => (props.selected ? "auto" : "pointer")};
    border: 1px solid ${(props) => props.theme.colors.primary.main};
  }

  &:active {
    opacity: ${(props) => (props.selected ? 1 : 0.8)};
  }
`

const IconContainer = styled.div`
  margin-right: 8px;
`

const TextStyles = css`
  font-size: ${(props) => props.theme.fontSize.button};
  text-transform: uppercase;
`

/**
 * Button with custom style and eventually an icon on the left
 * This button is used in <CENavigationMenu /> to navigate between the different tabs of an exposition (general, medias, artworks, playlists)
 * @param props {CENavigationMenuButtonProps}
 */
const CENavigationMenuButton: React.FC<CENavigationMenuButtonProps> = ({
  title,
  Icon,
  selected,
  onClick,
}) => {
  return (
    <Button selected={selected} onClick={onClick}>
      {Icon && (
        <IconContainer>
          <Icon strokeWidth="1.5" />
        </IconContainer>
      )}
      <CETextPoppins styles={TextStyles}>{title}</CETextPoppins>
    </Button>
  )
}

export default CENavigationMenuButton
