import type { FC } from "react"

import "../assets/scss/login.scss"
import { Login } from "../components/screens/Login"

export const LoginPage: FC = () => {
  return (
    <div className="login-root">
      <Login />
    </div>
  )
}
