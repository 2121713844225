// French strings, keys by "other" then "screens", then "ui components", then in alphabetic order

const fr = {
  button: {
    edit: "Modifier",
    save: "Enregistrer",
    cancel: "Annuler",
    back: "Retour",
    delete: "Supprimer",
    duplicate: "Dupliquer l'exposition",
    confirm: "Confirmer",
    validate: "Valider",
  },
  date: {
    locale: "fr-FR",
    from: "Du",
    fromWithoutEnd: "A partir du",
    to: "au",
  },
  general: {
    loading: "Chargement...",
  },
  placeholder: {
    noTitle: "Nom...",
    noDate: "Dates...",
    noLocation: "Lieu...",
  },
  fieldsValidation: {
    expoTitle: "Une exposition doit avoir un titre (fr, en, & nl)",
    playlists:
      "Une erreur est survenue. Essayez d'ajouter une playlist à l'exposition puis de la supprimer", // If songs is not an array (should never happen)
    playlistExpoMoment:
      "Une playlist nécessite un moment de l'exposition (fr, en, & nl)",
    playlistSongs:
      "Une erreur est survenue. Essayez d'ajouter une musique à chaque playlist puis de les supprimer", // If songs is not an array (should never happen)
    songTrackArtist: "L'artiste d'une musique est obligatoire.",
    songTrackTitle: "Le titre d'une musique est obligatoire.",
    artworks:
      "Une erreur est survenue. Essayez d'ajouter une oeuvre à l'exposition puis de la supprimer", // If songs is not an array (should never happen)
    artworkUrl: "L'image d'une oeuvre est obligatoire",
    artworkUrlThumbnail: "La miniature de l'image d'une oeuvre est obligatoire",
    artworkName: "Le nom d'une oeuvre est obligatoire",
    artworkArtistName: "Le nom de l'artiste d'une oeuvre est obligatoire",
    artworkAnecdotes:
      "Une erreur est survenue. Essayez d'ajouter une anecdote à cette oeuvre puis de la supprimer, ou d'en modifier une", // If songs is not an array (should never happen)
    anecdoteTitle: "Le titre d'une anecdote est obligatoire",
    anecdoteExplanation: "Le contenu d'une anecdote est obligatoire",
    usefulInformations:
      "Une erreur est survenue. Essayez de modifier le champ horaire de l'exposition", // If songs is not an array (should never happen)
    schedule: "Les horaires d'ouverture sont obligatoires",
    expoMedia: "L'image de l'exposition est obligatoire",
    expoThumbnailMedia:
      "La miniature de l'image de l'exposition est obligatoire",
    expoStartDate: "La date de début de l'exposition est obligatoire",
    expoAddress:
      "Une erreur est survenue. Essayer de modifier le champ pays de l'exposition", // If songs is not an array (should never happen)
    country: "Le pays de l'exposition est obligatoire",
    expoAddressLocation: "Le lieu est obligatoire",
    expoPhoneNumber:
      "Le numéro de téléphone de l'exposition est obligatoire. Si aucun numéro n'est disponible, indiquer (pas de téléphone) / (no phone number)",
  },
  ErrorScreen: {
    errorTitle: "Une erreur est survenue, essayez de vous reconnecter.",
    reconnection: "Se reconnecter",
  },
  Home: {
    searchLabel: "Rechercher une exposition :",
    searchPlaceholder: "Titre ou lieu...",
    displayArchived: "Afficher les expositions archivées (en bas de page)",
    noResult: "Aucune exposition de correspond à cette recherche.",
    expoChoices: {
      all: "Toutes",
      internal: "Internes",
      external: "Partenaires",
    },
  },
  Login: {
    loginTitle: "Culturespaces",
    usernameLabel: "Identifiant :",
    usernamePlaceholder: "...",
    passwordLabel: "Mot de passe :",
    passwordPlaceholder: "...",
    loginError: "Une erreur est survenue, veuillez réessayer.",
  },
  TabArtworksArtworks: {
    tabTitle: "Liste des oeuvres",
    edit: "Sauvegarder l'ordre des oeuvres",
    dragAndDropInfo:
      "Vous pouvez désormais modifier l'ordre d'affichage des oeuvres dans l'application en déplaçant les miniatures des oeuvres ci-dessus.",
  },
  TabLanguages: {
    languagesTitle: "Choix des langues utilisées dans cette exposition",
    canNotBeUpdateMessage:
      "Vous ne pouvez pas modifier les langues d'une exposition publiée",
  },
  TabOptions: {
    countriesTitle: "Choix des pays utilisés dans l'application",
    languagesTitle: "Choix des langues utilisées dans l'application",
    versionTitle: "Version minimum actuelle de l'application mobile :",
    versionField: "Version minimum de l'application mobile : ",
    versionPlaceholder: "X.X.X",
    saveBeforeDragMessage:
      "Vous devez sauvegarder le(s) nouveau(x) pays avant d'en changer l'ordre",
    searchPlaceholder: {
      language: "Saisissez une langue",
      country: "Saisissez un pays",
    },
  },
  TabArtworksForm: {
    artworkNameLabel: "Titre :",
    artworkNameFrLabel: "Français :",
    artworkNameFrPlaceholder: "...",
    artworkNameEnLabel: "Anglais :",
    artworkNameEnPlaceholder: "...",
    artworkNameNlLabel: "Néerlandais :",
    artworkNameNlPlaceholder: "...",
    artistNameLabel: "Artiste :",
    artistNamePlaceholder: "...",
    dateLabel: "Date :",
    dateFrLabel: "Français :",
    dateFrPlaceholder: "...",
    dateEnLabel: "Anglais :",
    dateEnPlaceholder: "...",
    dateNlLabel: "Néerlandais :",
    dateNlPlaceholder: "...",
    artworkTriviaLabel: "Trivia :",
    artworkTriviaFrLabel: "Français :",
    artworkTriviaFrPlaceholder: "...",
    artworkTriviaEnLabel: "Anglais :",
    artworkTriviaEnPlaceholder: "...",
    artworkTriviaNlLabel: "Néerlandais :",
    artworkTriviaNlPlaceholder: "...",
    artworkLocationLabel: "Emplacement :",
    artworkLocationFrLabel: "Français :",
    artworkLocationFrPlaceholder: "...",
    artworkLocationEnLabel: "Anglais :",
    artworkLocationEnPlaceholder: "...",
    artworkLocationNlLabel: "Néerlandais :",
    artworkLocationNlPlaceholder: "...",
    copyrightLabel: "Copyright :",
    copyrightFrLabel: "Français :",
    copyrightFrPlaceholder: "...",
    copyrightEnLabel: "Anglais :",
    copyrightEnPlaceholder: "...",
    copyrightNlLabel: "Néerlandais :",
    copyrightNlPlaceholder: "...",
    factualExplanationLabel: "En savoir plus sur l'oeuvre :",
    factualExplanationFrLabel: "Français :",
    factualExplanationFrPlaceholder: "...",
    factualExplanationEnLabel: "Anglais :",
    factualExplanationEnPlaceholder: "...",
    factualExplanationNlLabel: "Néerlandais :",
    factualExplanationNlPlaceholder: "...",
    anecdotesLabel: "Anecdotes :",
    anecdoteReorderButtonDownTitle: "Déplacer l'anecdote plus bas",
    anecdoteReorderButtonUpTitle: "Déplacer l'anecdote plus haut",
    anecdotesUpdateSpecifications:
      "Pour modifier une anecdote qui vient d'être créée, commencez par l'enregistrer dans l'oeuvre",
    artworkImageTitle: "Image de l'oeuvre :",
    artworkThumbnailImageTitle: "Miniature de l'oeuvre :",
    defaultArtistName: "Nom de l'artiste (obligatoire)",
    imageSpecifications:
      "Utilisez une image au format JPEG de taille inférieure à 1.5 Mo.",
    imageThumbnailSpecifications:
      "Utilisez une image au format JPEG de taille inférieure à 50 Ko. Redimensionnez au format paysage si besoin.",
    customErrorMessage:
      "Avez-vous bien renseigné le nom de l'artiste ? (obligatoire)",
  },
  TabGeneralForm: {
    titleLabel: "Titre de l'exposition :",
    titleFrLabel: "Français :",
    titleFrPlaceholder: "...",
    titleEnLabel: "Anglais :",
    titleEnPlaceholder: "...",
    titleNlLabel: "Néerlandais :",
    titleNlPlaceholder: "...",
    dateFrom: "Du",
    dateTo: "au",
    directorsLabel: "Réalisateurs :",
    directorsFrLabel: "Français :",
    directorsFrPlaceholder: "...",
    directorsEnLabel: "Anglais :",
    directorsEnPlaceholder: "...",
    directorsNlLabel: "Néerlandais :",
    directorsNlPlaceholder: "...",
    playlistUrlLabel: "Url de la playlist de l'exposition :",
    playlistUrlPlaceholder: "...",
    feedbackUrlLabel: "Url du livre d'or :",
    feedbackUrlPlaceholder: "...",
    locationLabel: "Lieu :",
    autocompleteInfo: "(recherchez le lieu sur la droite)",
    locationNameLabel: "Nom :",
    locationNamePlaceholder: "...",
    addressLabel: "Adresse :",
    streetNumberLabel: "Numéro de rue :",
    streetNumberPlaceholder: "...",
    streetNameLabel: "Rue :",
    streetNamePlaceholder: "...",
    cityLabel: "Ville :",
    cityPlaceholder: "...",
    zipCodeLabel: "Code postal :",
    zipCodePlaceholder: "...",
    countryLabel: "Pays :",
    countryPlaceholder: "...",
    latitudeLabel: "Latitude :",
    latitudePlaceholder: "...",
    longitudeLabel: "Longitude :",
    longitudePlaceholder: "...",
    phoneNumberLabel: "Téléphone :",
    phoneNumberPlaceholder: "...",
    websiteLabel: "Site internet :",
    websiteUrlLabel: "Adresse :",
    websiteUrlFrLabel: "Français :",
    websiteUrlFrPlaceholder: "...",
    websiteUrlEnLabel: "Anglais :",
    websiteUrlEnPlaceholder: "...",
    websiteUrlNlLabel: "Néerlandais :",
    websiteUrlNlPlaceholder: "...",
    websiteDisplayLabel: "Affichage :",
    websiteDisplayFrLabel: "Français :",
    websiteDisplayFrPlaceholder: "...",
    websiteDisplayEnLabel: "Anglais :",
    websiteDisplayEnPlaceholder: "...",
    websiteDisplayNlLabel: "Néerlandais :",
    websiteDisplayNlPlaceholder: "...",
    scheduleLabel: "Horaires :",
    scheduleFrLabel: "Français :",
    scheduleFrPlaceholder: "...",
    scheduleEnLabel: "Anglais :",
    scheduleEnPlaceholder: "...",
    scheduleNlLabel: "Néerlandais :",
    scheduleNlPlaceholder: "...",
    scheduleComplementLabel: "Complément d'horaires :",
    scheduleComplementFrLabel: "Français :",
    scheduleComplementFrPlaceholder: "...",
    scheduleComplementEnLabel: "Anglais :",
    scheduleComplementEnPlaceholder: "...",
    scheduleComplementNlLabel: "Néerlandais :",
    scheduleComplementNlPlaceholder: "...",
    additionalInformationsLabel: "Informations complémentaires :",
    additionalInformationsFrLabel: "Français :",
    additionalInformationsFrPlaceholder: "...",
    additionalInformationsEnLabel: "Anglais :",
    additionalInformationsEnPlaceholder: "...",
    additionalInformationsNlLabel: "Néerlandais :",
    additionalInformationsNlPlaceholder: "...",
    newCountryNotFound: "Ce pays n'existe pas dans la liste proposée.",
    newCountryPrefix: "Souhaitez vous créer le pays: ",
  },
  TabMediasForm: {
    imageLabel: "Image de l'exposition :",
    imageSpecifications:
      "Utilisez une image au format JPEG d'une taille inférieure à 100 Ko. Cette image est uniquement affichée sur l'accueil d'une exposition, le temps que la vidéo charge.",
    imageThumbnailLabel: "Miniature de l'exposition :",
    imageThumbnailSpecifications:
      "Utilisez une image au format JPEG d'une taille inférieure à 100 Ko. Utilisez le même fichier que pour l'image de l'exposition, éventuellement recadré.",
    videoLabel: "Vidéo courte de l'exposition :",
    videoSpecifications:
      "Utilisez une vidéo sans son au format MP4 d'une taille inférieure à 5 Mo et au format maximum largeur x hauteur : 480 x 720.",
    fullVideoLabel: "Vidéo complète de l'exposition :",
    fullVideoSpecifications:
      "Utilisez une vidéo avec son au format MP4 d'une taille inférieure à 50 Mo et au format maximum largeur x hauteur : 480 x 720.",
    title: "Titre",
    addVideo: " + Ajouter une vidéo",
    saveVideo: "Sauvegarder la vidéo",
    save: "Enregistrer",
    delete: "Supprimer la vidéo",
    edit: "Modifier l'ordre des vidéos",
    socialTitle: "Médias sociaux :",
    facebookLabel: "Facebook :",
    facebookPlaceholder: "...",
    twitterLabel: "Twitter :",
    twitterPlaceholder: "...",
    instagramLabel: "Instagram :",
    instagramPlaceholder: "...",
    tiktokLabel: "Tiktok :",
    tiktokPlaceholder: "...",
    hashtagLabel: "Hashtag :",
    hashtagPlaceholder: "...",
    purchaseProcessUrlLabel: "Billetterie :",
    purchaseProcessUrlPlaceholder: "...",
  },
  TabPlaylistsForm: {
    expoMomentLabel: "Moment de l'exposition :",
    expoMomentFrLabel: "Français :",
    expoMomentFrPlaceholder: "...",
    expoMomentEnLabel: "Anglais :",
    expoMomentEnPlaceholder: "...",
    expoMomentNlLabel: "Néerlandais :",
    expoMomentNlPlaceholder: "...",
    songsLabel: "Musiques :",
    songsUpdateSpecifications:
      "Pour modifier une musique qui vient d'être créée, commencez par l'enregistrer dans l'oeuvre",
    songReorderButtonDownTitle: "Déplacer la musique plus bas",
    songReorderButtonUpTitle: "Déplacer la musique plus haut",
    playlistImageTitle: "Image de la playlist :",
    playlistImageSpecifications:
      "Utilisez une image au format JPEG de taille inférieure à 100 Ko. Redimensionnez au format paysage.",
  },
  TabPlaylistsPlaylists: {
    tabTitle: "Liste des playlists",
    edit: "Modifier l'ordre des playlists",
    dragAndDropInfo:
      "Vous pouvez désormais modifier l'ordre d'affichage des playlists dans l'application en déplaçant les miniatures des playlists ci-dessus.",
  },
  TabTourRoutesList: {
    tabTitle: "Liste des parcours",
    edit: "Modifier l'ordre des parcours",
  },
  TabTourRoutesForm: {
    expoMomentLabel: "Moment de l'exposition :",
    expoMomentFrLabel: "Français :",
    expoMomentFrPlaceholder: "...",
    expoMomentEnLabel: "Anglais :",
    expoMomentEnPlaceholder: "...",
    expoMomentNlLabel: "Néerlandais :",
    expoMomentNlPlaceholder: "...",
    artworksLabel: "Oeuvres :",
    addArtwork: "Ajouter une oeuvre",
    factualExplanationLabel: "En savoir plus sur le parcours :",
    copyrightFrPlaceholder: "...",
    tourRouteImageTitle: "Image du parcours :",
    tourRouteImageSpecifications:
      "Utilisez une image au format JPEG de taille inférieure à 100 Ko. Redimensionnez au format paysage.",
    deleteArtwork: "Supprimer l'oeuvre",
  },
  TabStatusForm: {
    title: "Statut de l'exposition",
    draftTitle: "Brouillon",
    draftExplanation:
      "Une exposition ayant le statut brouillon n'apparait pas dans l'application mobile",
    upcomingTitle: "A venir",
    upcomingExplanation:
      "Une exposition ayant le statut à venir apparait dans l'application mobile, mais en grisé et l'utilisateur ne peut pas cliquer dessus pour voir les détails",
    publishedTitle: "Publiée",
    publishedExplanation:
      "Une exposition ayant le statut publiée apparait dans l'application mobile",
    archivedTitle: "Archivée",
    archivedExplanation:
      "Une exposition ayant le statut archivée n'apparait pas dans l'application mobile, et n'apparait pas non plus dans l'écran d'accueil du back office sauf en choisissant Afficher les expositions archivées.",
  },
  CEAnecdoteModal: {
    headerTitleUpdate: "Modification de l'anecdote",
    headerTitleCreate: "Création d'une anecdote",
    anecdoteTitleLabel: "Titre : ",
    anecdoteTitleFrLabel: "Français : ",
    anecdoteTitleFrPlaceholder: "...",
    anecdoteTitleEnLabel: "Anglais : ",
    anecdoteTitleEnPlaceholder: "...",
    anecdoteTitleNlLabel: "Néerlandais : ",
    anecdoteTitleNlPlaceholder: "...",
    anecdoteExplanationLabel: "Contenu : ",
    anecdoteExplanationFrLabel: "Français : ",
    anecdoteExplanationFrPlaceholder: "...",
    anecdoteExplanationEnLabel: "Anglais : ",
    anecdoteExplanationEnPlaceholder: "...",
    anecdoteExplanationNlLabel: "Néerlandais : ",
    anecdoteExplanationNlPlaceholder: "...",
  },
  CEDropzone: {
    unsupportedFormat:
      "Format non supporté, utilisez un des formats suivants :",
  },
  CEExpoListItem: {
    noTitle: "(Pas de titre)",
    noLocation: "(Pas de lieu)",
    noDates: "(Pas de dates)",
  },
  CEExpoNewModal: {
    headerTitle: "Nouvelle exposition",
    expoTitleLabel: "Titre de l'exposition (fr) :",
    expoTitlePlaceholder: "...",
    createNewExpo: "Créer une nouvelle exposition",
    expoType: {
      internal: "Exposition interne",
      external: "Exposition partenaire",
    },
  },
  CEExpoPublishModal: {
    headerTitle: "Publication",
    cannotConfirmBecauseOfErrors:
      "Des champs manquants ou des erreurs empêche le changement de statut de l'exposition :",
    sameStatusError: "L'exposition a déjà le statut désiré.",
    tryingToPublishFromArchiveError:
      "Il n'est pas possible de mettre directement en à venir ou publiée une exposition archivée. Veuillez commencer par la passer en brouillon.",
    tryingToArchiveFromPublishError:
      "Il n'est pas possible d'archiver directement une exposition à venir ou publiée. Veuillez commencer par la passer en brouillon.",
    infoToDraft: "L'exposition peut être passée en brouillon.",
    infoToUpcoming: "L'exposition peut être passée en à venir.",
    infoToPublished: "L'exposition peut être publiée.",
    infoToArchived: "L'exposition peut être archivée.",
    infoDefault:
      "L'action n'est pas reconnue. Essayez d'actualiser la page puis de recommencer.",
    confirmDraftExpo: "Passer l'exposition en brouillon",
    confirmUpcomingExpo: "Passer l'exposition en à venir",
    confirmPublishedExpo: "Publier l'exposition",
    confirmArchivedExpo: "Archiver l'exposition",
    confirmDefault: "Action non reconnue",
  },
  CEHeader: {
    title: "Culturespaces",
    logoAlt: "Logo Culturespaces",
    logoTitle: "Accueil",
    logoutTitle: "Déconnexion",
    countryTitle: "Gestion des pays",
    languageTitle: "Gestion des langues",
    versionTitle: "Gestion de la version",
  },
  CEIconTooltipCode: {
    title: "Champ de texte enrichi",
    subTitle: "Les balises suivantes peuvent être utilisées",
    content: "Contenu :",
    render: "Rendu :",
    br: {
      tag: "<br> :",
      description: "Retour à la ligne (x2 pour saut de ligne)",
      content: "Chagall<br>Voyages",
      render: "Chagall<br>Voyages",
    },
    i: {
      tag: "<i> :",
      description: "Text en italique",
      content:
        "<i>J'ai mis du temps à comprendre mes nymphéas</i>, écrit Monet.",
      render:
        "<p><i>J'ai mis du temps à comprendre mes nymphéas</i>, écrit Monet.</p>",
    },
    b: {
      tag: "<b> :",
      description: "Text en gras",
      content: "Claude Monet s’essaie à la figure <b>féminine</b>",
      render: "<p>Claude Monet s’essaie à la figure <b>féminine</b></p>",
    },
  },
  CEMapsAutocomplete: {
    title: "Remplissez automatiquement le formulaire en recherchant le lieu :",
    placeholder: "Entrez un lieu...",
  },
  CENavigationMenu: {
    home: "Accueil",
    tabLanguages: "Langues",
    tabGeneral: "Général",
    tabMedias: "Médias",
    tabArtworks: "Oeuvres",
    tabPlaylists: "Playlists",
    tabTourRoutes: "Parcours",
    tabStatus: "Statut",
  },
  CESongModal: {
    headerTitleUpdate: "Modification de la musique",
    headerTitleCreate: "Création d'une musique",
    trackArtistLabel: "Artiste :",
    trackArtistPlaceholder: "...",
    trackTitleLabel: "Titre :",
    trackTitleFrLabel: "Français :",
    trackTitleFrPlaceholder: "...",
    trackTitleEnLabel: "Anglais :",
    trackTitleEnPlaceholder: "...",
    trackTitleNlLabel: "Néerlandais :",
    trackTitleNlPlaceholder: "...",
    trackUrlLabel: "Url de la musique :",
    trackUrlPlaceholder: "...",
  },
  CEUpdateModal: {
    headerTitle: "Validation",
    confirm: "Confirmer les modifications ?",
    publicationErrorsTitleDraft:
      "Les points suivants empêcheront le passage de l'exposition à un statut à venir ou publiée. L'exposition a actuellement un statut de brouillon et peut donc tout de même être modifiée.",
    publicationErrorsTitlePublished:
      "L'exposition est actuellement publiée ou à venir, tous les champs obligatoires doivent donc être remplis. Les points suivants empêchent la modification de l'exposition.",
    updateError: "Une erreur est survenue, veuillez réessayer plus tard.",
    headerTitleFull: "Résumé des modifications", // In <CEUpdateModalFull />, which is not implemented
    textIfNoCompare: "Aucune modification n'a été apportée.", // In <CEUpdateModalFull />, which is not implemented
  },
  CEDuplicateModal: {
    title: "Dupliquer",
    body1:
      "Dupliquer cette exposition dupliquera également les médias, oeuvres, playlists et morceaux associés.",
    body2_1:
      "Le nom de l'exposition et le nom des oeuvres seront préfixés par ",
    body2_2: "Copie de",
    body2_3:
      "et son statut réinitialisé à brouillon dans le cas d'une exposition publiée, a venir ou archivée.",
  },
  CountriesScreen: {
    title: "Configuration des pays",
    form: {
      add: "Ajouter un nouveau pays",
      edit: "Modifier un pays",
      title: "Nom du pays :",
    },
  },
  LanguagesScreen: {
    title: "Configuration des langues",
    inputPlaceholder: "Rechercher un pays",
    submit: "Enregistrer",
    saveSuccess: "Sauvegarde réussie",
    saveFailed: "Échec de la sauvegarde",
  },
  CENewArtworkModal: {
    headerTitle: "Ajouter une oeuvre",
    artworkName: "Nom de l'œuvre (Fr) :",
    artworkNameFrPlaceholder: "...",
  },
  CENewPlaylistModal: {
    headerTitle: "Ajouter une playlist",
    expositionMoment: "Moment de l'exposition (Fr) :",
    expositionMomentPlaceholder: "...",
  },
  CENewTourRouteModal: {
    headerTitle: "Ajouter un parcours",
    expositionMoment: "Moment de l'exposition (Fr) :",
    expositionMomentPlaceholder: "...",
  },
  CEArtworksForTourRouteModal: {
    headerTitle: "Ajouter une oeuvre au parcours",
  },
  publishedExpoModal: {
    headerTitle: "Exposition Publiée",
    message:
      "Impossible d'ajouter un artwork, une playlist, une musique, un parcours ou une anecdote sur une exposition publiée",
  },
  CEConfirmModal: {
    headerTitle: "Confirmation",
    deleteArtworkTitle: "Suppression d'une oeuvre",
    deleteArtwork: "Confirmer la suppression de cette oeuvre ?",
    deletePlaylistTitle: "Suppression d'une playlist",
    deletePlaylist: "Confirmer la suppression de cette playlist ?",
    deleteTourRouteTitle: "Suppression d'un parcours",
    deleteTourRoute: "Confirmer la suppression de ce parcours ?",
    deleteSongTitle: "Suppression d'une musique",
    deleteSong: "Confirmer la suppression de cette musique ?",
    deleteAnecdoteTitle: "Suppression d'une anecdote",
    deleteAnecdote: "Confirmer la suppression de cette anecdote ?",
  },
  CEExpoTag: {
    extenal: "Partenaire",
    archived: "Archivée",
    published: "Publiée",
    upcoming: "A venir",
  },
  CEFlashNotifications: {
    exposition: {
      create: {
        success: "L'exposition a été créée avec succès",
      },
      update: {
        success: "L'exposition a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de l'exposition",
          countryNotFound: "Le pays n'existe pas",
          languageNotFound: "Cette langue n'existe pas",
        },
      },
      duplicate: {
        success: "L'exposition a été dupliquée avec succès",
        errors: {
          default: "Échec de la duplication de l'exposition",
        },
      },
    },
    language: {
      create: {
        success: "La liste des langues a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de l'enregistrement de la langue",
          languageAlreadyExist: "Cette langue existe deja",
          languageNotFound: "Celle langue n'existe pas",
        },
      },
      delete: {
        success: "La langue a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression de la langue",
          languageCannotBeDelete: "Le langue n'a pas pu être supprimée",
        },
      },
    },
    file: {
      upload: {
        success: "Le fichier a été téléchargé avec succès",
        errors: {
          default: "Erreur lors du téléchargement du fichier",
          invalidFile: "Ce fichier n'est pas valide",
          fileCannotBeDelete: "Ce fichier n'a pas pu être supprimé",
          playlistNotFound: "Cette playlist n'existe pas",
          songNotFound: "Cette musique n'existe pas",
          entityNotFound: "Cette entité n'existe pas",
        },
      },
    },
    anecdote: {
      create: {
        success: "L'anecdote a été créée avec succès",
        errors: {
          default: "Erreur lors de la création de l'anecdote",
          anecdoteNotFound: "Cette anecdote n'existe pas",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      update: {
        success: "L'anecdote a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de l'anecdote",
          anecdoteNotFound: "Cette anecdote n'existe pas",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      delete: {
        success: "L'anecdote a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression de l'anecdote",
          anecdoteNotFound: "Cette anecdote n'existe pas",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      updateOrder: {
        success: "La liste des anecdotes a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la liste des anecdotes",
        },
      },
    },
    artwork: {
      create: {
        success: "L'oeuvre a été créée avec succès",
        errors: {
          default: "Erreur lors de la création de l'oeuvre",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      update: {
        success: "L'oeuvre a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de l'oeuvre",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      delete: {
        success: "L'oeuvre a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression de l'oeuvre",
          artworkNotFound: "Cette oeuvre n'existe pas",
        },
      },
      updateOrder: {
        success: "La liste des oeuvres a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la liste des oeuvres",
        },
      },
    },
    playlist: {
      create: {
        success: "La playlist a été créée avec succès",
        errors: {
          default: "Erreur lors de la création de la playlist",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      update: {
        success: "La playlist a été sauvegardée avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la playlist",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      delete: {
        success: "La playlist a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression de la playlist",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      updateOrder: {
        success: "La liste des playlists a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la liste des playlists",
        },
      },
    },
    song: {
      create: {
        success: "La musique a été sauvegardée avec succès",
        errors: {
          default: "Erreur lors de la création de la musique",
          songNotFound: "Cette musique n'existe pas",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      update: {
        success: "La musique a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la musique",
          songNotFound: "Cette musique n'existe pas",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      delete: {
        success: "La musique a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression de la musique",
          songNotFound: "Cette musique n'existe pas",
          playlistNotFound: "Cette playlist n'existe pas",
        },
      },
      updateOrder: {
        success: "La liste des musiques a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la liste des musiques",
        },
      },
    },
    version: {
      update: {
        success:
          "La version minimum de l'application mobile a été mise à jour avec succès",
        errors: {
          default:
            "Erreur lors de la mise à jour de la version minimum de l'application mobile",
          versionNotFound: "Cette version n'existe pas",
        },
      },
    },
    tourRoute: {
      create: {
        success: "Le parcours a été créé avec succès",
        errors: {
          default: "Erreur lors de la création du parcours",
          tourRouteNotFound: "Ce parcours n'existe pas",
        },
      },
      update: {
        success: "Le parcours a été sauvegardé avec succès",
        errors: {
          default: "Erreur lors de la mise à jour du parcours",
          tourRouteNotFound: "Ce parcours n'existe pas",
        },
      },
      delete: {
        success: "Le parcours a été supprimé avec succès",
        errors: {
          default: "Erreur lors de la suppression du parcours",
          tourRouteNotFound: "Ce parcours n'existe pas",
        },
      },
      updateOrder: {
        success: "La liste des parcours a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour de la liste des parcours",
        },
      },
    },
    country: {
      create: {
        success: "La liste des pays a été enregistrée avec succès",
        errors: {
          default: "Erreur lors de la création du pays",
          countryAlreadyexist: "Ce pays existe déjà",
        },
      },
      update: {
        success: "La liste des pays a été mise à jour avec succès",
        errors: {
          default: "Erreur lors de l'enregistrement de la liste des pays",
        },
      },
      delete: {
        success: "Le pays a été supprimée avec succès",
        errors: {
          default: "Erreur lors de la suppression du pays",
          countryCannotBeDelete: "Ce pays n'a pas pu être supprimé",
          countryNotFound: "Le pays n'existe pas",
        },
      },
    },
    expositionStatus: {
      update: {
        success: "Le statut de l'exposition a été mis à jour avec succès",
        errors: {
          default: "Erreur lors de la mise à jour du statut de l'exposition",
        },
      },
    },
  },
  CEValidationMessages: {
    headerTitle: "Échec du changement de statut",
    languageIsRequired: "La langue est requise",
    fieldIsRequired: "Le champ est requis",
    unknownError: "Erreur inconnue",
    keys: {
      anecdotes:
        "Anecdotes: <span class='error_subtitle'>( une ou plusieurs anecdotes )</span>",
      artworks:
        "Oeuvres: <span class='error_subtitle'>( une ou plusieurs œuvres )</span>",
      exposition: "Exposition: ",
      playlist:
        "Playlists: <span class='error_subtitle'>( une ou plusieurs playlists )</span>",
      songs:
        "Musiques: <span class='error_subtitle'>( une ou plusieurs musiques )</span>",
      title: "Titre",
      startDate: "Date de début",
      endDate: "Date de fin",
      addressLocation: "Nom du lieu",
      addressCountry: "Pays du lieu",
      websiteUrl: "Url du site internet",
      websiteTitle: "Nom du site internet",
      thumbnailMedia: "Vignette de l'exposition",
      artworkName: "Nom de l'oeuvre",
      artworkTrivia: "Trivia",
      artworkLocation: "Lieu de l'oeuvre",
      artwork: "Nom de l'oeuvre",
      artworkThumbnail: "Vignette de l'oeuvre",
      date: "Date",
      factualExplanation: "Explication de l'oeuvre",
      copyright: "Copyright",
      anecdoteExplanation: "Explication de l'anecdote",
      trackArtist: "Nom de l'artiste",
      trackTitle: "Nom de la musique",
      trackUrl: "Url de la musique",
      media: "Media",
      expositionMoment: "Moment de l'exposition",
      directors: "Réalisateur",
      schedule: "Horaire",
      scheduleComplement: "Complément d'horaires",
      latitude: "Latitude",
      longitude: "Longitude",
      additionalInformations: "Informations supplémentaires",
    },
  },
}

export default fr
