import type { FC } from "react"

import type { DtoExpositionSummary } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { getDateRangeString } from "../../functions"
import { css } from "../../styled-components"
import { CEExpoTagList } from "./CEExpoTagList"
import CETextPoppins from "./CETextPoppins"
import "../../assets/scss/expoSummary.scss"

interface CEExpoListItemProps {
  exposition: DtoExpositionSummary
  onClick: (id: string) => void
}

const titleStyles = css`
  font-size: 24px;
  text-transform: uppercase;
  text-overflow: ellipsis;
`

const CEExpoListItem: FC<CEExpoListItemProps> = ({ exposition, onClick }) => {
  const s = strings.CEExpoListItem

  const dates = getDateRangeString(exposition.startDate, exposition.endDate)

  return (
    <div onClick={() => onClick(exposition.id)} className="expo-item-container">
      <div>
        <div className="expo-item-header">
          <CETextPoppins styles={titleStyles} fontType={"SemiBold"}>
            {exposition.title?.fr || s.noTitle}
          </CETextPoppins>
        </div>
        <div className="expo-item-body">
          <CETextPoppins>{dates || s.noDates}</CETextPoppins>
          <CETextPoppins>
            {exposition.address?.location || s.noLocation}
          </CETextPoppins>
        </div>
      </div>
      <div>
        <CEExpoTagList
          isExternal={exposition.isExternal}
          isArchived={exposition.isArchived}
          isPublished={!!exposition.isPublished}
          isUpcoming={!!exposition.isUpcoming}
        />
      </div>
    </div>
  )
}

export default CEExpoListItem
