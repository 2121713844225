import type { FC } from "react"
import styled from "styled-components"

import type { DtoCountry, DtoLanguage } from "../../@types/dto/DtoObjects"
import { Trash } from "../icons"

interface CECountryLanguageItemProps {
  item: DtoCountry | DtoLanguage
  canBeDeleted: boolean
  onClick: (item: DtoCountry | DtoLanguage) => void
}

export const CECountryLanguageItem: FC<CECountryLanguageItemProps> = ({
  item,
  canBeDeleted,
  onClick,
}) => {
  return (
    <Container>
      {item.label}
      {canBeDeleted && (
        <Item onClick={() => onClick(item)}>
          <Trash />
        </Item>
      )}
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid #000;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 350px;
`

const Item = styled.div`
  cursor: pointer;
`
