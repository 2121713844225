import type { FC } from "react"
import styled from "styled-components"

import type { DtoFullVideos } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import {
  useDeleteFullVideo,
  useUpdateVideoTitle,
} from "../../services/useQueries"
import CEButton from "./CEButton"
import { CEDropzone } from "./CEDropzone"
import CEInput from "./CEInput"
import { CELabel } from "./CELabel"

interface CEFullVideoProps {
  index: number
  item: DtoFullVideos
  expoId: string
  fullVideos: DtoFullVideos[] | undefined
  formDataState: FormData | undefined
  isOrderChanged: boolean
  isLoading: boolean
  handleFullVideoTitleChange: (index: number, newTitle: string) => void
  uploadVideo: (item: DtoFullVideos, order: string) => void
  setFormDataState: React.Dispatch<React.SetStateAction<FormData | undefined>>
}

export const CEFullVideo: FC<CEFullVideoProps> = ({
  index,
  item,
  expoId,
  fullVideos,
  formDataState,
  isOrderChanged,
  isLoading,
  handleFullVideoTitleChange,
  uploadVideo,
  setFormDataState,
}) => {
  const s = strings.TabMediasForm

  const { mutateAsync: deleteVideo } = useDeleteFullVideo()
  const { mutateAsync: updateVideoTitle } = useUpdateVideoTitle()

  return (
    <FullVideoContainer key={item._id}>
      <CELabelContainer>
        <CELabel title={s.title} htmlFor="directorsFrInput" />
        <CEInput
          id={item.title}
          placeholder={"..."}
          value={item.title || ""}
          rows={2}
          onChange={(value) => handleFullVideoTitleChange(index, value)}
        />
      </CELabelContainer>
      <VideoDropzoneContainer>
        <CEDropzone
          type="video"
          entity={{
            field: "fullVideos",
            id: expoId,
            type: "expositions",
          }}
          isEntityLoading={isLoading}
          defaultFileUrl={item.url}
          videoTitle={item.title}
          index={index}
          setFormData={(value) => setFormDataState(value)}
        />
      </VideoDropzoneContainer>
      {item.url ? (
        <>
          <CELabelContainer>
            <CEButton
              title={s.delete}
              onClick={() => {
                deleteVideo(item._id)
              }}
              isDelete={true}
            />
          </CELabelContainer>
          {!isOrderChanged &&
            fullVideos &&
            fullVideos[index] &&
            item.title !== fullVideos[index].title && (
              <CELabelContainer>
                <CEButton
                  title={s.save}
                  onClick={() => {
                    updateVideoTitle({
                      videoId: item._id,
                      title: item.title,
                      order: index.toString(),
                    })
                  }}
                />
              </CELabelContainer>
            )}
        </>
      ) : (
        <CELabelContainer>
          <CEButton
            title={s.saveVideo}
            disabled={item.title === "" || formDataState === undefined}
            onClick={() => uploadVideo(item, index.toString())}
          />
        </CELabelContainer>
      )}
    </FullVideoContainer>
  )
}

const FullVideoContainer = styled.div`
  margin-bottom: 10px;
`

const CELabelContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const VideoDropzoneContainer = styled.div`
  width: 240px;
  height: 360px;
`
