import { createContext, Context, useState, ReactNode, FC } from "react"

import type { NavigationMenuTab } from "../../@types/interface"

// INTERFACE
interface TabActiveContextInterface {
  tabActive: NavigationMenuTab
  updateTabActive: (newTabActive: NavigationMenuTab) => void
}

// INITIAL CONTEXT STATE
const initialTabActiveContext: TabActiveContextInterface = {
  tabActive: "general",
  updateTabActive: () => {},
}

// CREATION OF THE CONTEXT
export const TabActiveContext: Context<TabActiveContextInterface> =
  createContext(initialTabActiveContext)

/**
 * This context stores and updates which tab is active between 'general', 'medias', 'artworks', 'playlists', and 'status'
 */
const TabActiveProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // STATE
  const [tabActive, setTabActive] = useState<NavigationMenuTab>("general")

  /**
   * Update the active navigation stab
   * @param newTabActive {NavigationMenuTab} new navigation tab ('general', 'medias', 'artworks', 'playlists', or 'status')
   */
  const updateTabActive = (newTabActive: NavigationMenuTab) => {
    setTabActive(newTabActive)
  }

  // RENDER
  return (
    <TabActiveContext.Provider
      value={{
        tabActive,
        updateTabActive,
      }}
    >
      {children}
    </TabActiveContext.Provider>
  )
}

export default TabActiveProvider
