import type { FC } from "react"
import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"

import strings from "../assets/strings"
import { TabActiveContext } from "../components/contexts/TabActiveContext"
import CENavigationMenu from "../components/layout/CENavigationMenu"
import { Main } from "../components/layout/Main"
import ErrorScreen from "../components/screens/ErrorScreen"
import { TabGeneral } from "../components/screens/TabGeneral/TabGeneral"
import { CETextPoppins } from "../components/ui"
import { useGetExposition } from "../services/useQueries"

export const ExpositionGeneralPage: FC = () => {
  const { expoId } = useParams()

  const { tabActive, updateTabActive } = useContext(TabActiveContext)

  const {
    data: exposition,
    isLoading: isLoadingExposition,
    isError: isErrorExposition,
  } = useGetExposition(expoId)

  useEffect(() => {
    if (tabActive !== "general") {
      updateTabActive("general")
    }
  }, [expoId, exposition, tabActive, updateTabActive])

  if (isLoadingExposition) {
    return <CETextPoppins>{strings.general.loading}</CETextPoppins>
  }

  if (isErrorExposition || !exposition) {
    return <ErrorScreen />
  }
  return (
    <Main>
      <div className="row-container">
        <CENavigationMenu />
        <TabGeneral exposition={exposition} />
      </div>
    </Main>
  )
}
