import type { FC } from "react"

import "../../assets/scss/tags.scss"
import strings from "../../assets/strings"

export const CEExpoTagList: FC<{
  isArchived: boolean
  isExternal: boolean
  isPublished: boolean
  isUpcoming: boolean
}> = ({ isArchived, isExternal, isPublished, isUpcoming }) => {
  const s = strings.CEExpoTag
  return (
    <ul className="tag-list">
      <li>
        {isExternal ? (
          <span className="tag-item tag-external">{s.extenal}</span>
        ) : null}
      </li>
      <li>
        {isArchived ? (
          <span className="tag-item tag-archived">{s.archived}</span>
        ) : null}
      </li>
      <li>
        {isPublished && !isUpcoming ? (
          <span className="tag-item tag-published">{s.published}</span>
        ) : null}
      </li>
      <li>
        {isPublished && isUpcoming ? (
          <span className="tag-item tag-upcoming">{s.upcoming}</span>
        ) : null}
      </li>
    </ul>
  )
}
