import type { FC } from "react"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"

import type { EntityField, EntityType } from "../../@types/dto/DtoObjects"
import { useUploadFile } from "../../services/useQueries"
import styled from "../../styled-components"
import theme from "../../styled-components/theme"
import { Plus } from "../icons"

interface CEDropzoneProps {
  type: "image" | "video" | "fullVideo"
  entity: { type: EntityType; field: EntityField; id: string }
  defaultFileUrl?: string
  isEntityLoading?: boolean
  videoTitle?: string
  index?: number
  setTourRouteFile?: (file: File) => void
  setFormData?: (formData: FormData) => void
}

export const CEDropzone: FC<CEDropzoneProps> = ({
  type,
  defaultFileUrl,
  entity,
  isEntityLoading,
  videoTitle,
  index,
  setTourRouteFile,
  setFormData,
}) => {
  const [filePath, setFilePath] = useState<string | undefined>()
  const { mutateAsync: uploadFile, isLoading } = useUploadFile()

  const handleDropImage = useCallback(
    async (acceptedFiles: Array<File>) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        setFilePath(URL.createObjectURL(file))
        if (setTourRouteFile) {
          setTourRouteFile(file)
        } else {
          const formData = new FormData()
          formData.append("file", file)
          if (videoTitle) {
            formData.append("title", videoTitle)
            formData.append("order", index?.toString() ?? "0")
          }
          if (setFormData) {
            setFormData(formData)
          } else {
            await uploadFile({ formData, entity })
          }
        }
      }
    },
    [entity, index, setFormData, setTourRouteFile, uploadFile, videoTitle]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDropImage,
    accept:
      type === "image"
        ? {
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
          }
        : {
            "video/mp4": [".mp4"],
          },
  })

  const renderPreview = () => {
    if (isLoading || isEntityLoading) {
      return (
        <FullWidthAndHeightContainer>
          <CenteredSpinnerContainer>
            <Spinner />
          </CenteredSpinnerContainer>
        </FullWidthAndHeightContainer>
      )
    }
    if (type === "image") {
      return (
        <FullWidthAndHeightContainer>
          <PreviewImage src={filePath ?? defaultFileUrl} />
        </FullWidthAndHeightContainer>
      )
    }

    if (type === "video") {
      return (
        <FullWidthAndHeightContainer>
          <video
            key={filePath ?? defaultFileUrl}
            width="100%"
            height="100%"
            autoPlay
            loop
            muted
          >
            <source src={filePath ?? defaultFileUrl} type="video/mp4" />
          </video>
        </FullWidthAndHeightContainer>
      )
    }

    if (isDragActive) {
      return (
        <PlusContainer isDragActive={isDragActive}>
          <Plus
            color={theme.colors.primary.main}
            width="48"
            height="48"
            strokeWidth="1"
          />
        </PlusContainer>
      )
    }
  }

  return (
    <Root>
      {entity.field === "fullVideos" && defaultFileUrl ? (
        <FullVideoContainer>{renderPreview()}</FullVideoContainer>
      ) : (
        <DropZone {...getRootProps()}>
          {/* INPUT */}
          <DropZoneInput {...getInputProps()} />

          {renderPreview()}
        </DropZone>
      )}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const FullVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  outline: none;
`

const DropZone = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed ${(props) => props.theme.colors.primary.main};
  border-radius: 16px;
  overflow: hidden;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`
const DropZoneInput = styled.input``

const FullWidthAndHeightContainer = styled.div`
  width: 100%;
  height: 100%;
`

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

interface PlusContainerProps {
  isDragActive?: boolean
}
const PlusContainer = styled.div<PlusContainerProps>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isDragActive ? props.theme.colors.primary.light : "transparent"};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.light};
  }
`

const CenteredSpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
