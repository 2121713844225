import type {
  DtoAnecdote,
  DtoArtwork,
  DtoArtworkForm,
  DtoAuthSuccess,
  DtoCountry,
  DtoCreateExposition,
  DtoDraftExpositionForm,
  DtoExposition,
  DtoExpositionForm,
  DtoExpositionSummary,
  DtoFullVideos,
  DtoLanguage,
  DtoLanguageSummary,
  DtoLogin,
  DtoPlaylist,
  DtoSong,
  DtoTourRoute,
  DtoUpdateArtworksAnecdotesOrder,
  DtoUpdateExpositionArtworksOrder,
  DtoUpdateExpositionPlaylistOrder,
  DtoUpdatePlaylistSongsOrder,
  EntityField,
  EntityType,
  ExpositionStatusType,
} from "../@types/dto/DtoObjects"
import { del, get, post, put } from "../api"

export const authApi = {
  login: (credentials: DtoLogin) =>
    post<DtoAuthSuccess>("/auth/login", credentials),
  logout: () => post<any>("/auth/logout"),
}

export const expositionApi = {
  getAll: () => get<DtoExpositionSummary[]>("/api/expositions"),
  fetchExposition: (id: string) => get<DtoExposition>(`/api/expositions/${id}`),
  putPublishedExposition: (
    exposition: DtoExpositionForm,
    isExternal: boolean
  ) =>
    put<DtoExposition>(
      `/api/expositions/${exposition.id}?isExternal=${isExternal}`,
      exposition
    ),
  putDraftExposition: (
    exposition: DtoDraftExpositionForm,
    isExternal: boolean
  ) =>
    put<DtoExposition>(
      `/api/expositions/${exposition.id}/draft?isExternal=${
        isExternal ? "true" : "false"
      }`,
      exposition
    ),
  duplicateExposition: (id: string) =>
    post<DtoExposition>(`/api/expositions/${id}/duplicate`),
  postExposition: (exposition: DtoCreateExposition) =>
    post<DtoExposition>("api/expositions", exposition),
  putExpositionLanguages: (id: string, languagesIds: string[]) =>
    put<DtoExposition>(`api/expositions/${id}/languages`, {
      languagesIds,
    }),
  updateArtworksOrder: (
    expositionId: string,
    updatedArtworksOrder: DtoUpdateExpositionArtworksOrder
  ) =>
    put<DtoExposition>(
      `api/expositions/${expositionId}/artworksOrder`,
      updatedArtworksOrder
    ),
  updatePlaylistsOrder: (
    expositionId: string,
    updatedPlaylistsOrder: DtoUpdateExpositionPlaylistOrder
  ) =>
    put<DtoExposition>(
      `api/expositions/${expositionId}/playlistsOrder`,
      updatedPlaylistsOrder
    ),
  switchStatus: (id: string, status: ExpositionStatusType) =>
    put(`/api/expositions/${id}/status/${status}`),
}

export const fullVideosApi = {
  getAll: (expoId: string) => get<DtoFullVideos[]>(`/api/fullVideos/${expoId}`),
  upload: (formData: FormData, expoId: string) =>
    put(`/api/files/v2/expositions/${expoId}/fullVideo`, formData),
  update: (videoId: string, title: string, order: string) =>
    post<string>(`api/fullVideos/${videoId}`, { title: title, order: order }),
  delete: (videoId: string) => del(`api/fullVideos/${videoId}`),
}

export const languageApi = {
  fetchLanguages: () => get<DtoLanguageSummary[]>("/api/languages"),
  postLanguage: (data: DtoLanguage[]) => post("/api/languages", data),
  delete: (languageId: string) => del(`/api/languages/${languageId}`),
}

export const versionApi = {
  get: () => get<{ id: string; version: string }>("/version"),
  update: (version: string) => post<string>("/version", { version: version }),
}

export const countryApi = {
  getAll: () => get<DtoCountry[]>("/api/countries"),
  fetchCountry: (id: string) => get<DtoCountry>(`/api/countries/${id}`),
  postCountries: (countries: DtoCountry[]) =>
    post<DtoCountry[]>("/api/countries", countries),
  delete: (countryId: string) => del(`/api/countries/${countryId}`),
  saveOrder: (countryIds: string[]) =>
    put("/api/countries/order", {
      countryIds,
    }),
}

export const fileApi = {
  upload: (
    formData: FormData,
    entity: { type: EntityType; field: EntityField; id: string }
  ) => put(`/api/files/${entity.type}/${entity.id}/${entity.field}`, formData),
  delete: (name: string) => del(`/api/files/${name}`),
}

export const artworkApi = {
  create: (newArtwork: DtoArtworkForm, expositionId: string) =>
    post<DtoArtwork>(`api/expositions/${expositionId}/artworks`, newArtwork),
  update: (artwork: DtoArtworkForm, expositionId: string) =>
    put<DtoArtwork>(
      `api/expositions/${expositionId}/artworks/${artwork.id}`,
      artwork
    ),
  delete: (artworkId: string, expositionId: string) =>
    del(`api/expositions/${expositionId}/artworks/${artworkId}`),
  updateAnecdotesOrder: (
    expositionId: string,
    artworkId: string,
    updatedAnecdotesOrder: DtoUpdateArtworksAnecdotesOrder
  ) =>
    put<DtoExposition>(
      `api/expositions/${expositionId}/artworks/${artworkId}/anecdotesOrder`,
      updatedAnecdotesOrder
    ),
}

export const anecdoteApi = {
  save: (anecdote: DtoAnecdote, artworkId: string, expositionId: string) =>
    anecdote.id
      ? put<DtoAnecdote>(
          `api/expositions/${expositionId}/artworks/${artworkId}/anecdotes/${anecdote.id}`,
          anecdote
        )
      : post<DtoAnecdote>(
          `api/expositions/${expositionId}/artworks/${artworkId}/anecdotes`,
          anecdote
        ),
  delete: (anecdoteId: string, artworkId: string, expositionId: string) =>
    del(
      `api/expositions/${expositionId}/artworks/${artworkId}/anecdotes/${anecdoteId}`
    ),
}

export const playlistApi = {
  create: (newPlaylist: DtoPlaylist, expositionId: string) =>
    post<DtoPlaylist>(`api/expositions/${expositionId}/playlists`, newPlaylist),
  update: (playlist: DtoPlaylist, expositionId: string) =>
    put<DtoPlaylist>(
      `api/expositions/${expositionId}/playlists/${playlist.id}`,
      playlist
    ),
  delete: (playlistId: string, expositionId: string) =>
    del(`api/expositions/${expositionId}/playlists/${playlistId}`),
  updateSongsOrder: (
    expositionId: string,
    playlistId: string,
    updatedSongsOrder: DtoUpdatePlaylistSongsOrder
  ) =>
    put<DtoPlaylist>(
      `api/expositions/${expositionId}/playlists/${playlistId}/songsOrder`,
      updatedSongsOrder
    ),
}

export const songApi = {
  save: (song: DtoSong, playlistId: string, expositionId: string) =>
    song.id
      ? put<DtoSong>(
          `api/expositions/${expositionId}/playlists/${playlistId}/songs/${song.id}`,
          song
        )
      : post<DtoSong>(
          `api/expositions/${expositionId}/playlists/${playlistId}/songs`,
          song
        ),
  delete: (songId: string, playlistId: string, expositionId: string) =>
    del(
      `api/expositions/${expositionId}/playlists/${playlistId}/songs/${songId}`
    ),
}

export const tourRouteApi = {
  getAll: (expoId: string) => get<DtoTourRoute[]>(`/api/courses/${expoId}`),
  create: (formData: FormData) => post("api/courses/", formData),
  update: (formData: FormData, tourRouteId: string) =>
    post<DtoTourRoute>(`/api/courses/${tourRouteId}`, formData),
  delete: (tourRouteId: string) => del(`api/courses/${tourRouteId}`),
  deleteArtwork: (tourRouteId: string, artworkId: string) =>
    del(`api/courses/${tourRouteId}/${artworkId}`),
  updateTourRoutesOrder: (
    expositionId: string,
    tourRoutesOrdered: { courseId: string; order: number }[]
  ) => post<any>(`api/courses/reorder/${expositionId}`, tourRoutesOrdered),
}
