import { FC, FormEvent, useState } from "react"

import type { DtoLogin } from "../../@types/dto/DtoObjects"
import CultureEspacesLogo from "../../assets/img/culturespaces-squarelogo.png"
import strings from "../../assets/strings"
import { useLoginMutation } from "../../services/useQueries"
import { CEButton, CELabel, CEInput } from "../ui"

export const Login: FC = () => {
  const s = strings.Login

  const [loginState, setLoginState] = useState<DtoLogin>({
    password: "",
    username: "",
  })

  const { mutateAsync: login, isLoading } = useLoginMutation()

  const updateLoginState = (name: keyof DtoLogin, value: string) => {
    setLoginState((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await login(loginState)
  }

  return (
    <div className="login-container">
      <div>
        <div className="section-container">
          <h2 className="title">{s.loginTitle}</h2>
        </div>
        <form onSubmit={onSubmit}>
          <div className="section-container">
            <div className="input-container">
              <CELabel title={s.usernameLabel} htmlFor="usernameInput" />
              <CEInput
                id="usernameInput"
                name="username"
                short
                placeholder={s.usernamePlaceholder}
                value={loginState.username}
                onChange={(value) => updateLoginState("username", value)}
              />
            </div>

            <div className="input-container">
              <CELabel title={s.passwordLabel} htmlFor="passwordInput" />
              <CEInput
                id="passwordInput"
                type="password"
                short
                placeholder={s.passwordPlaceholder}
                value={loginState.password}
                onChange={(value) => updateLoginState("password", value)}
              />
            </div>
          </div>
          <div>
            <CEButton
              type="submit"
              title={"connexion"}
              disabled={
                isLoading || !loginState.password || !loginState.username
              }
            />
          </div>
        </form>
      </div>
      <div className="login-logo-container">
        <img src={CultureEspacesLogo} alt="logo Culturespaces" />
      </div>
    </div>
  )
}
