import {
  Loadable,
  RecoilState,
  RecoilValue,
  useRecoilCallback,
  useRecoilTransactionObserver_UNSTABLE,
} from "recoil"

export function getRecoilState<T>(recoilValue: RecoilValue<T>): T {
  return getRecoilLoadable(recoilValue).getValue()
}

let getRecoilLoadable: <T>(recoilValue: RecoilValue<T>) => Loadable<T> = () =>
  null as any

export let setRecoilState: <T>(
  recoilState: RecoilState<T>,
  valOrUpdater: ((currVal: T) => T) | T
) => void = () => null as any

export function RecoilExternalStatePortal() {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    getRecoilLoadable = snapshot.getLoadable
  })

  useRecoilCallback(({ set }) => {
    setRecoilState = set

    return async () => {}
  })()

  return <></>
}
