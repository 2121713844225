import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import type { FC } from "react"
import { Toaster } from "react-hot-toast"
import { RecoilRoot } from "recoil"

import { AuthProvider } from "./components/contexts/AuthProvider"
import TabActiveProvider from "./components/contexts/TabActiveContext"
import { Router } from "./components/layout/Router"
import { CEErrorValidationModal } from "./components/ui"
import { useErrorsModal } from "./hooks/useErrorsModal"
import { ThemeProvider } from "./styled-components"
import theme from "./styled-components/theme"

const queryClient = new QueryClient()

export function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <TabActiveProvider>
              <Router />
            </TabActiveProvider>
            <Toaster position="bottom-center" />
            <ErrorsNotification />
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}

const ErrorsNotification: FC = () => {
  const { isOpen } = useErrorsModal()
  return isOpen ? <CEErrorValidationModal /> : null
}
