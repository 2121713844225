import styled, { css } from "../../styled-components"
import CETextPoppins from "./CETextPoppins"

interface CEExpoTypeButtonProps {
  title: string
  selected: boolean
  onClick: () => void
}

const CEExpoTypeButton: React.FC<CEExpoTypeButtonProps> = ({
  title,
  selected,
  onClick,
}) => {
  return (
    <Button onClick={onClick} selected={selected}>
      <CETextPoppins styles={TextStyles}>{title.toUpperCase()}</CETextPoppins>
    </Button>
  )
}

const Button = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.primary.main
      : props.theme.colors.white};
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white
      : props.theme.colors.primary.main};
  padding: 10px;
  border: 0px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 10px;
  outline: none;
  opacity: ${(props) =>
    props.disabled ? props.theme.opacity.buttonDisabled : "1"};

  &:hover {
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    opacity: ${(props) =>
      props.disabled ? props.theme.opacity.buttonDisabled : "0.8"};
  }

  &:active {
    opacity: ${(props) => props.theme.opacity.buttonDisabled};
  }
`

const TextStyles = css`
  font-size: ${(props) => props.theme.fontSize.buttonChoiceExpoType};
  text-transform: uppercase;
`

export default CEExpoTypeButton
