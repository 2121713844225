import { FC, Fragment } from "react"
import styled from "styled-components"

import strings from "../../assets/strings"
import {
  translateKeyErrorMessage,
  useErrorsModal,
} from "../../hooks/useErrorsModal"
import {
  ButtonContainer,
  ButtonsContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEButton from "./CEButton"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"
import CETextPoppins from "./CETextPoppins"

export const CEErrorValidationModal: FC = () => {
  const { errors, onClose } = useErrorsModal()

  const handleOnClick = () => onClose()

  return (
    <CEModal isOpen={true}>
      <CEModalHeader
        title={strings.CEValidationMessages.headerTitle}
        onRequestClose={handleOnClick}
      />
      <CEModalBody>
        <Body>
          <ContentContainer>
            <InfoTextContainer>
              <CETextPoppins></CETextPoppins>
            </InfoTextContainer>
            <InfoTextContainer>
              {Object.entries(errors).map(([key, values], index) => (
                <Fragment key={index}>
                  <ErrorTitle
                    dangerouslySetInnerHTML={{
                      __html: translateKeyErrorMessage(key),
                    }}
                  />
                  <ul>
                    {Object.entries(values).map(
                      ([errorKey, errorValues], index) => (
                        <li key={index}>
                          <ErrorSubTitle>
                            {translateKeyErrorMessage(errorKey)}:{" "}
                            {errorValues.length > 1 ? (
                              <ErrorList>
                                {errorValues.map(
                                  (errorMessage: string, i: number) => (
                                    <li key={i}>
                                      <ErrorValue>{errorMessage}</ErrorValue>
                                    </li>
                                  )
                                )}
                              </ErrorList>
                            ) : (
                              <ErrorValue>{errorValues[0]}</ErrorValue>
                            )}
                          </ErrorSubTitle>
                        </li>
                      )
                    )}
                  </ul>
                </Fragment>
              ))}
            </InfoTextContainer>
          </ContentContainer>

          <ButtonsContainer>
            <ButtonContainer>
              <CEButton
                title={strings.button.validate}
                onClick={handleOnClick}
              />
            </ButtonContainer>
          </ButtonsContainer>
        </Body>
      </CEModalBody>
    </CEModal>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 600px;
  min-height: 200px;
`
const ContentContainer = styled.div``
const InfoTextContainer = styled.div``

const ErrorTitle = styled.div`
  font-family: Poppins-SemiBold, serif;
  font-size: 18px;
`
const ErrorSubTitle = styled.div`
  font-family: Poppins-SemiBold, serif;
  font-size: 16px;
`

const ErrorValue = styled.span`
  font-family: Poppins-Regular, serif;
  font-size: 16px;
`

const ErrorList = styled.ul`
  padding: 0 0 0 20px;
`
