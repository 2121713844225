import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd"
import type { ReactNode } from "react"

import {
  ListContainer,
  ListItemContainer,
} from "../screens/TabArtworks/TabArtworksForm"
import {
  InputContainer,
  underlinedLabelStyles,
} from "../screens/TabGeneral/TabGeneralForm"
import { CELabel } from "./CELabel"

export function CEVerticalDraggableList<T extends { id?: string }>({
  list,
  label,
  listIntent = 0,
  droppableId,
  containerWidth,
  renderItem,
  onDragEnd,
}: {
  list: T[]
  label?: string
  listIntent: number
  droppableId: string
  containerWidth?: string
  renderItem: (item: T) => ReactNode
  onDragEnd: (orderedList: T[]) => void
}) {
  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (destinationIndex !== undefined) {
      const movedItem = list.find((item, index) => index === sourceIndex)
      let newLists = list.filter((item, index) => index !== sourceIndex)

      if (movedItem) {
        newLists.splice(destinationIndex, 0, movedItem)
        onDragEnd(newLists)
      } else {
        onDragEnd(list)
      }
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {label && (
        <InputContainer>
          <CELabel title={label} styles={underlinedLabelStyles} />
        </InputContainer>
      )}

      <InputContainer indent={listIntent}>
        <Droppable droppableId={droppableId} direction="vertical">
          {(provided) => (
            <ListContainer
              style={{
                height: list.length * (25 + 28),
                width: containerWidth ? containerWidth : "50%",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {list.map((item, index) => (
                <Draggable key={item.id!} draggableId={item.id!} index={index}>
                  {(provided) => (
                    <ListItemContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderItem(item)}
                    </ListItemContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ListContainer>
          )}
        </Droppable>
      </InputContainer>
    </DragDropContext>
  )
}
