import { useState, FC, FormEvent } from "react"

import "../../assets/scss/newExpoModal.scss"
import type { DtoPlaylist } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { useSaveNewPlaylist } from "../../services/useQueries"
import { Music } from "../icons"
import {
  ButtonsContainer,
  LabelWithIconContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEIconTooltipCode from "./CEIconTooltipCode"
import CEInput from "./CEInput"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"
import { CELabel } from "./index"

const emptyPlaylist: DtoPlaylist = {
  expositionMoment: {
    fr: "",
  },
}

export const CECreatePlaylistModal: FC<{
  expositionId: string
  isOpen: boolean
  onConfirmSave: (playlist: DtoPlaylist) => void
  onClose: () => void
}> = ({ isOpen, onClose, expositionId, onConfirmSave }) => {
  const s = strings

  const { mutateAsync: savePlaylist, isLoading } = useSaveNewPlaylist()

  const [newPlaylist, setNewPlaylist] = useState<DtoPlaylist>(emptyPlaylist)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = await savePlaylist({
      newPlaylist,
      expoId: expositionId,
    })
    setNewPlaylist(emptyPlaylist)
    if (data) {
      onConfirmSave(data)
    }
  }

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={s.CENewPlaylistModal.headerTitle}
        onRequestClose={onClose}
        Icon={Music}
      />
      <CEModalBody>
        <form className="expo-modal-body" onSubmit={onSubmit}>
          <div className="input-container">
            <LabelWithIconContainer>
              <CEIconTooltipCode tags={["br"]} id="expoTitleTooltip" />
              <CELabel title={s.CENewPlaylistModal.expositionMoment} />
            </LabelWithIconContainer>
            <CEInput
              placeholder={s.CENewPlaylistModal.expositionMomentPlaceholder}
              value={newPlaylist.expositionMoment?.fr || ""}
              onChange={(value) =>
                setNewPlaylist((prev) => ({
                  ...prev,
                  expositionMoment: { fr: value },
                }))
              }
            />
          </div>

          <ButtonsContainer>
            <button
              className={`btn ${isLoading && "btn-disabled"} `}
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
              disabled={isLoading}
            >
              {s.button.cancel}
            </button>
            <button
              className={`btn ${
                (isLoading || !newPlaylist.expositionMoment?.fr) &&
                "btn-disabled"
              } `}
              type="submit"
              disabled={isLoading || !newPlaylist.expositionMoment?.fr}
            >
              {s.button.save}
            </button>
          </ButtonsContainer>
        </form>
      </CEModalBody>
    </CEModal>
  )
}
