import type { FC } from "react"

import strings from "../../assets/strings"
import { Picture } from "../icons"
import {
  ButtonsContainer,
  InputSectionContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"
import { CETextPoppins } from "./index"

export const CEPublishedExpoModal: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const s = strings.publishedExpoModal
  return (
    <CEModal isOpen={isOpen} onRequestClose={onClose}>
      <CEModalHeader
        title={s.headerTitle}
        onRequestClose={onClose}
        Icon={Picture}
      />
      <CEModalBody>
        <InputSectionContainer>
          <CETextPoppins>{s.message}</CETextPoppins>
        </InputSectionContainer>
        <ButtonsContainer>
          <button className={"btn"} onClick={onClose}>
            {strings.button.back}
          </button>
        </ButtonsContainer>
      </CEModalBody>
    </CEModal>
  )
}
