import Axios, { AxiosInstance, AxiosRequestConfig } from "axios"

import { ApiError } from "../@types/dto/DtoObjects.errors"
import { setRecoilState } from "../components/RecoilExternalStatePortal"
import { isAuthenticated } from "../store/auth.store"

let client: AxiosInstance

export const getClient = () => {
  if (client) {
    return client
  }
  client = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json",
  })

  client.interceptors.request.use(async (config) => {
    config.headers = {
      Authorization: `Bearer ${await sessionStorage.getItem("token")}`,
    }
    return config
  })

  client.interceptors.response.use(
    (value) => value,
    async (error) => {
      if (error.response.data.message === "INVALID_TOKEN") {
        setRecoilState(isAuthenticated, false)
      }
      throw toApiError(error.response?.data || error)
    }
  )

  return client
}

export const toApiError = (error: any) => {
  return error.message === "Network Error"
    ? error
    : error?.message && error?.status
    ? new ApiError(
        error.message,
        error.payload ? { data: error.payload } : undefined
      )
    : new ApiError("UNKNOWN_ERROR", { data: { error } })
}

export const get = <T>(url: string, config?: AxiosRequestConfig) =>
  getClient()
    .get<T>(url, config)
    .then((r) => r.data as T)

export const post = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
  getClient()
    .post<T>(url, data, config)
    .then((r) => r.data as T)

export const put = <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
  getClient()
    .put<T>(url, data, config)
    .then((r) => r.data as T)

export const del = <T>(url: string, config?: AxiosRequestConfig) =>
  getClient()
    .delete<T>(url, config)
    .then((r) => r.data as T)
