import { FC, useEffect, useState } from "react"

import type { DtoAnecdote, DtoLanguage } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { useSaveAnecdote } from "../../services/useQueries"
import styled from "../../styled-components"
import { Edit3 } from "../icons"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
  underlinedLabelStyles,
  LabelWithIconContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEButton from "./CEButton"
import CEHtmlInput from "./CEHtmlInput"
import CEIconTooltipCode from "./CEIconTooltipCode"
import CEInput from "./CEInput"
import { CELabel } from "./CELabel"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

const emptyAnecdoteForm: DtoAnecdote = {
  id: undefined,
  title: undefined,
  anecdoteExplanation: undefined,
}

interface CEAnecdoteModalProps {
  anecdote?: DtoAnecdote
  isOpen: boolean
  expositionId: string
  artworkId: string
  onRequestClose: () => void
  onConfirm: (anecdote: DtoAnecdote) => void
  languagesAvailable: DtoLanguage[]
}

export const CEAnecdoteModal: FC<CEAnecdoteModalProps> = ({
  anecdote,
  onConfirm,
  isOpen,
  expositionId,
  artworkId,
  onRequestClose,
  languagesAvailable,
}) => {
  const s = strings.CEAnecdoteModal

  const [anecdoteFormState, setAnecdoteFormState] =
    useState<DtoAnecdote>(emptyAnecdoteForm)

  useEffect(() => {
    if (anecdote) {
      setAnecdoteFormState(anecdote)
    }
    return () => setAnecdoteFormState(emptyAnecdoteForm)
  }, [anecdote])

  const { mutateAsync: saveAnecdote } = useSaveAnecdote()

  const handleConfirm = async () => {
    const data = await saveAnecdote({
      anecdote: anecdoteFormState,
      artworkId,
      expositionId,
    })
    onConfirm(data)
    setAnecdoteFormState(emptyAnecdoteForm)
  }

  const onClose = () => {
    setAnecdoteFormState(emptyAnecdoteForm)
    onRequestClose()
  }

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={anecdote?.id ? s.headerTitleUpdate : s.headerTitleCreate}
        onRequestClose={onClose}
        Icon={Edit3}
      />
      <CEModalBody>
        <Body>
          <InputContainer>
            <CELabel
              title={s.anecdoteTitleLabel}
              htmlFor="anecdoteTitleFr"
              styles={underlinedLabelStyles}
            />
          </InputContainer>
          {languagesAvailable.map((language) => (
            <InputContainer key={language.code} indent={1}>
              <CELabel title={language.label} />
              <CEInput
                id="anecdoteTitleFrInput"
                placeholder={s.anecdoteTitleFrPlaceholder}
                value={anecdoteFormState.title?.[language.code] || ""}
                onChange={(value) =>
                  setAnecdoteFormState((prev) => ({
                    ...prev,
                    title: {
                      ...prev.title,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}
          <InputContainer>
            <CELabel
              title={s.anecdoteExplanationLabel}
              htmlFor="anecdoteExplanationFr"
              styles={underlinedLabelStyles}
            />
          </InputContainer>

          {languagesAvailable.map((language) => (
            <InputContainer key={language.code} indent={1} column>
              <ContentLabelContainer>
                <LabelWithIconContainer>
                  <CEIconTooltipCode
                    tags={["br", "b", "i"]}
                    id={`title${language.code}tooltip`}
                  />
                  <CELabel title={language.label} />
                </LabelWithIconContainer>
              </ContentLabelContainer>
              <CEHtmlInput
                placeholder={s.anecdoteExplanationFrPlaceholder}
                value={
                  anecdoteFormState.anecdoteExplanation?.[language.code] || ""
                }
                onChange={(value) =>
                  setAnecdoteFormState((prev) => ({
                    ...prev,
                    anecdoteExplanation: {
                      ...prev.anecdoteExplanation,
                      [language.code]: value !== "" ? value : undefined,
                    },
                  }))
                }
              />
            </InputContainer>
          ))}

          <ButtonsContainer>
            <ButtonContainer>
              <CEButton title={strings.button.cancel} onClick={onClose} />
            </ButtonContainer>

            <ButtonContainer>
              <CEButton title={strings.button.save} onClick={handleConfirm} />
            </ButtonContainer>
          </ButtonsContainer>
        </Body>
      </CEModalBody>
    </CEModal>
  )
}

const Body = styled.div`
  min-width: 800px;
  min-height: 600px;
`

const ContentLabelContainer = styled.div`
  margin-bottom: 8px;
`
