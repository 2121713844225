import type { FC } from "react"
import styled from "styled-components"

interface CEInputCheckboxProps {
  id: string
  name: string
  isChecked: boolean
  isDisabled: boolean
  label: string
  onChange: (id: string) => void
}

export const CEInputCheckbox: FC<CEInputCheckboxProps> = ({
  id,
  name,
  isChecked,
  isDisabled,
  label,
  onChange,
}) => (
  <CheckboxContainer>
    <Checkbox
      type="checkbox"
      id={id}
      name={name}
      checked={isChecked}
      disabled={isDisabled}
      onChange={() => !isDisabled && onChange(id)}
    />
    <label htmlFor={id}>{label}</label>
  </CheckboxContainer>
)

const CheckboxContainer = styled.li`
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`
