import { useState, FC, FormEvent } from "react"

import "../../assets/scss/newExpoModal.scss"
import type { DtoArtwork, DtoArtworkForm } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { useSaveNewArtwork } from "../../services/useQueries"
import { Picture } from "../icons"
import {
  ButtonsContainer,
  LabelWithIconContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEIconTooltipCode from "./CEIconTooltipCode"
import CEInput from "./CEInput"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"
import { CELabel } from "./index"

const emptyArtwork: DtoArtworkForm = {
  artworkName: {
    fr: "",
  },
}

export const CECreateArtworkModal: FC<{
  expositionId: string
  isOpen: boolean
  onConfirmSave: (artwork: DtoArtwork) => void
  onClose: () => void
}> = ({ isOpen, onClose, expositionId, onConfirmSave }) => {
  const s = strings

  const { mutateAsync: saveArtwork, isLoading } = useSaveNewArtwork()

  const [newArtwork, setNewArtwork] = useState<DtoArtworkForm>(emptyArtwork)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = await saveArtwork({ newArtwork, expoId: expositionId })
    setNewArtwork(emptyArtwork)
    if (data) {
      onConfirmSave(data)
    }
  }

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={s.CENewArtworkModal.headerTitle}
        onRequestClose={onClose}
        Icon={Picture}
      />
      <CEModalBody>
        <form className="expo-modal-body" onSubmit={onSubmit}>
          <div className="input-container">
            <LabelWithIconContainer>
              <CEIconTooltipCode tags={["br"]} id="expoTitleTooltip" />
              <CELabel title={s.CENewArtworkModal.artworkName} />
            </LabelWithIconContainer>
            <CEInput
              placeholder={s.CENewArtworkModal.artworkNameFrPlaceholder}
              value={newArtwork.artworkName?.fr || ""}
              onChange={(value) =>
                setNewArtwork((prev) => ({
                  ...prev,
                  artworkName: { fr: value },
                }))
              }
            />
          </div>

          <ButtonsContainer>
            <button
              className={`btn ${isLoading && "btn-disabled"} `}
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
              disabled={isLoading}
            >
              {s.button.cancel}
            </button>
            <button
              className={`btn ${
                (isLoading || !newArtwork.artworkName?.fr) && "btn-disabled"
              } `}
              type="submit"
              disabled={isLoading || !newArtwork.artworkName?.fr}
            >
              {s.button.save}
            </button>
          </ButtonsContainer>
        </form>
      </CEModalBody>
    </CEModal>
  )
}
