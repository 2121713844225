import type { DropResult } from "@hello-pangea/dnd"
import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { css } from "styled-components"

import type { DtoArtwork, DtoLanguage } from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings/fr"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import { useSaveExpositionArtworksOrder } from "../../../services/useQueries"
import styled from "../../../styled-components"
import theme from "../../../styled-components/theme"
import { NAVIGATION_MENU_FULL_WIDTH } from "../../layout/CENavigationMenu"
import { CEAddButton, CEButton, CECarousel, CETextPoppins } from "../../ui"
import type { CarouselItem } from "../../ui/CECarousel"
import { CECreateArtworkModal } from "../../ui/CECreateArtworkModal"
import { CEPublishedExpoModal } from "../../ui/CEPublishedExpoModal"
import { ButtonContainer, ButtonsContainer } from "../TabGeneral/TabGeneralForm"
import { TabArtworksForm } from "./TabArtworksForm"

export const TAB_ARTWORK_PADDING = theme.padding.root

export const TabArtworks: FC<{
  artworks: DtoArtwork[]
  expositionId: string
  isPublishedExpo: boolean
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
}> = ({
  expositionId,
  artworks,
  languagesAvailable,
  isLoading,
  isPublishedExpo,
}) => {
  const s = strings.TabArtworksArtworks

  const [artworkOrdered, setArtworkOrdered] = useState(artworks)
  const [artworkSelected, setArtworkSelected] = useState<string | undefined>(
    undefined
  )
  const [isOpenNewArtworkModal, setIsOpenNewArtworkModal] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setArtworkOrdered(artworks)
    }
  }, [artworks, isLoading])

  const { mutateAsync: updateOrder } = useSaveExpositionArtworksOrder()

  const { dispatch, isOrderChanged } = useOrderChanged()

  useEffect(() => {
    for (let i = 0; i < artworks.length; i++) {
      if (artworkOrdered[i].id !== artworks[i].id) {
        dispatch("ORDER_CHANGED")
        break
      }
    }
  }, [artworkOrdered, artworks, dispatch])

  const artworksToCarouselItems: CarouselItem[] = useMemo(
    () =>
      artworkOrdered.map((artwork) => ({
        id: artwork.id,
        imageUrl: artwork.artworkUrlThumbnail,
        alt: artwork.artworkName?.fr,
      })),
    [artworkOrdered]
  )

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (destinationIndex !== undefined) {
      setArtworkOrdered((prevArtworks) => {
        const movedArtwork = prevArtworks.find(
          (_item, index) => index === sourceIndex
        )
        let newCarouselArtworks = prevArtworks.filter(
          (_item, index) => index !== sourceIndex
        )

        if (movedArtwork) {
          newCarouselArtworks.splice(destinationIndex, 0, movedArtwork)
          return newCarouselArtworks
        }
        return prevArtworks
      })
    }
  }

  const currentArtwork = useMemo(
    () => artworkOrdered.find((a) => a.id === artworkSelected),
    [artworkSelected, artworkOrdered]
  )

  return (
    <>
      <Root>
        <AddButtonContainer>
          <CEAddButton onClick={() => setIsOpenNewArtworkModal(true)} />
        </AddButtonContainer>
        <TabTitleContainer>
          <CETextPoppins styles={tabTitleStyles}>{s.tabTitle}</CETextPoppins>
        </TabTitleContainer>
        <CarouselContainer>
          <CECarousel
            items={artworksToCarouselItems}
            activeId={artworkSelected}
            droppableId="artworksDroppable"
            onDragEnd={onDragEnd}
            onClick={setArtworkSelected}
          />
        </CarouselContainer>
        {isOrderChanged && (
          <ButtonsContainer>
            <ButtonContainer>
              <CEButton
                title={strings.button.cancel}
                onClick={() => {
                  setArtworkOrdered(artworks)
                  dispatch("ORDER_CANCELED")
                }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <CEButton
                title={s.edit}
                onClick={async () => {
                  await updateOrder({
                    expositionId,
                    artworkIds: artworkOrdered.map((a) => a.id!),
                  })
                  if (isOrderChanged) {
                    dispatch("ORDER_SAVED")
                  }
                }}
              />
            </ButtonContainer>
          </ButtonsContainer>
        )}
        {currentArtwork && (
          <TabArtworksForm
            key={currentArtwork.id}
            isPublishedExposition={isPublishedExpo}
            expositionId={expositionId}
            onCancel={() => setArtworkSelected(undefined)}
            isLoading={isLoading}
            languagesAvailable={languagesAvailable}
            artwork={currentArtwork}
          />
        )}
      </Root>
      {isPublishedExpo ? (
        <CEPublishedExpoModal
          isOpen={isOpenNewArtworkModal}
          onClose={() => setIsOpenNewArtworkModal(false)}
        />
      ) : (
        <CECreateArtworkModal
          onConfirmSave={(createdArtwork) => {
            setArtworkOrdered([...artworkOrdered, createdArtwork])
            setArtworkSelected(createdArtwork.id)
            setIsOpenNewArtworkModal(false)
          }}
          expositionId={expositionId}
          isOpen={isOpenNewArtworkModal}
          onClose={() => setIsOpenNewArtworkModal(false)}
        />
      )}
    </>
  )
}

const Root = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(
    100% - ${NAVIGATION_MENU_FULL_WIDTH}px - 2 * ${TAB_ARTWORK_PADDING}
  );
  padding: ${TAB_ARTWORK_PADDING};
`

export const AddButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`

export const TabTitleContainer = styled.div``

export const tabTitleStyles = css`
  font-size: ${(props) => props.theme.fontSize.title};
`

export const CarouselContainer = styled.div`
  width: 100%;
  margin-bottom: 14px;
`
