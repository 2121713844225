import strings from "../assets/strings"

export const getDateRangeString = (
  startDate?: string | undefined,
  endDate?: string | undefined
): string | undefined => {
  if (!startDate || !endDate) {
    return
  }
  const start = new Date(startDate)
  const end = new Date(endDate)
  const isStartingAndEndingSameYear =
    start && end && end.getFullYear() === start.getFullYear()
  const startDateString = `${start?.getDate()} ${start?.toLocaleString(
    strings.date.locale,
    { month: "short" }
  )} ${!isStartingAndEndingSameYear ? start?.getFullYear() : ""}`
  const endDateString = `${end?.getDate()} ${end?.toLocaleString("fr-FR", {
    month: "short",
  })} ${end?.getFullYear()}`

  return end
    ? `${strings.date.from} ${startDateString} ${strings.date.to} ${endDateString}`
    : `${strings.date.fromWithoutEnd} ${startDateString}`
}

export const normalizeText = (text: string): string => {
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
}
