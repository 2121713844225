import type { FC, ReactNode } from "react"

import styled from "../../styled-components"
import { CEHeader } from "./CEHeader"

const RootContainer = styled.div`
  padding: 0 10px 10px 10px;
`

export const Main: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <>
      <CEHeader />
      <RootContainer>{children}</RootContainer>
    </>
  )
}
