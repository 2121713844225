import type { FC } from "react"

import strings from "../assets/strings"
import { Main } from "../components/layout/Main"
import { LanguageScreen } from "../components/screens/Configuration/LanguageScreen"
import ErrorScreen from "../components/screens/ErrorScreen"
import { CETextPoppins } from "../components/ui"
import languagesList from "../data/languagesList.json"
import { useGetAllLanguages } from "../services/useQueries"

export interface ConfigurationLanguage {
  [key: string]: {
    code: string
    label: string
  }
}

export const ManageLanguages: FC = () => {
  const availableLanguages = languagesList as ConfigurationLanguage
  const { data, isLoading, isError, isFetching } = useGetAllLanguages()
  if (isLoading) {
    return (
      <Main>
        <div className="row-container">
          <CETextPoppins>{strings.general.loading}</CETextPoppins>
        </div>
      </Main>
    )
  }

  if (isError) {
    return (
      <Main>
        <div className="row-container">
          <ErrorScreen />
        </div>
      </Main>
    )
  }

  return (
    <Main>
      <div className="row-container">
        <LanguageScreen
          availableLanguages={availableLanguages}
          usedLanguages={data}
          isLoading={isLoading || isFetching}
        />
      </div>
    </Main>
  )
}
