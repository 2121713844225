import type { FC } from "react"
import { FormEvent, useState } from "react"
import styled from "styled-components"

import type { DtoLanguage, DtoPlaylist } from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings/fr"
import {
  useDeletePlaylist,
  useUpdatePlaylist,
} from "../../../services/useQueries"
import { CEDropzone, CEInput, CELabel, CETextPoppins } from "../../ui"
import { CEConfirmModal } from "../../ui/CEConfirmModal"
import {
  ContainerHalfWidth,
  ThumbnailImageDropzoneContainer,
  UploadSection,
  UploadSpecificationsContainer,
  uploadSpecificationStyles,
  UploadTitleContainer,
} from "../TabArtworks/TabArtworksForm"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
  InputSectionContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"
import { SongsListForm } from "./SongsListForm"

const initialFormState: DtoPlaylist = {
  id: undefined,
  expositionMoment: undefined,
}

export const TabPlaylistForm: FC<{
  isPublishedExposition: boolean
  playlist?: DtoPlaylist
  expositionId: string
  onCancel: () => void
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
}> = ({
  playlist,
  languagesAvailable,
  isLoading,
  onCancel,
  expositionId,
  isPublishedExposition,
}) => {
  const s = strings.TabPlaylistsForm
  const [playlistFormState, setPlaylistFormState] = useState<DtoPlaylist>(
    playlist?.id
      ? { id: playlist.id, expositionMoment: playlist.expositionMoment }
      : initialFormState
  )

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  const { mutateAsync: updatePlaylist, isLoading: isUpdateOnProgress } =
    useUpdatePlaylist()
  const { mutateAsync: deletePlaylist } = useDeletePlaylist()

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = await updatePlaylist({
      playlist: playlistFormState,
      expoId: expositionId,
    })
    setPlaylistFormState({
      id: data.id,
      expositionMoment: data.expositionMoment,
    })
  }

  return (
    <>
      <Root>
        <ContainerHalfWidth>
          <form onSubmit={onSubmit}>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.expoMomentLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.expoMomentFrPlaceholder}
                    value={
                      playlistFormState.expositionMoment?.[language.code] || ""
                    }
                    onChange={(value) =>
                      setPlaylistFormState((prev) => ({
                        ...prev,
                        expositionMoment: {
                          ...prev.expositionMoment,
                          [language.code]: value !== "" ? value : undefined,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <SongsListForm
                isPublishedExposition={isPublishedExposition}
                expositionId={expositionId}
                playlistId={playlist!.id!}
                songs={playlist?.songs || []}
                languagesAvailable={languagesAvailable}
                isLoadingPlaylist={isLoading}
              />
            </InputSectionContainer>
            <ButtonsContainer>
              <ButtonContainer>
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault()
                    onCancel()
                  }}
                >
                  {strings.button.cancel}
                </button>
              </ButtonContainer>
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  type="submit"
                  disabled={isLoading || isUpdateOnProgress}
                >
                  {strings.button.save}
                </button>
              </ButtonContainer>{" "}
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsConfirmDeleteOpen(true)
                  }}
                  disabled={isLoading || isUpdateOnProgress}
                >
                  {strings.button.delete}
                </button>
              </ButtonContainer>
            </ButtonsContainer>
          </form>
        </ContainerHalfWidth>
        <ContainerHalfWidth>
          <UploadSection>
            <UploadTitleContainer>
              <CETextPoppins>{s.playlistImageTitle}</CETextPoppins>
            </UploadTitleContainer>

            <UploadSpecificationsContainer>
              <CETextPoppins styles={uploadSpecificationStyles}>
                {s.playlistImageSpecifications}
              </CETextPoppins>
            </UploadSpecificationsContainer>

            <ThumbnailImageDropzoneContainer>
              <CEDropzone
                type="image"
                entity={{
                  type: "playlists",
                  field: "media",
                  id: playlistFormState.id!,
                }}
                isEntityLoading={isLoading}
                defaultFileUrl={playlist!.playlistPictureUrl}
              />
            </ThumbnailImageDropzoneContainer>
          </UploadSection>
        </ContainerHalfWidth>
      </Root>
      {isConfirmDeleteOpen && playlistFormState.id && (
        <CEConfirmModal
          isOpen={isConfirmDeleteOpen}
          onCancel={() => {
            setIsConfirmDeleteOpen(false)
          }}
          onConfirm={async () => {
            setIsConfirmDeleteOpen(false)
            await deletePlaylist({
              playlistId: playlistFormState.id!,
              expoId: expositionId,
            })
          }}
          title={strings.CEConfirmModal.deletePlaylistTitle}
          message={strings.CEConfirmModal.deletePlaylist}
        />
      )}
    </>
  )
}

const Root = styled.div`
  display: flex;
  position: relative;
`
