import { FC, FormEvent, useState } from "react"
import styled from "styled-components"

import type {
  DtoExposition,
  DtoLanguageSummary,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { CEAlert, CEButton, CEInputCheckbox, CELabel } from "../../ui"
import {
  ButtonsContainer,
  ButtonContainer,
  InputContainer,
  InputSectionContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"

interface TabLangProps {
  exposition: DtoExposition
  languagesAvailable: DtoLanguageSummary[]
  updateIsLoading: boolean
  onExpoLanguagesSave: (languages: string[]) => void
}

const requiredLanguages = ["fr", "en"]

export const TabLang: FC<TabLangProps> = ({
  exposition,
  languagesAvailable,
  updateIsLoading,
  onExpoLanguagesSave: onExpoSave,
}) => {
  const [languagesUsed, setLanguagesUsed] = useState<string[]>(
    exposition.languages.map((i) => i.id!)
  )

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onExpoSave(languagesUsed)
  }

  const handleChange = (id: string) => {
    if (languagesUsed.some((i) => id === i)) {
      setLanguagesUsed(languagesUsed.filter((i) => i !== id))
    } else {
      setLanguagesUsed((prev) => [...prev, id])
    }
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      <InputSectionContainer>
        <InputContainer>
          <CELabel
            title={strings.TabLanguages.languagesTitle}
            htmlFor="titleFrInput"
            styles={underlinedLabelStyles}
          />
        </InputContainer>
        <ul>
          {languagesAvailable.map((language) => {
            const langIsUsedByExpo = languagesUsed.some(
              (i) => language.id === i
            )
            return (
              <CEInputCheckbox
                id={language.id}
                name={language.code}
                label={language.label}
                isChecked={langIsUsedByExpo}
                isDisabled={
                  requiredLanguages.some(
                    (i) => i === language.code.toLocaleLowerCase()
                  ) || exposition.published
                }
                onChange={handleChange}
                key={language.id}
              />
            )
          })}
        </ul>
      </InputSectionContainer>
      {!exposition.published ? (
        <ButtonsContainer>
          <ButtonContainer>
            <CEButton
              title={strings.button.save}
              type="submit"
              disabled={updateIsLoading}
            />
          </ButtonContainer>
        </ButtonsContainer>
      ) : (
        <CEAlert
          type="error"
          message={strings.TabLanguages.canNotBeUpdateMessage}
        />
      )}
    </Form>
  )
}

const Form = styled.form`
  margin: 50px 15px;
`
