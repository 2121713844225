import { FC, useEffect, useState } from "react"
import styled from "styled-components"

import type { DtoAnecdote, DtoLanguage } from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import {
  useDeleteAnecdote,
  useSaveArtworkAnecdotesOrder,
} from "../../../services/useQueries"
import theme from "../../../styled-components/theme"
import { X } from "../../icons"
import { CEAddButton, CEAnecdoteListItem, CEAnecdoteModal } from "../../ui"
import { CEConfirmModal } from "../../ui/CEConfirmModal"
import { CEPublishedExpoModal } from "../../ui/CEPublishedExpoModal"
import { CEVerticalDraggableList } from "../../ui/CEVerticalDraggableList"

export const AnecdotesListForm: FC<{
  anecdotes: DtoAnecdote[]
  isPublishedExposition: boolean
  expositionId: string
  artworkId: string
  languagesAvailable: DtoLanguage[]
  isArtworkLoading: boolean
}> = ({
  anecdotes,
  languagesAvailable,
  expositionId,
  artworkId,
  isPublishedExposition,
  isArtworkLoading,
}) => {
  const s = strings.TabArtworksForm

  const { mutateAsync: deleteAnecdote } = useDeleteAnecdote()

  const { mutateAsync: updateAnecdoteOrder } = useSaveArtworkAnecdotesOrder()

  const [orderedAnecdotes, setOrderedAnecdotes] = useState<DtoAnecdote[]>([])

  const [selectedAnecdoteId, setSelectedAnecdoteId] = useState<
    string | undefined
  >(undefined)
  const [isConfirmDeleteAnecdoteId, setIsConfirmDeleteAnecdoteId] = useState<
    string | undefined
  >(undefined)

  const { dispatch, isOrderChanged } = useOrderChanged()

  useEffect(() => {
    if (isOrderChanged) {
      updateAnecdoteOrder({
        expositionId,
        artworkId,
        anecdoteIds: orderedAnecdotes.map((a) => a.id!),
      })
      dispatch("ORDER_SAVED")
    }
  }, [
    artworkId,
    expositionId,
    dispatch,
    isOrderChanged,
    orderedAnecdotes,
    updateAnecdoteOrder,
  ])

  useEffect(() => {
    if (!isArtworkLoading) {
      setOrderedAnecdotes(anecdotes)
    }
  }, [anecdotes, isArtworkLoading, orderedAnecdotes.length])

  return (
    <>
      <CEVerticalDraggableList
        droppableId={"droppableAnecdotes"}
        label={s.anecdotesLabel}
        listIntent={1}
        onDragEnd={(orderedList) => {
          setOrderedAnecdotes(orderedList)
          dispatch("ORDER_CHANGED")
        }}
        list={orderedAnecdotes}
        renderItem={(item) => (
          <ItemContainer>
            <CEAnecdoteListItem
              id={item?.id || ""}
              title={item.title?.fr || ""}
              anecdoteExplanation={item.anecdoteExplanation?.fr || ""}
              onClick={() => {
                setSelectedAnecdoteId(item.id)
              }}
            />
            <span
              onClick={() => {
                setIsConfirmDeleteAnecdoteId(item.id)
              }}
            >
              <X
                width={"28"}
                height={"28"}
                stroke={theme.colors.primary.main}
                strokeWidth={"2"}
                fill={theme.colors.primary.main}
              />
            </span>
          </ItemContainer>
        )}
      />
      {(!anecdotes.length || anecdotes.length < 2) && (
        <AddListItemContainer>
          <CEAddButton
            small
            onClick={() => {
              setSelectedAnecdoteId("create")
            }}
          />
        </AddListItemContainer>
      )}

      {isPublishedExposition && selectedAnecdoteId === "create" ? (
        <CEPublishedExpoModal
          isOpen={!!selectedAnecdoteId}
          onClose={() => setSelectedAnecdoteId(undefined)}
        />
      ) : (
        <CEAnecdoteModal
          onConfirm={(anecdote) => {
            if (!orderedAnecdotes.some((a) => a.id === anecdote.id)) {
              setOrderedAnecdotes([...orderedAnecdotes, anecdote])
            }
            setSelectedAnecdoteId(undefined)
          }}
          isOpen={!!selectedAnecdoteId}
          onRequestClose={() => setSelectedAnecdoteId(undefined)}
          expositionId={expositionId}
          artworkId={artworkId}
          languagesAvailable={languagesAvailable}
          anecdote={anecdotes.find((a) => a.id === selectedAnecdoteId)}
        />
      )}
      {!!isConfirmDeleteAnecdoteId && (
        <CEConfirmModal
          isOpen={!!isConfirmDeleteAnecdoteId}
          onCancel={() => {
            setIsConfirmDeleteAnecdoteId(undefined)
          }}
          onConfirm={async () => {
            setIsConfirmDeleteAnecdoteId(undefined)
            await deleteAnecdote({
              anecdoteId: isConfirmDeleteAnecdoteId,
              artworkId,
              expositionId,
            })
          }}
          title={strings.CEConfirmModal.deleteAnecdoteTitle}
          message={strings.CEConfirmModal.deleteAnecdote}
        />
      )}
    </>
  )
}

export const AddListItemContainer = styled.div`
  width: 100%;
`
export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`
