import type { FC } from "react"
import "../../assets/scss/button.scss"

interface CEButtonProps {
  title: string
  onClick?: () => void
  disabled?: boolean
  isDelete?: boolean
  type?: "submit" | "button" | "reset"
}

export const CEButton: FC<CEButtonProps> = ({
  title,
  onClick,
  disabled,
  isDelete,
  type,
}) => {
  return (
    <button
      className={
        disabled ? "btn btn-disabled" : isDelete ? "btn-delete" : "btn"
      }
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}

export default CEButton
