import { useState, FC, FormEvent, useMemo } from "react"

import "../../assets/scss/newExpoModal.scss"
import strings from "../../assets/strings"
import { useCreateExposition } from "../../services/useQueries"
import { Picture } from "../icons"
import {
  ButtonsContainer,
  LabelWithIconContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEIconTooltipCode from "./CEIconTooltipCode"
import CEInput from "./CEInput"
import { CEInputCheckbox } from "./CEInputCheckbox"
import { CELabel } from "./CELabel"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

interface CEExpoNewModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

interface newExpoState {
  title: string
  type?: "int" | "ext"
}

const emptyNewExpo: newExpoState = {
  title: "",
  type: undefined,
}

const CEExpoNewModal: FC<CEExpoNewModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const s = strings.CEExpoNewModal

  const { mutateAsync: createExposition, isLoading } = useCreateExposition()

  const [newExpo, setNewExpo] = useState<newExpoState>(emptyNewExpo)

  const submitIsDisabled = useMemo(() => {
    return isLoading || !newExpo.title || typeof newExpo.type === "undefined"
  }, [isLoading, newExpo])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (newExpo.type) {
      await createExposition({
        title: {
          fr: newExpo.title,
        },
        isExternal: newExpo.type === "ext",
      })
      setNewExpo(emptyNewExpo)
      onRequestClose()
    }
  }

  const onClose = () => {
    setNewExpo(emptyNewExpo)
    onRequestClose()
  }

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={s.headerTitle}
        onRequestClose={onClose}
        Icon={Picture}
      />
      <CEModalBody>
        <form className="expo-modal-body" onSubmit={onSubmit}>
          <div className="input-container">
            <LabelWithIconContainer>
              <CEIconTooltipCode tags={["br"]} id="expoTitleTooltip" />
              <CELabel title={s.expoTitleLabel} htmlFor="expoTitle" />
            </LabelWithIconContainer>
            <CEInput
              id="expoTitleInput"
              placeholder={s.expoTitlePlaceholder}
              value={newExpo.title}
              onChange={(value) =>
                setNewExpo((prev) => ({ ...prev, title: value }))
              }
            />
          </div>
          <div className="expo-modal-type-select">
            <CEInputCheckbox
              id="type-external"
              name="type-external"
              isChecked={newExpo.type === "int"}
              isDisabled={false}
              label={s.expoType.internal}
              onChange={() => setNewExpo({ ...newExpo, type: "int" })}
            />

            <CEInputCheckbox
              id="type-internal"
              name="type-internal"
              isChecked={newExpo.type === "ext"}
              isDisabled={false}
              label={s.expoType.external}
              onChange={() => setNewExpo({ ...newExpo, type: "ext" })}
            />
          </div>
          <ButtonsContainer>
            <button
              className={`btn ${isLoading && "btn-disabled"} `}
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
              disabled={isLoading}
            >
              {strings.button.cancel}
            </button>
            <button
              className={`btn ${submitIsDisabled ? "btn-disabled" : ""} `}
              type="submit"
              title={s.createNewExpo}
              disabled={submitIsDisabled}
            >
              {s.createNewExpo}
            </button>
          </ButtonsContainer>
        </form>
      </CEModalBody>
    </CEModal>
  )
}

export default CEExpoNewModal
