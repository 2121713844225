import type { FC, ReactNode } from "react"
import Modal from "react-modal"

// DATA
// cf. https://github.com/reactjs/react-modal#examples
const modalStyles: Modal.Styles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,

    backgroundColor: "transparent",
    border: "none",
    padding: 0,
  },
}

// Required so that screen readers do not read the background, cf. http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement("#root")

// TYPES
interface CEModalProps {
  isOpen: boolean
  onRequestClose?: () => void // Executed when use clicks outside the modal
  contentLabel?: string // aria-label for the modal
  children?: ReactNode
}

const CEModal: FC<CEModalProps> = ({
  isOpen,
  onRequestClose,
  contentLabel,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyles}
      contentLabel={contentLabel}
    >
      {children}
    </Modal>
  )
}

export default CEModal
