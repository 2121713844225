import type { FC } from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"

import type { DtoLanguage, DtoSong } from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import {
  useDeleteSong,
  useSavePlaylistSongOrder,
} from "../../../services/useQueries"
import theme from "../../../styled-components/theme"
import { X } from "../../icons"
import { CEAddButton, CESongListItem, CESongModal } from "../../ui"
import { CEConfirmModal } from "../../ui/CEConfirmModal"
import { CEPublishedExpoModal } from "../../ui/CEPublishedExpoModal"
import { CEVerticalDraggableList } from "../../ui/CEVerticalDraggableList"

export const SongsListForm: FC<{
  songs: DtoSong[]
  isPublishedExposition: boolean
  expositionId: string
  playlistId: string
  languagesAvailable: DtoLanguage[]
  isLoadingPlaylist: boolean
}> = ({
  songs,
  languagesAvailable,
  expositionId,
  playlistId,
  isPublishedExposition,
  isLoadingPlaylist,
}) => {
  const s = strings.TabPlaylistsForm

  const { mutateAsync: deleteSong } = useDeleteSong()

  const { mutateAsync: updateSongsOrder } = useSavePlaylistSongOrder()

  const [orderedSongs, setOrderedSongs] = useState(songs)
  const [selectedSongId, setSelectedSongId] = useState<string | undefined>(
    undefined
  )
  const [isConfirmDeleteSongId, setIsConfirmDeleteSongId] = useState<
    string | undefined
  >(undefined)

  const { dispatch, isOrderChanged } = useOrderChanged()

  useEffect(() => {
    if (isOrderChanged) {
      updateSongsOrder({
        expositionId,
        playlistId,
        songIds: orderedSongs.map((a) => a.id!),
      })
      dispatch("ORDER_SAVED")
    }
  }, [
    expositionId,
    dispatch,
    isOrderChanged,
    updateSongsOrder,
    playlistId,
    orderedSongs,
  ])

  useEffect(() => {
    if (!isLoadingPlaylist) {
      setOrderedSongs(songs)
    }
  }, [songs, isLoadingPlaylist])

  return (
    <>
      <CEVerticalDraggableList
        droppableId={"droppableSongs"}
        label={s.songsLabel}
        listIntent={1}
        onDragEnd={(orderedList) => {
          dispatch("ORDER_CHANGED")
          setOrderedSongs(orderedList)
        }}
        list={orderedSongs}
        renderItem={(item) => (
          <ItemContainer>
            <CESongListItem
              id={item?.id || ""}
              trackTitle={item.trackTitle}
              trackArtist={item.trackArtist || ""}
              onClick={() => {
                setSelectedSongId(item.id)
              }}
            />
            <span
              onClick={() => {
                setIsConfirmDeleteSongId(item.id)
              }}
            >
              <X
                width={"28"}
                height={"28"}
                stroke={theme.colors.primary.main}
                strokeWidth={"2"}
                fill={theme.colors.primary.main}
              />
            </span>
          </ItemContainer>
        )}
      />
      <AddListItemContainer>
        <CEAddButton
          small
          onClick={() => {
            setSelectedSongId("create")
          }}
        />
      </AddListItemContainer>

      {isPublishedExposition && selectedSongId === "create" ? (
        <CEPublishedExpoModal
          isOpen={!!selectedSongId}
          onClose={() => setSelectedSongId(undefined)}
        />
      ) : (
        <CESongModal
          onConfirm={(song) => {
            if (!orderedSongs.some((a) => a.id === song.id)) {
              setOrderedSongs([...orderedSongs, song])
            }
            setSelectedSongId(undefined)
          }}
          isOpen={!!selectedSongId}
          onRequestClose={() => setSelectedSongId(undefined)}
          expositionId={expositionId}
          languagesAvailable={languagesAvailable}
          song={songs.find((a) => a.id === selectedSongId)}
          playlistId={playlistId}
        />
      )}
      {!!isConfirmDeleteSongId && (
        <CEConfirmModal
          isOpen={!!isConfirmDeleteSongId}
          onCancel={() => {
            setIsConfirmDeleteSongId(undefined)
          }}
          onConfirm={async () => {
            setIsConfirmDeleteSongId(undefined)
            await deleteSong({
              songId: isConfirmDeleteSongId,
              playlistId,
              expositionId,
            })
          }}
          title={strings.CEConfirmModal.deleteSongTitle}
          message={strings.CEConfirmModal.deleteSong}
        />
      )}
    </>
  )
}

export const AddListItemContainer = styled.div`
  width: 100%;
`

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`
