import { useState, type FC } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import strings from "../../../assets/strings"
import { useUpdateMinimumVersion } from "../../../services/useQueries"
import { CEButton, CEInput, CELabel } from "../../ui"
import {
  ButtonsContainer,
  InputContainer,
  InputSectionContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"

interface VersionScreenProps {
  currentVersion: { id: string; version: string }
}

export const VersionScreen: FC<VersionScreenProps> = ({ currentVersion }) => {
  const navigate = useNavigate()

  const [newVersionState, setNewVersionState] = useState<string>("")
  const { mutateAsync: updateVersion, isLoading } = useUpdateMinimumVersion()

  const handleOnCancel = () => {
    setNewVersionState("")
  }

  const isVersionValid = (str: string) => {
    const regex = /^\d+\.\d+\.\d+$/
    return regex.test(str)
  }

  return (
    <Root>
      <InputSectionContainer>
        <InputContainer>
          <CELabel
            title={strings.TabOptions.versionTitle}
            styles={underlinedLabelStyles}
          />
        </InputContainer>
        <InputContainer>
          <CELabel
            title={
              currentVersion.version === "" ? "0.0.0" : currentVersion.version
            }
          />
        </InputContainer>
      </InputSectionContainer>
      <InputSectionContainer>
        <InputContainer>
          <CELabel title={strings.TabOptions.versionField} />
          <CEInput
            placeholder={strings.TabOptions.versionPlaceholder}
            value={newVersionState}
            onChange={(value) => setNewVersionState(value)}
          />
        </InputContainer>
      </InputSectionContainer>
      <ButtonsContainer>
        <CEButton title={strings.button.back} onClick={() => navigate(-1)} />
        <Separator />
        <CEButton
          title={strings.LanguagesScreen.submit}
          disabled={
            !newVersionState.length ||
            isLoading ||
            !isVersionValid(newVersionState)
          }
          onClick={() => updateVersion(newVersionState)}
        />
        <Separator />
        <CEButton
          title={strings.button.cancel}
          disabled={!newVersionState.length || isLoading}
          onClick={handleOnCancel}
        />
      </ButtonsContainer>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: ${(props) => props.theme.padding.root};
`
const Separator = styled.div`
  margin-left: 10px;
`
