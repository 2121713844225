import dompurify from "dompurify"
import type { FC } from "react"

import strings from "../../assets/strings"
import styled, { css } from "../../styled-components"
import theme from "../../styled-components/theme"
import { Code } from "../icons"
import CETextPoppins from "./CETextPoppins"

// TYPES
type Tag = "br" | "i" | "b"

interface CEIconTooltipCodeProps {
  tags: Array<Tag>
  id: string
  top?: boolean
}

// STYLED COMPONENTS
const ICON_DIMENSIONS = "14"

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: ${(props) => props.theme.spacing.labelMargin};
`

interface IconTooltipContainerProps {
  tooltipId: string
}
const IconTooltipContainer = styled.div<IconTooltipContainerProps>`
  position: relative;

  &:hover > #${(props) => props.tooltipId} {
    display: block;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(${ICON_DIMENSIONS}px + 8px);
  height: calc(${ICON_DIMENSIONS}px + 8px);
  background-color: ${(props) => props.theme.colors.primary.main};
  border-radius: 100%;
`

interface TooltipContainerProps {
  top?: boolean
}
const TooltipContainer = styled.div<TooltipContainerProps>`
  display: none;
  position: absolute;
  ${({ top }) => top && "bottom: 100%;"}
  ${({ top }) => !top && "top: 0;"}
  left: 100%;
  padding-left: 8px;
  z-index: 1;
`
const Tooltip = styled.div`
  width: 400px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 6px 12px 6px 12px;
`

const TitleContainer = styled.div``
const titleStyles = css`
  font-size: 18px;
`
const SubTitleContainer = styled.div`
  margin-bottom: 12px;
`

const TagContainer = styled.div`
  margin-bottom: 12px;
`
const TagNameAndDescription = styled.div``
const tagNameStyles = css`
  margin-right: 8px;
`
const tagContentAndRenderStyles = css`
  font-size: ${(props) => props.theme.fontSize.tooltip};
`

const ContentContainer = styled.div`
  display: flex;
  padding-left: 12px;
`
const ContentLabelContainer = styled.div`
  margin-right: 8px;
  white-space: nowrap;
`
const ContentValueContainer = styled.div`
  display: flex;
  align-items: center;
`

const RenderContainer = styled.div`
  display: flex;
  padding-left: 12px;
`
const RenderLabelContainer = styled.div`
  margin-right: 8px;
  white-space: nowrap;
`
const RenderValueContainer = styled.div`
  display: flex;
  align-items: center;
`

const HtmlRender = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: ${(props) => props.theme.fontSize.tooltip};
`

/**
 * Display a code (<>) icon with a tooltip showing the tags that can be used in an input (<br>, <i>, or <b>)
 */
const CEIconTooltipCode: FC<CEIconTooltipCodeProps> = ({
  tags,
  id,
  top = false,
}) => {
  // Strings
  const s = strings.CEIconTooltipCode

  return (
    <Root>
      {/* ICON & TOOLTIP */}
      <IconTooltipContainer tooltipId={id}>
        {/* ICON */}
        <IconContainer>
          <Code
            width={ICON_DIMENSIONS}
            height={ICON_DIMENSIONS}
            stroke={theme.colors.white}
          />
        </IconContainer>

        {/* TOOLTIP */}
        <TooltipContainer id={id} top={top}>
          <Tooltip>
            {/* TITLE */}
            <TitleContainer>
              <CETextPoppins styles={titleStyles} fontType="SemiBold">
                {s.title}
              </CETextPoppins>
            </TitleContainer>

            {/* SUBTITLE */}
            <SubTitleContainer>
              <CETextPoppins fontType="Italic">{s.subTitle}</CETextPoppins>
            </SubTitleContainer>

            {/* TAGS */}
            {tags.map((tag) => (
              <TagContainer key={tag}>
                {/* NAME & DESCRIPTION */}
                <TagNameAndDescription>
                  <CETextPoppins styles={tagNameStyles} fontType="SemiBold">
                    {s[tag].tag}
                  </CETextPoppins>
                  <CETextPoppins>{s[tag].description}</CETextPoppins>
                </TagNameAndDescription>

                {/* EXAMPLE */}
                <ContentContainer>
                  {/* CONTENT */}
                  <ContentLabelContainer>
                    <CETextPoppins styles={tagContentAndRenderStyles}>
                      {s.content}
                    </CETextPoppins>
                  </ContentLabelContainer>
                  <ContentValueContainer>
                    <CETextPoppins styles={tagContentAndRenderStyles}>
                      {s[tag].content}
                    </CETextPoppins>
                  </ContentValueContainer>
                </ContentContainer>

                {/* RENDER */}
                <RenderContainer>
                  <RenderLabelContainer>
                    <CETextPoppins styles={tagContentAndRenderStyles}>
                      {s.render}
                    </CETextPoppins>
                  </RenderLabelContainer>
                  <RenderValueContainer>
                    <HtmlRender
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(s[tag].render),
                      }}
                    />
                  </RenderValueContainer>
                </RenderContainer>
              </TagContainer>
            ))}
          </Tooltip>
        </TooltipContainer>
      </IconTooltipContainer>
    </Root>
  )
}

export default CEIconTooltipCode
