import { FC, useEffect, useState } from "react"

import type {
  DtoExposition,
  ExpositionStatusType,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useChangeExpoStatus } from "../../../services/useQueries"
import styled, { css } from "../../../styled-components"
import theme from "../../../styled-components/theme"
import { CEButton, CETextPoppins } from "../../ui"
import { ButtonContainer } from "../TabGeneral/TabGeneralForm"

const TAB_ARTWORK_PADDING = theme.padding.root
const RADIO_WIDTH_PX = 25
const s = strings.TabStatusForm

const getStatus = (exposition: DtoExposition) => {
  return exposition.published && !exposition.upcoming
    ? "publish"
    : exposition.published && exposition.upcoming
    ? "upcoming"
    : exposition.isArchived
    ? "archived"
    : "draft"
}

const ExpositionStatusData = [
  {
    title: s.draftTitle,
    explanation: s.draftExplanation,
    status: "draft" as ExpositionStatusType,
  },
  {
    title: s.upcomingTitle,
    explanation: s.upcomingExplanation,
    status: "upcoming" as ExpositionStatusType,
  },
  {
    title: s.publishedTitle,
    explanation: s.publishedExplanation,
    status: "publish" as ExpositionStatusType,
  },
  {
    title: s.archivedTitle,
    explanation: s.archivedExplanation,
    status: "archived" as ExpositionStatusType,
  },
]

const TabStatus: FC<{ exposition: DtoExposition }> = ({ exposition }) => {
  const [expoStatus, setExpoStatus] = useState<ExpositionStatusType>(
    getStatus(exposition)
  )

  const {
    mutateAsync: mutateAsyncSwitchStatus,
    isLoading: isLoadingSwitchPublishStatus,
    isError: isErrorSwitchPublishStatus,
  } = useChangeExpoStatus()

  const handleOnSave = async () => {
    if (expoStatus) {
      await mutateAsyncSwitchStatus({
        id: exposition.id,
        status: expoStatus,
      })
    }
  }

  useEffect(() => {
    if (isErrorSwitchPublishStatus) {
      setExpoStatus(getStatus(exposition))
    }
  }, [exposition, isErrorSwitchPublishStatus])

  return (
    <Root>
      <>
        <StatusTitleContainer>
          <CETextPoppins styles={titleStyles}>{s.title}</CETextPoppins>
        </StatusTitleContainer>
        <ChooseStatus>
          {expoStatus &&
            ExpositionStatusData.map((item) => {
              return (
                <StatusContainer key={item.status}>
                  <LabelTitleContainer>
                    <RadioInputContainer>
                      <input
                        type="radio"
                        id={item.status}
                        name={item.status}
                        value={item.status}
                        checked={expoStatus === item.status}
                        onChange={() => setExpoStatus(item.status)}
                      />
                    </RadioInputContainer>

                    <label>
                      <CETextPoppins styles={labelTitleStyles}>
                        {item.title}
                      </CETextPoppins>
                    </label>
                  </LabelTitleContainer>
                  <LabelExplanationContainer>
                    <CETextPoppins>{item.explanation}</CETextPoppins>
                  </LabelExplanationContainer>
                </StatusContainer>
              )
            })}
        </ChooseStatus>
        <ButtonContainer>
          <CEButton
            title={strings.button.save}
            onClick={handleOnSave}
            disabled={
              expoStatus === getStatus(exposition) ||
              isLoadingSwitchPublishStatus
            }
          />
        </ButtonContainer>
      </>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${TAB_ARTWORK_PADDING};
`

const StatusTitleContainer = styled.div`
  margin-bottom: 14px;
`
const titleStyles = css`
  font-size: ${({ theme }) => theme.fontSize.title};
`

const ChooseStatus = styled.div`
  margin-bottom: 32px;
`

const StatusContainer = styled.div`
  padding-left: 20px;
  margin-bottom: 14px;
`
const LabelTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const labelTitleStyles = css`
  font-size: ${({ theme }) => theme.fontSize.subtitle};
`

const RadioInputContainer = styled.div`
  width: ${RADIO_WIDTH_PX}px;
`

const LabelExplanationContainer = styled.div`
  padding-left: ${RADIO_WIDTH_PX}px;
`

export default TabStatus
