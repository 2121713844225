import dompurify from "dompurify"
import type { FC } from "react"

import styled, { css } from "../../styled-components"
import CETextPoppins from "./CETextPoppins"

// TYPES
interface CEAnecdoteListItemProps {
  id: string
  title: string
  anecdoteExplanation: string
  onClick: (id: string) => void
}

// STYLED COMPONENTS
const Root = styled.div`
  display: flex;
  width: 100%;
  max-height: 25px;
  text-align: left;
  padding: 12px;
  border: 1px solid black;
  border-radius: 20px;
  box-shadow: 3px 3px 4px ${(props) => props.theme.colors.shadow};

  outline: none;

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      `${props.theme.colors.secondary.light}${props.theme.alpha.buttonHover}`};
  }

  &:active {
    background-color: ${(props) =>
      `${props.theme.colors.secondary.light}${props.theme.alpha.buttonActive}`};
  }

  /* Display edit icon on hover */
  &:hover div {
    opacity: 1;
  }
`

const TextContainer = styled.div`
  min-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* TODO: MAKE ELLIPSIS WORK */
`

const titleStyles = css`
  text-transform: uppercase;
  padding-right: 8px;
`

const anecdoteExplanationStyles = css``

/**
 * List item for a single anecdote. Display a summary of the anecdote info, and accepts an onClick (in <TabArtworksForm />: open anecdote modal)
 */
const CEAnecdoteListItem: FC<CEAnecdoteListItemProps> = ({
  id,
  title,
  anecdoteExplanation,
  onClick,
}) => {
  return (
    <Root onClick={() => onClick(id)}>
      {/* TITLE */}
      <TextContainer>
        <CETextPoppins styles={titleStyles} fontType={"SemiBold"}>
          {title}
        </CETextPoppins>
      </TextContainer>

      {/* BEGINNING OF THE ANECDOTE EXPLANATION */}
      <TextContainer>
        <CETextPoppins styles={anecdoteExplanationStyles}>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(anecdoteExplanation),
            }}
          />
        </CETextPoppins>
      </TextContainer>
    </Root>
  )
}

export default CEAnecdoteListItem
