import { FC, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"

import strings from "../assets/strings"
import { TabActiveContext } from "../components/contexts/TabActiveContext"
import CENavigationMenu from "../components/layout/CENavigationMenu"
import { Main } from "../components/layout/Main"
import ErrorScreen from "../components/screens/ErrorScreen"
import { TabLang } from "../components/screens/TabLangues/TabLang"
import { CETextPoppins } from "../components/ui"
import {
  useGetExposition,
  useGetAllLanguages,
  useSaveExpositionLanguages,
} from "../services/useQueries"

export const ExpositionLanguagesPage: FC = () => {
  const { expoId } = useParams()

  const { tabActive, updateTabActive } = useContext(TabActiveContext)

  const {
    data: exposition,
    isLoading: isLoadingExposition,
    isError: isErrorExposition,
  } = useGetExposition(expoId)

  const {
    data: languagesAvailable,
    isLoading: isLoadingLanguagesAvailable,
    isError: isErrorLanguages,
  } = useGetAllLanguages()

  const { mutateAsync: saveExpositionLanguages, isLoading: updateIsLoading } =
    useSaveExpositionLanguages()

  useEffect(() => {
    if (tabActive !== "langues") {
      updateTabActive("langues")
    }
  }, [expoId, exposition, tabActive, updateTabActive])

  if (isLoadingExposition || isLoadingLanguagesAvailable) {
    return <CETextPoppins>{strings.general.loading}</CETextPoppins>
  }

  if (
    isErrorExposition ||
    isErrorLanguages ||
    !exposition ||
    !languagesAvailable
  ) {
    return <ErrorScreen />
  }

  return (
    <Main>
      <div className="row-container">
        <CENavigationMenu />
        <TabLang
          exposition={exposition}
          languagesAvailable={languagesAvailable}
          updateIsLoading={updateIsLoading}
          onExpoLanguagesSave={async (languageIds) => {
            await saveExpositionLanguages({
              id: exposition.id,
              languageIds: languageIds,
            })
          }}
        />
      </div>
    </Main>
  )
}
