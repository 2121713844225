import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api"
import { FC, useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import strings from "../../assets/strings"

const s = strings.CEMapsAutocomplete

const containerStyle = {
  width: "400px",
  border: "1px solid #000",
  borderRadius: "5%",
  boxShadow: "2px 2px 4px #CCC",
  overflow: "hidden",
  paddingTop: "8px",
  height: "400px",
}
export const defaultCenter = {
  lat: 48.76237930336084,
  lng: 2.3649627503983828,
}

export interface SuggestInfo {
  address: {
    location: string
    streetNumber: string
    streetName: string
    zipCode: string
    city: string
    country: string
  }
  latitude: string
  longitude: string
  phoneNumber: string
  website: {
    title: string
    uri: string
  }
}

const GeosuggestContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

const CEMapContainer = styled.div`
  display: flex;
  margin: 0;
`

export const CEMaps: FC<{
  onChange: (info: SuggestInfo) => void
  position?: google.maps.LatLngLiteral
  isSearchDisabled?: boolean
}> = ({ onChange, position, isSearchDisabled }) => {
  let [autocompleteLoaded, setAutocompleteLoaded] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined)
  const [marker, setMarker] = useState<google.maps.LatLngLiteral | undefined>(
    position
  )
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY || "",
    mapIds: [process.env.REACT_APP_GMAPS_MAPS_ID || ""],
    libraries: ["places"],
  })

  const handlePlaceChanged = useCallback(() => {
    if (autocompleteLoaded) {
      const resultPlace = autocompleteLoaded.getPlace()
      if (resultPlace.geometry?.location) {
        setMarker(resultPlace.geometry?.location.toJSON())
        onChange({
          address: {
            city:
              resultPlace.address_components?.find((address) =>
                address.types.includes("locality")
              )?.long_name || "",
            country:
              resultPlace.address_components?.find((address) =>
                address.types.includes("country")
              )?.short_name || "",
            location: resultPlace.name || "",
            streetName:
              resultPlace.address_components?.find((address) =>
                address.types.includes("route")
              )?.long_name || "",
            streetNumber:
              resultPlace.address_components?.find((address) =>
                address.types.includes("street_number")
              )?.long_name || "",
            zipCode:
              resultPlace.address_components?.find((address) =>
                address.types.includes("postal_code")
              )?.long_name || "",
          },
          latitude:
            autocompleteLoaded
              .getPlace()
              .geometry?.location?.toJSON()
              .lat.toString() || "",
          longitude:
            autocompleteLoaded
              .getPlace()
              .geometry?.location?.toJSON()
              .lng.toString() || "",
          phoneNumber:
            autocompleteLoaded.getPlace().formatted_phone_number || "",
          website: {
            title: autocompleteLoaded.getPlace().website || "",
            uri: autocompleteLoaded.getPlace().website || "",
          },
        })
      }
    }
  }, [autocompleteLoaded, onChange])

  useEffect(() => {
    if (position?.lat !== marker?.lat || position?.lng !== marker?.lng) {
      setMarker(position)
    }
  }, [marker?.lat, marker?.lng, position])

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <CEMapContainer>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={marker || defaultCenter}
        zoom={10}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        <>
          {!isSearchDisabled && (
            <GeosuggestContainer>
              <Autocomplete
                onLoad={(autocomplete) => {
                  setAutocompleteLoaded(autocomplete)
                }}
                onPlaceChanged={handlePlaceChanged}
              >
                <input
                  disabled={isSearchDisabled}
                  type="text"
                  placeholder={s.placeholder}
                  style={{
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    width: "240px",
                    height: "32px",
                    padding: "0 12px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                    fontSize: "14px",
                    outline: "none",
                    textOverflow: "ellipses",
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-120px",
                  }}
                />
              </Autocomplete>
            </GeosuggestContainer>
          )}
          {marker && <Marker position={marker} />}
        </>
      </GoogleMap>
    </CEMapContainer>
  ) : (
    <>Loading...</>
  )
}
