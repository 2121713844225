import type { FC } from "react"
import type { FlattenInterpolation, ThemeProps } from "styled-components"

import styled from "../../styled-components"
import type { ThemeInterface } from "../../styled-components/theme"
import CETextPoppins from "./CETextPoppins"

interface CELabelProps {
  title: string
  htmlFor?: string // id of the corresponding input, so that user can clic the label to focus the input
  error?: boolean
  styles?: FlattenInterpolation<ThemeProps<ThemeInterface>> // Style using 'css' from 'styled-components'
}

interface LabelProps {
  error?: boolean
}

const Label = styled.label<LabelProps>`
  color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.black};
  margin-right: ${(props) => props.theme.spacing.labelMargin};
`

export const CELabel: FC<CELabelProps> = ({
  title,
  htmlFor,
  error = false,
  styles,
}) => {
  return (
    <Label error={error} htmlFor={htmlFor}>
      <CETextPoppins styles={styles}>{title}</CETextPoppins>
    </Label>
  )
}
