import { FC, useMemo } from "react"

import { CEButton } from "."
import type { DtoCountry } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import countriesList from "../../data/countriesList.json"
import { useSaveCountries } from "../../services/useQueries"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

export const CESuggestNewCountryModal: FC<{
  countryCode: string
  onClose: () => void
  onConfirm: (country: DtoCountry) => void
}> = ({ countryCode, onClose, onConfirm }) => {
  const { mutateAsync: saveCountries } = useSaveCountries()

  const countryOnList = useMemo(() => {
    return countriesList[countryCode as keyof typeof countriesList]
  }, [countryCode])

  const onSaveCountry = async () => {
    const data = await saveCountries([
      { code: countryCode, label: countryOnList.fr },
    ])
    if (data[0]) {
      onConfirm(data[0])
    }
  }

  return (
    <CEModal isOpen>
      <CEModalHeader
        title={strings.CountriesScreen.form.add}
        onRequestClose={onClose}
      />

      <CEModalBody>
        {countryOnList ? (
          <div>
            <InputContainer>
              {strings.TabGeneralForm.newCountryPrefix}
              {countryOnList.fr} ?
            </InputContainer>
            <ButtonsContainer>
              <ButtonContainer>
                <CEButton
                  type="button"
                  onClick={onClose}
                  title={strings.button.cancel}
                />
              </ButtonContainer>
              <ButtonContainer>
                <CEButton
                  type="button"
                  onClick={onSaveCountry}
                  title={strings.button.confirm}
                />
              </ButtonContainer>
            </ButtonsContainer>
          </div>
        ) : (
          <div>
            <InputContainer>
              {strings.TabGeneralForm.newCountryNotFound}
            </InputContainer>
            <ButtonContainer>
              <CEButton
                type="button"
                onClick={onClose}
                title={strings.button.confirm}
              />
            </ButtonContainer>
          </div>
        )}
      </CEModalBody>
    </CEModal>
  )
}
