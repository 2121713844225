import { useState, type FC, type FormEvent } from "react"

import type { DtoTourRoute } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { useSaveNewTourRoute } from "../../services/useQueries"
import WhiteTourRoute from "../icons/WhiteTourRoute"
import {
  ButtonsContainer,
  LabelWithIconContainer,
} from "../screens/TabGeneral/TabGeneralForm"
import CEIconTooltipCode from "./CEIconTooltipCode"
import CEInput from "./CEInput"
import { CELabel } from "./CELabel"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

const emptyTourRoute: DtoTourRoute = {
  title: {
    fr: "",
  },
}

export const CECreateTourRouteModal: FC<{
  expositionId: string
  isOpen: boolean
  onClose: () => void
  onConfirmSave: (tourRoute: DtoTourRoute) => void
}> = ({ expositionId, isOpen, onClose }) => {
  const s = strings

  const [newTourRoute, setNewTourRoute] = useState<DtoTourRoute>(emptyTourRoute)

  const { mutateAsync: saveTourRoute, isLoading } = useSaveNewTourRoute()

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("title[fr]", newTourRoute.title?.fr || "")
    formData.append("exhibitionId", expositionId)
    await saveTourRoute({
      formData,
    })
    setNewTourRoute(emptyTourRoute)
    onClose()
  }

  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={s.CENewTourRouteModal.headerTitle}
        onRequestClose={onClose}
        Icon={WhiteTourRoute}
      />
      <CEModalBody>
        <form className="expo-modal-body" onSubmit={onSubmit}>
          <div className="input-container">
            <LabelWithIconContainer>
              <CEIconTooltipCode tags={["br"]} id="expoTitleTooltip" />
              <CELabel title={s.CENewTourRouteModal.expositionMoment} />
            </LabelWithIconContainer>
            <CEInput
              placeholder={s.CENewTourRouteModal.expositionMomentPlaceholder}
              value={newTourRoute.title?.fr || ""}
              onChange={(value) =>
                setNewTourRoute((prev) => ({
                  ...prev,
                  title: { fr: value },
                }))
              }
            />
          </div>
          <ButtonsContainer>
            <button
              className={`btn ${isLoading && "btn-disabled"} `}
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
              disabled={isLoading}
            >
              {s.button.cancel}
            </button>
            <button
              className={`btn ${
                (isLoading || !newTourRoute.title?.fr) && "btn-disabled"
              } `}
              type="submit"
              disabled={isLoading || !newTourRoute.title?.fr}
            >
              {s.button.save}
            </button>
          </ButtonsContainer>
        </form>
      </CEModalBody>
    </CEModal>
  )
}
