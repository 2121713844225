/**
 * Theme of the application, accessible with correct type in `styled` and `css` imported from '/styled-components'
 */

export interface Color {
  light: string
  main: string
  dark: string
}

export interface ThemeInterface {
  colors: {
    primary: Color
    secondary: Color
    black: string
    grey: string
    lightGrey: string
    white: string
    error: string
    shadow: string
  }
  fontSize: {
    caption: string
    tooltip: string
    button: string
    buttonChoiceExpoType: string
    main: string
    modalHeader: string
    subtitle: string
    title: string
  }
  borderRadius: {
    modal: string
  }
  height: {
    header: string
  }
  alpha: {
    buttonHover: string
    buttonActive: string
  }
  padding: {
    root: string
  }
  spacing: {
    labelMargin: string
  }
  opacity: {
    buttonDisabled: string
  }
}

const theme: ThemeInterface = {
  // COLORS
  colors: {
    primary: {
      light: "#E6D2D8",
      main: "#9e0231", // from https://www.culturespaces.com/fr/mentions-legales
      dark: "#63001E",
    },
    secondary: {
      light: "#AEBFDC",
      main: "#2D5CAA", // from https://www.culturespaces.com/sites/ceportail/files/dp_institutionnel_ce_2018_fr.pdf
      dark: "#062D6F",
    },
    black: "#000000",
    grey: "#444444",
    lightGrey: "#cccccc",
    white: "#ffffff",
    error: "#FC693E",
    shadow: "#dddddd",
  },

  // FONT SIZE
  fontSize: {
    caption: "12px",
    tooltip: "14px",
    button: "16px",
    buttonChoiceExpoType: "14px",
    main: "16px",
    modalHeader: "24px",
    subtitle: "24px",
    title: "36px",
  },

  // BORDER RADIUS
  borderRadius: {
    modal: "18px",
  },

  // HEIGHT
  height: {
    header: "80px",
  },

  // ALPHA
  alpha: {
    buttonHover: "44",
    buttonActive: "33",
  },

  // PADDING
  padding: {
    root: "16px",
  },

  // SPACING
  spacing: {
    labelMargin: "8px",
  },

  // OPACITY
  opacity: {
    buttonDisabled: "0.6",
  },
}

export default theme
