type ApiUsersMessageError = "INVALID_CREDENTIALS" | "USER_NOT_FOUND"

export type ApiErrorMessage =
  | "FORM_VALIDATION_FAILED"
  | "INVALID_REFRESH_TOKEN"
  | "INVALID_TOKEN"
  | "PAYLOAD_TOO_LARGE"
  | "UNAUTHORIZED_ACTION"
  | "FORBIDDEN"
  | "UNKNOWN_ERROR"
  | ApiUsersMessageError
  | "COUNTRY_NOT_FOUND"
  | "LANGUAGE_ALREADY_EXIST"
  | "LANGUAGE_CAN_NOT_BE_DELETE"
  | "LANGUAGE_NOT_FOUND"
  | "EXPOSITION_NOT_FOUND"
  | "INVALID_FILE"
  | "FILE_CAN_NOT_BE_DELETE"
  | "ARTWORK_NOT_FOUND"
  | "ANECDOTE_NOT_FOUND"
  | "PLAYLIST_NOT_FOUND"
  | "SONG_NOT_FOUND"
  | "ENTITY_NOT_FOUND"
  | "COUNTRY_ALREADY_EXIST"
  | "COUNTRY_CAN_NOT_BE_DELETE"

export class ApiError extends Error {
  constructor(
    message: ApiErrorMessage,
    public data: { status?: number; data?: any } = { status: 400 }
  ) {
    super(message)
  }
}

export type ApiErrorResponse = {
  message: ApiErrorMessage
  status: number
  payload?: any
}
