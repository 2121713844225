import type { FC } from "react"

import type {
  DtoDraftExternalExpositionForm,
  DtoExposition,
  DtoExternalExpositionForm,
} from "../../../@types/dto/DtoObjects"
import {
  useSaveDraftExposition,
  useSavePublishedExposition,
} from "../../../services/useQueries"
import {
  ExternalExpoGeneralForm,
  InternalExpoGeneralForm,
} from "./TabGeneralForm"

export const TabGeneral: FC<{ exposition: DtoExposition }> = ({
  exposition,
}) => {
  const { mutateAsync: saveDraftExpo } = useSaveDraftExposition()

  const { mutateAsync: savePubishedExpo } = useSavePublishedExposition()

  return (
    <div className="form-tab-container">
      {exposition.isExternal ? (
        <ExternalExpoGeneralForm
          exposition={exposition}
          onExpoSave={async (updatedExpo) => {
            if (exposition.published) {
              await savePubishedExpo({
                exposition: updatedExpo as DtoExternalExpositionForm,
                isExternal: exposition.isExternal,
              })
            } else {
              await saveDraftExpo({
                exposition: updatedExpo as DtoDraftExternalExpositionForm,
                isExternal: exposition.isExternal,
              })
            }
          }}
        />
      ) : (
        <InternalExpoGeneralForm
          exposition={exposition}
          onExpoSave={async (updatedExpo) => {
            if (exposition.published) {
              await savePubishedExpo({
                exposition: updatedExpo as DtoExternalExpositionForm,
                isExternal: exposition.isExternal,
              })
            } else {
              await saveDraftExpo({
                exposition: updatedExpo as DtoDraftExternalExpositionForm,
                isExternal: exposition.isExternal,
              })
            }
          }}
        />
      )}
    </div>
  )
}
