import { atom } from "recoil"

interface ErrorState {
  isOpen: boolean
  errors: {
    exposition?: { [key: string]: string[] }
    artworks?: { [key: string]: string[] }
    anecdotes?: { [key: string]: string[] }
    songs?: { [key: string]: string[] }
    playlist?: { [key: string]: string[] }
  }
}

export const errorsState = atom<ErrorState>({
  key: "error_state",
  default: {
    isOpen: false,
    errors: {},
  },
})
