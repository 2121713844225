import type { FC } from "react"
import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"

import strings from "../assets/strings"
import { TabActiveContext } from "../components/contexts/TabActiveContext"
import CENavigationMenu from "../components/layout/CENavigationMenu"
import { Main } from "../components/layout/Main"
import ErrorScreen from "../components/screens/ErrorScreen"
import { TabArtworks } from "../components/screens/TabArtworks/TabArtworks"
import { CETextPoppins } from "../components/ui"
import { useGetExposition } from "../services/useQueries"

export const ArtworksPage: FC = () => {
  const { expoId } = useParams()

  const { tabActive, updateTabActive } = useContext(TabActiveContext)

  const {
    data: exposition,
    isLoading,
    isError: isErrorExposition,
  } = useGetExposition(expoId)

  useEffect(() => {
    if (tabActive !== "artworks") {
      updateTabActive("artworks")
    }
  }, [expoId, exposition, tabActive, updateTabActive])

  if (isLoading) {
    return <CETextPoppins>{strings.general.loading}</CETextPoppins>
  }

  if (isErrorExposition || !exposition || exposition.isExternal) {
    return <ErrorScreen />
  }
  return (
    <Main>
      <div className="row-container">
        <CENavigationMenu />
        <TabArtworks
          isPublishedExpo={exposition.published}
          isLoading={isLoading}
          expositionId={exposition.id}
          artworks={exposition.artworks || []}
          languagesAvailable={exposition.languages}
        />
      </div>
    </Main>
  )
}
