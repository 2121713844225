import type { FC, ReactNode } from "react"
import type { FlattenInterpolation, ThemeProps } from "styled-components"

import styled from "../../styled-components"
import type { ThemeInterface } from "../../styled-components/theme"

export type FontType =
  | "Black"
  | "BlackItalic"
  | "Bold"
  | "BoldItalic"
  | "ExtraBold"
  | "ExtraBoldItalic"
  | "ExtraLight"
  | "ExtraLightItalic"
  | "Italic"
  | "Light"
  | "LightItalic"
  | "Medium"
  | "MediumItalic"
  | "Regular"
  | "SemiBold"
  | "SemiBoldItalic"
  | "Thin"
  | "ThinItalic"

interface CETextPoppinsProps {
  styles?: FlattenInterpolation<ThemeProps<ThemeInterface>> // Style using 'css' from 'styled-components'
  fontType?: FontType
  children?: ReactNode
}

// STYLED COMPONENTS
const Span = styled.span<CETextPoppinsProps>`
  font-size: 16px;
  font-family: ${(props) =>
    props.fontType ? `Poppins-${props.fontType}` : "Poppins-Regular"};
  ${(props) => props.styles || ""}
`

const CETextPoppins: FC<CETextPoppinsProps> = ({
  children,
  styles,
  fontType,
}) => {
  return (
    <Span styles={styles} fontType={fontType}>
      {children}
    </Span>
  )
}

export default CETextPoppins
