import type { ChangeEvent, FC } from "react"

import styled from "../../styled-components"

interface CEInputProps {
  id?: string
  name?: string
  type?: "text" | "email" | "password" | "number"
  placeholder?: string
  value: string
  short?: boolean
  min?: string
  max?: string
  rows?: number
  step?: string
  error?: boolean
  readOnly?: boolean
  isRequired?: boolean
  onChange: (value: string) => void
  onBlur?: (value: string) => void
}

interface InputProps {
  error?: boolean
  short?: boolean
}

const Input = styled.input<InputProps>`
  font-family: Poppins-Regular, serif;
  font-size: ${(props) => props.theme.fontSize.main};
  color: ${(props) => props.theme.colors.grey};
  flex-grow: ${(props) => (props.short ? 0 : 1)};
  padding-top: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid;
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.readOnly
      ? "transparent"
      : props.theme.colors.lightGrey};

  outline: none;
  text-overflow: ellipsis;
`
const InputAreaContainer = styled.div<InputProps>`
  display: flex;
  flex-grow: ${(props) => (props.short ? 0 : 1)};
  padding-right: 0;
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 18px;
  overflow: hidden;
`
const InputArea = styled.textarea<InputProps>`
  font-family: Poppins-Regular, serif;
  font-size: ${(props) => props.theme.fontSize.main};
  color: ${(props) => props.theme.colors.grey};
  flex-grow: ${(props) => (props.short ? 0 : 1)};
  padding: 10px;
  border: none;
  outline: none;
  resize: none;
`

const CEInput: FC<CEInputProps> = ({
  id,
  name,
  type = "text",
  placeholder,
  value,
  short,
  min,
  max,
  step,
  rows,
  error = false,
  readOnly = false,
  onChange,
  onBlur,
  isRequired,
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    onChange(e.target.value)
  }

  const handleBlur = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(e.target.value)
    }
  }

  if (rows) {
    return (
      <InputAreaContainer>
        <InputArea
          rows={rows}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          short={short}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error}
          readOnly={readOnly}
        />
      </InputAreaContainer>
    )
  }
  return (
    <Input
      required={isRequired}
      id={id}
      name={`${name}${isRequired ? "*" : ""}`}
      type={type}
      placeholder={placeholder}
      value={value}
      short={short}
      min={min}
      max={max}
      step={step}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      readOnly={readOnly}
    />
  )
}

export default CEInput
