import toast from "react-hot-toast"

import { ApiError, ApiErrorMessage } from "../@types/dto/DtoObjects.errors"
import strings from "../assets/strings"

type FlashNotificationType = "errors" | "success"
type FlashNotificationAction =
  | "update"
  | "delete"
  | "create"
  | "duplicate"
  | "upload"
  | "updateOrder"

interface FlashNotification {
  type: FlashNotificationType
  entity: keyof typeof strings["CEFlashNotifications"]
  action: FlashNotificationAction
  error?: any
}

const config = {
  duration: 3000,
  styles: {
    success: {
      style: {
        color: "#000",
        padding: "10px",
        backgroundColor: "#E6F4EA",
      },
    },
    error: {
      style: {
        color: "#000",
        padding: "10px",
        backgroundColor: "#E6B8AF",
      },
    },
  },
}

export const useFlashNotification = () => {
  const prefixMessage = strings.CEFlashNotifications

  return ({ type, entity, action, error }: FlashNotification) => {
    if (type === "success") {
      toast.success(`${(prefixMessage as any)[entity][action].success}`, {
        duration: config.duration,
        style: config.styles.success.style,
      })
    } else {
      toast.error(
        getErrorMessageKey(
          (prefixMessage as any)[entity][action].errors,
          error
        ),
        {
          duration: config.duration,
          style: config.styles.error.style,
        }
      )
    }
  }
}

const getErrorMessageKey = (prefix: string, error: any) => {
  let result = "default"

  if (error instanceof ApiError) {
    switch (error.message as ApiErrorMessage) {
      case "COUNTRY_ALREADY_EXIST":
        result = "countryAlreadyExist"
        break
      case "COUNTRY_CAN_NOT_BE_DELETE":
        result = "countryCannotBeDelete"
        break
      case "COUNTRY_NOT_FOUND":
        result = "countryNotFound"
        break
      case "LANGUAGE_ALREADY_EXIST":
        result = "languageAlreadyExist"
        break
      case "LANGUAGE_CAN_NOT_BE_DELETE":
        result = "languageCannotBeDelete"
        break
      case "LANGUAGE_NOT_FOUND":
        result = "languageNotFound"
        break
      case "INVALID_FILE":
        result = "invalidFile"
        break
      case "ARTWORK_NOT_FOUND":
        result = "artworkNotFound"
        break
      case "ANECDOTE_NOT_FOUND":
        result = "anecdoteNotFound"
        break
      case "PLAYLIST_NOT_FOUND":
        result = "playlistNotFound"
        break
      case "SONG_NOT_FOUND":
        result = "songNotFound"
        break
      case "ENTITY_NOT_FOUND":
        result = "entityNotFound"
        break
      case "COUNTRY_ALREADY_EXIST":
        result = "countryAlreadyexist"
        break
      case "COUNTRY_CAN_NOT_BE_DELETE":
        result = "countryCannotBeDelete"
        break
    }
  }

  return prefix[result as any]
}
