import strings from "../assets/strings"
import { Main } from "../components/layout/Main"
import ExpositionSummaries from "../components/screens/ExpositionSummaries"
import { CETextPoppins } from "../components/ui"
import { useGetAllExpositions } from "../services/useQueries"

export const HomePage = () => {
  const { data: expositions, isLoading } = useGetAllExpositions()

  return (
    <Main>
      {!isLoading && expositions ? (
        <ExpositionSummaries expositions={expositions} />
      ) : (
        <CETextPoppins>{strings.general.loading}</CETextPoppins>
      )}
    </Main>
  )
}
