import type { FC } from "react"

import type { DtoCountrySummary } from "../@types/dto/DtoObjects"
import strings from "../assets/strings"
import { Main } from "../components/layout/Main"
import { CountriesScreen } from "../components/screens/Configuration/CountryScreen"
import ErrorScreen from "../components/screens/ErrorScreen"
import { CETextPoppins } from "../components/ui"
import countriesList from "../data/countriesList.json"
import { useGetAllCountries } from "../services/useQueries"

export interface ConfigurationCountry {
  [key: string]: {
    fr: string
  }
}

export const ManageCountries: FC = () => {
  const availableCountries = countriesList as ConfigurationCountry
  const { data, isLoading, isError, isFetching } = useGetAllCountries()

  if (isLoading) {
    return (
      <Main>
        <div className="row-container">
          <CETextPoppins>{strings.general.loading}</CETextPoppins>
        </div>
      </Main>
    )
  }

  if (isError) {
    return (
      <Main>
        <div className="row-container">
          <ErrorScreen />
        </div>
      </Main>
    )
  }

  return (
    <Main>
      <div className="row-container">
        <CountriesScreen
          availableCountries={availableCountries}
          usedCountries={data as DtoCountrySummary[]}
          isLoading={isLoading || isFetching}
        />
      </div>
    </Main>
  )
}
