import { useQueryClient } from "@tanstack/react-query"
import { createContext, ReactNode, useEffect } from "react"
import { useRecoilState } from "recoil"

import type { DtoAuthSuccess } from "../../@types/dto/DtoObjects"
import { isAuthenticated } from "../../store/auth.store"

interface AuthContextType {
  isAuth: boolean
  login: (data: DtoAuthSuccess) => void
  logout: () => void
}

export const AuthContext = createContext<AuthContextType>(null!)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuth, setIsAuth] = useRecoilState(isAuthenticated)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setIsAuth(true)
    }
  }, [setIsAuth])

  const login = (data: DtoAuthSuccess) => {
    sessionStorage.setItem("token", data.token)
    setIsAuth(true)
  }
  const logout = () => {
    sessionStorage.removeItem("token")
    queryClient.removeQueries()
    setIsAuth(false)
  }

  const value: AuthContextType = { isAuth, login, logout }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
