import { useContext, type FC, useEffect } from "react"
import { useParams } from "react-router-dom"

import strings from "../assets/strings"
import { TabActiveContext } from "../components/contexts/TabActiveContext"
import CENavigationMenu from "../components/layout/CENavigationMenu"
import { Main } from "../components/layout/Main"
import ErrorScreen from "../components/screens/ErrorScreen"
import TabTourRoutes from "../components/screens/TabTourRoutes/TabTourRoutes"
import { CETextPoppins } from "../components/ui"
import { useGetExposition, useGetTourRoutes } from "../services/useQueries"

export const TourRoutesPage: FC = () => {
  const { expoId } = useParams()
  const { tabActive, updateTabActive } = useContext(TabActiveContext)
  const {
    data: exposition,
    isLoading,
    isError: isErrorExposition,
  } = useGetExposition(expoId)

  const { data: tourRoutes } = useGetTourRoutes(expoId)

  useEffect(() => {
    if (tabActive !== "tourRoutes") {
      updateTabActive("tourRoutes")
    }
  }, [tabActive, updateTabActive])

  if (isLoading) {
    return <CETextPoppins>{strings.general.loading}</CETextPoppins>
  }

  if (isErrorExposition || !exposition || exposition.isExternal) {
    return <ErrorScreen />
  }

  return (
    <Main>
      <div className="row-container">
        <CENavigationMenu />
        <TabTourRoutes
          isPublishedExpo={exposition.published}
          isLoading={isLoading}
          expositionId={exposition.id}
          languagesAvailable={exposition.languages}
          tourRoutes={tourRoutes || []}
          artworks={exposition.artworks || []}
        />
      </div>
    </Main>
  )
}
