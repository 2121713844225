import { FC, useEffect } from "react"
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"

import { useAuth } from "../../hooks/useAuth"
import { ArtworksPage } from "../../pages/ArtworksPage"
import { ExpositionGeneralPage } from "../../pages/ExpositionGeneralPage"
import { ExpositionLanguagesPage } from "../../pages/ExpositionLanguagesPage"
import { ExpositionStatusPage } from "../../pages/ExpositionStatusPage"
import { HomePage } from "../../pages/HomePage"
import { LoginPage } from "../../pages/LoginPage"
import { ManageCountries } from "../../pages/ManageCountriesPage"
import { ManageLanguages } from "../../pages/ManageLanguagesPage"
import { ManageVersion } from "../../pages/ManageVersionPage"
import { MediasPage } from "../../pages/MediasPage"
import { PlaylistsPage } from "../../pages/PlaylistsPage"
import { TourRoutesPage } from "../../pages/TourRoutesPage"

export const Router: FC = () => {
  const { isAuth } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      navigate("/login", { replace: true })
    }
  }, [isAuth, navigate])

  return (
    <Routes>
      {!isAuth ? (
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </>
      ) : (
        <>
          <Route path="/" element={<HomePage />} />

          <Route
            path="/exposition/:expoId/langues"
            element={<ExpositionLanguagesPage />}
          />
          <Route
            path="/exposition/:expoId/general"
            element={<ExpositionGeneralPage />}
          />
          <Route path="/exposition/:expoId/medias" element={<MediasPage />} />
          <Route
            path="/exposition/:expoId/artworks"
            element={<ArtworksPage />}
          />
          <Route
            path="/exposition/:expoId/playlists"
            element={<PlaylistsPage />}
          />
          <Route
            path="/exposition/:expoId/tourRoutes"
            element={<TourRoutesPage />}
          />
          <Route
            path="/exposition/:expoId/status"
            element={<ExpositionStatusPage />}
          />

          <Route path="/gestion/pays" element={<ManageCountries />} />
          <Route path="/gestion/langue" element={<ManageLanguages />} />
          <Route path="/gestion/version" element={<ManageVersion />} />

          <Route
            path="/exposition/:expoId"
            element={<Navigate replace to="/exposition/:expoId/general" />}
          />

          <Route path="/expositions" element={<Navigate replace to="/" />} />
        </>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
