import { ChangeEvent, FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import type { DtoExpositionSummary } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { normalizeText } from "../../functions"
import styled, { css } from "../../styled-components"
import {
  CEAddButton,
  CEExpoListItem,
  CEExpoNewModal,
  CEExpoTypeButton,
  CEInput,
  CELabel,
  CETextPoppins,
} from "../ui"

// TYPES

// STYLED COMPONENTS
const Root = styled.div`
  position: relative;
  padding: ${(props) => props.theme.padding.root};
`

const AddButtonContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.padding.root};
  right: ${(props) => props.theme.padding.root};
  z-index: 1;
`

interface SearchContainerProps {
  center?: boolean
}
const SearchContainer = styled.div<SearchContainerProps>`
  display: flex;
  margin-bottom: 16px;
  ${({ center }) => (center ? "align-items: center;" : "")}
`

const SearchInputContainer = styled.div`
  flex-shrink: 1;
`

const Checkbox = styled.input`
  margin-right: ${({ theme }) => theme.spacing.labelMargin};
`

const ListContainer = styled.div``

const noResultStyles = css`
  font-size: 24px;
`

const ExpoListItemContainer = styled.div`
  max-width: 1400px;
  margin-bottom: 8px;
`

const ExpoTypeContainer = styled.div`
  margin-bottom: 10px;
`

type ExpoType = "ALL" | "INTERNAL" | "EXTERNAL"

const ExpositionSummaries: FC<{ expositions: DtoExpositionSummary[] }> = ({
  expositions,
}) => {
  const s = strings.Home
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState<string>("")
  const [displayArchivedExpositions, setDisplayArchivedExpositions] =
    useState<boolean>(false)
  const [isCreateExpoModalOpen, setIsCreateExpoModalOpen] =
    useState<boolean>(false)

  const [displayExpoType, setDisplayExpoType] = useState<ExpoType>("ALL")

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const handleDisplayArchivedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayArchivedExpositions(e.target.checked)
  }

  const handleExpositionClick = (expoId: string | undefined) => {
    if (expoId) {
      navigate(`/exposition/${expoId}/general`)
    }
  }

  const filteredExpositions = useMemo(() => {
    let filteredExpositions = expositions.filter((exposition) => {
      return displayArchivedExpositions || !exposition.isArchived
    })
    if (displayExpoType === "INTERNAL") {
      filteredExpositions = filteredExpositions.filter(
        (exposition) => !exposition.isExternal
      )
    } else if (displayExpoType === "EXTERNAL") {
      filteredExpositions = filteredExpositions.filter(
        (exposition) => exposition.isExternal
      )
    }
    if (searchValue === "") {
      return filteredExpositions
    }

    const normalizedKeyword = normalizeText(searchValue)
    filteredExpositions = filteredExpositions.filter((exposition) => {
      if (!displayArchivedExpositions && exposition.isArchived) {
        return false
      }
      const frTitleMatch =
        exposition.title?.fr &&
        normalizeText(exposition.title.fr).includes(normalizedKeyword)
      const enTitleMatch =
        exposition.title?.en &&
        normalizeText(exposition.title.en).includes(normalizedKeyword)
      const locationMatch =
        exposition.address?.location &&
        normalizeText(exposition.address?.location).includes(normalizedKeyword)
      return frTitleMatch || enTitleMatch || locationMatch
    })

    return filteredExpositions
  }, [displayArchivedExpositions, displayExpoType, expositions, searchValue])

  const openExpoNewModal = () => setIsCreateExpoModalOpen(true)
  const closeExpoNewModal = () => setIsCreateExpoModalOpen(false)

  const sortedExpositions = filteredExpositions.sort((a, b) => {
    if (a.isArchived && !b.isArchived) {
      return -1
    }
    if (b.isArchived && !a.isArchived) {
      return -1
    }
    return 0
  })

  return (
    <Root>
      <AddButtonContainer>
        <CEAddButton onClick={openExpoNewModal} />
      </AddButtonContainer>

      <SearchContainer>
        <CELabel title={s.searchLabel} htmlFor="search" />
        <SearchInputContainer>
          <CEInput
            id="search"
            placeholder={s.searchPlaceholder}
            value={searchValue}
            onChange={handleChange}
          />
        </SearchInputContainer>
      </SearchContainer>

      <SearchContainer center>
        <Checkbox
          type="checkbox"
          id="displayArchived"
          onChange={handleDisplayArchivedChange}
        />
        <CELabel
          title={"Afficher les expositions archivées"}
          htmlFor="displayArchived"
        />
      </SearchContainer>

      <ExpoTypeContainer>
        <CEExpoTypeButton
          title={s.expoChoices.all}
          onClick={() => setDisplayExpoType("ALL")}
          selected={displayExpoType === "ALL"}
        />
        <CEExpoTypeButton
          title={s.expoChoices.internal}
          onClick={() => setDisplayExpoType("INTERNAL")}
          selected={displayExpoType === "INTERNAL"}
        />
        <CEExpoTypeButton
          title={s.expoChoices.external}
          onClick={() => setDisplayExpoType("EXTERNAL")}
          selected={displayExpoType === "EXTERNAL"}
        />
      </ExpoTypeContainer>

      <ListContainer>
        {sortedExpositions.map((exposition) => {
          return (
            <ExpoListItemContainer key={exposition.id || Math.random()}>
              <CEExpoListItem
                exposition={exposition}
                onClick={(id) => handleExpositionClick(id)}
              />
            </ExpoListItemContainer>
          )
        })}
        {sortedExpositions.length === 0 && (
          <CETextPoppins styles={noResultStyles}>{s.noResult}</CETextPoppins>
        )}
      </ListContainer>

      <CEExpoNewModal
        isOpen={isCreateExpoModalOpen}
        onRequestClose={closeExpoNewModal}
      />
    </Root>
  )
}

export default ExpositionSummaries
