/**
 * Export `styled` and `css` with correct types (imports from 'styled-components' are not typed)
 */

import type { ThemedStyledComponentsModule } from "styled-components"
import * as styledComponents from "styled-components"

import type { ThemeInterface } from "./theme"

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>

export { css, createGlobalStyle, keyframes, ThemeProvider }
export default styled
