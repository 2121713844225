import type { FC } from "react"
import styled from "styled-components"

type AlertType = "error" | "success" | "info"

interface CEAlertProps {
  type: AlertType
  message: string
}

export const CEAlert: FC<CEAlertProps> = ({ type, message }) => {
  if (type === "error") {
    return <Error>{message}</Error>
  }
  if (type === "info") {
    return <Info>{message}</Info>
  }
  return <Success>{message}</Success>
}

const Error = styled.p`
  background-color: #f8d7d9;
  color: #842029;
  padding: 10px 20px;
  border-radius: 10px;
`

const Success = styled.p`
  background-color: #d1e7dd;
  color: #0f5132;
  padding: 10px 20px;
  border-radius: 10px;
`

const Info = styled.p`
  background-color: #fff3cd;
  color: #664d03;
  padding: 10px 20px;
  border-radius: 10px;
`
