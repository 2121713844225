import { FC, useEffect, useState } from "react"

import type { DtoSong, DtoLanguage } from "../../@types/dto/DtoObjects"
import strings from "../../assets/strings"
import { useSaveSong } from "../../services/useQueries"
import styled from "../../styled-components"
import { Edit3 } from "../icons"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
  underlinedLabelStyles,
} from "../screens/TabGeneral/TabGeneralForm"
import CEButton from "./CEButton"
import CEInput from "./CEInput"
import { CELabel } from "./CELabel"
import CEModal from "./CEModal"
import CEModalBody from "./CEModalBody"
import CEModalHeader from "./CEModalHeader"

const emptySongForm: DtoSong = {
  id: undefined,
  trackArtist: undefined,
  trackUrl: undefined,
  trackTitle: undefined,
}
interface CESongModalProps {
  song?: DtoSong
  isOpen: boolean
  expositionId: string
  playlistId: string
  onRequestClose: () => void
  onConfirm: (song: DtoSong) => void
  languagesAvailable: DtoLanguage[]
}

export const CESongModal: FC<CESongModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  playlistId,
  languagesAvailable,
  expositionId,
  song,
}) => {
  // STRINGS
  const s = strings.CESongModal

  const [songFormState, setSongFormState] = useState<DtoSong>(emptySongForm)

  const { mutateAsync: saveSong } = useSaveSong()

  useEffect(() => {
    if (song) {
      setSongFormState(song)
    }
    return () => setSongFormState(emptySongForm)
  }, [song])

  const handleConfirm = async () => {
    const data = await saveSong({
      song: songFormState,
      playlistId,
      expositionId,
    })
    onConfirm(data)
    setSongFormState(emptySongForm)
  }
  const onClose = () => {
    setSongFormState(emptySongForm)
    onRequestClose()
  }
  // RENDER
  return (
    <CEModal isOpen={isOpen}>
      <CEModalHeader
        title={song?.id ? s.headerTitleUpdate : s.headerTitleCreate}
        onRequestClose={onClose}
        Icon={Edit3}
      />
      <CEModalBody>
        <Body>
          <InputsContainer>
            <InputContainer>
              <CELabel
                title={s.trackTitleLabel}
                styles={underlinedLabelStyles}
              />
            </InputContainer>
            {languagesAvailable.map((language) => (
              <InputContainer key={language.code} indent={1}>
                <CELabel title={language.label} />
                <CEInput
                  placeholder={s.trackTitleFrPlaceholder}
                  value={songFormState.trackTitle?.[language.code] || ""}
                  onChange={(value) =>
                    setSongFormState((prev) => ({
                      ...prev,
                      trackTitle: {
                        ...prev.trackTitle,
                        [language.code]: value !== "" ? value : undefined,
                      },
                    }))
                  }
                />
              </InputContainer>
            ))}

            <InputContainer>
              <CELabel
                title={s.trackArtistLabel}
                styles={underlinedLabelStyles}
              />
              <CEInput
                placeholder={s.trackArtistPlaceholder}
                value={songFormState.trackArtist || ""}
                onChange={(value) =>
                  setSongFormState((prev) => ({
                    ...prev,
                    trackArtist: value !== "" ? value : undefined,
                  }))
                }
              />
            </InputContainer>

            <InputContainer>
              <CELabel title={s.trackUrlLabel} styles={underlinedLabelStyles} />
              <CEInput
                placeholder={s.trackUrlPlaceholder}
                value={songFormState.trackUrl || ""}
                onChange={(value) =>
                  setSongFormState((prev) => ({
                    ...prev,
                    trackUrl: value !== "" ? value : undefined,
                  }))
                }
              />
            </InputContainer>
          </InputsContainer>
          <ButtonsContainer>
            <ButtonContainer>
              <CEButton
                title={strings.button.cancel}
                onClick={onRequestClose}
              />
            </ButtonContainer>
            <ButtonContainer>
              <CEButton title={strings.button.save} onClick={handleConfirm} />
            </ButtonContainer>
          </ButtonsContainer>
        </Body>
      </CEModalBody>
    </CEModal>
  )
}

const Body = styled.div`
  min-width: 600px;
`

const InputsContainer = styled.div`
  margin-bottom: 14px;
`
