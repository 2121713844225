import { useState, type FC } from "react"
import styled from "styled-components"

import type {
  DtoArtwork,
  DtoLanguage,
  DtoTourRoute,
} from "../../../@types/dto/DtoObjects"
import defaultImageUrl from "../../../assets/img/culturespaces-squarelogo.png"
import strings from "../../../assets/strings"
import {
  useDeleteArtworkTourRoute,
  useDeleteTourRoute,
  useUpdateTourRoute,
} from "../../../services/useQueries"
import theme from "../../../styled-components/theme"
import {
  CEDropzone,
  CEHtmlInput,
  CEIconTooltipCode,
  CEInput,
  CELabel,
  CETextPoppins,
} from "../../ui"
import { CEArtworksForTourRouteModal } from "../../ui/CEArtworksForTourRouteModal"
import { Image } from "../../ui/CECarouselItem"
import { CEConfirmModal } from "../../ui/CEConfirmModal"
import {
  ContainerHalfWidth,
  ThumbnailImageDropzoneContainer,
  UploadSection,
  UploadSpecificationsContainer,
  UploadTitleContainer,
  uploadSpecificationStyles,
} from "../TabArtworks/TabArtworksForm"
import {
  ButtonContainer,
  ButtonsContainer,
  InputContainer,
  InputSectionContainer,
  LabelWithIconContainer,
  underlinedLabelStyles,
} from "../TabGeneral/TabGeneralForm"

const initialFormState: DtoTourRoute = {
  _id: undefined,
  title: undefined,
}

export const TabTourRouteForm: FC<{
  isPublishedExposition: boolean
  tourRoute?: DtoTourRoute
  artworks: DtoArtwork[]
  expositionId: string
  onCancel: () => void
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
}> = ({
  tourRoute,
  artworks,
  expositionId,
  languagesAvailable,
  isLoading,
  onCancel,
}) => {
  const s = strings.TabTourRoutesForm

  const [tourRouteFormState, setTourRouteFormState] = useState<DtoTourRoute>(
    tourRoute?._id
      ? {
          _id: tourRoute._id,
          title: tourRoute.title,
          artworks: [],
          courseThumbnail: tourRoute.courseThumbnail,
          description: tourRoute.description,
        }
      : initialFormState
  )

  const [tourRouteFile, setTourRouteFile] = useState<File>()
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isOpenArtworksForTourRouteModal, setIsOpenArtworksForTourRouteModal] =
    useState(false)

  const { mutateAsync: updateTourRoute, isLoading: isUpdateOnProgress } =
    useUpdateTourRoute()
  const { mutateAsync: deleteTourRoute } = useDeleteTourRoute()
  const { mutateAsync: deleteArtwork } = useDeleteArtworkTourRoute()

  const stateToFormData = () => {
    const formData = new FormData()
    formData.append("exhibitionId", expositionId)
    languagesAvailable.map((language) => {
      formData.append(
        `title[${language.code}]`,
        tourRouteFormState.title?.[language.code] || ""
      )
      formData.append(
        `description[${language.code}]`,
        tourRouteFormState.description?.[language.code] || ""
      )
    })
    formData.append("courseThumbnail", tourRouteFormState.courseThumbnail || "")
    if (tourRouteFile) {
      formData.append("file", tourRouteFile)
    }
    return formData
  }

  const onSubmit = async () => {
    if (tourRoute?._id && isOpenArtworksForTourRouteModal === false) {
      if (tourRouteFormState.artworks?.length) {
        tourRouteFormState.artworks?.map(async (artwork) => {
          const formData = stateToFormData()
          if (artwork?.id) {
            formData.append("artworkId", artwork?.id)
            await updateTourRoute({
              formData: formData,
              tourRouteId: tourRoute._id!,
            })
            setTourRouteFormState({ ...tourRouteFormState, artworks: [] })
          }
        })
      } else {
        await updateTourRoute({
          formData: stateToFormData(),
          tourRouteId: tourRoute._id,
        })
      }
    }
  }

  return (
    <>
      <Root>
        <ContainerHalfWidth>
          <form onSubmit={onSubmit}>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.expoMomentLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} indent={1}>
                  <CELabel title={language.label} />
                  <CEInput
                    placeholder={s.expoMomentFrPlaceholder}
                    value={tourRouteFormState.title?.[language.code] || ""}
                    onChange={(value) =>
                      setTourRouteFormState((prev) => ({
                        ...prev,
                        title: { ...prev.title, [language.code]: value },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.artworksLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              <InputContainer>
                <ArtworkList>
                  {tourRouteFormState.artworks?.map((artwork) => (
                    <ArtworkContainer key={artwork.id}>
                      <Image src={artwork.artworkUrl} />
                      <ArtworkName>{artwork.artworkName?.fr}</ArtworkName>
                      <ArtistName>{artwork.artistName}</ArtistName>
                      {initialFormState.artworks?.find(
                        (item) => item.id === artwork.id
                      ) && (
                        <ButtonContainer>
                          <button
                            className="btn btn-delete"
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                          >
                            {s.deleteArtwork}
                          </button>
                        </ButtonContainer>
                      )}
                    </ArtworkContainer>
                  ))}
                  {tourRoute?.artworks?.map((artwork) => (
                    <ArtworkContainer key={artwork.id}>
                      <Image src={artwork.artworkUrl} />
                      <ArtworkName>{artwork.artworkName?.fr}</ArtworkName>
                      <ArtistName>{artwork.artistName}</ArtistName>
                      <ButtonContainer>
                        <button
                          className="btn btn-delete"
                          onClick={(e) => {
                            e.preventDefault()
                            deleteArtwork({
                              tourRouteId: tourRoute._id ?? "",
                              artworkId: artwork._id ?? "",
                            })
                          }}
                        >
                          {s.deleteArtwork}
                        </button>
                      </ButtonContainer>
                    </ArtworkContainer>
                  ))}
                </ArtworkList>
              </InputContainer>
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault(), setIsOpenArtworksForTourRouteModal(true)
                }}
              >
                {s.addArtwork}
              </button>
            </InputSectionContainer>
            <InputSectionContainer>
              <InputContainer>
                <CELabel
                  title={s.factualExplanationLabel}
                  styles={underlinedLabelStyles}
                />
              </InputContainer>
              {languagesAvailable.map((language) => (
                <InputContainer key={language.code} column indent={1}>
                  <FactualExplanationLabelContainer>
                    <LabelWithIconContainer>
                      <CEIconTooltipCode
                        id={language.code}
                        tags={["br", "b", "i"]}
                        top
                      />
                      <CELabel title={language.label} />
                    </LabelWithIconContainer>
                  </FactualExplanationLabelContainer>
                  <CEHtmlInput
                    placeholder={s.copyrightFrPlaceholder}
                    value={
                      tourRouteFormState.description?.[language.code] || ""
                    }
                    onChange={(value) =>
                      setTourRouteFormState((prev) => ({
                        ...prev,
                        description: {
                          ...prev.description,
                          [language.code]: value,
                        },
                      }))
                    }
                  />
                </InputContainer>
              ))}
            </InputSectionContainer>
            <ButtonsContainer>
              <ButtonContainer>
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault()
                    onCancel()
                  }}
                >
                  {strings.button.cancel}
                </button>
              </ButtonContainer>
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  type="submit"
                  disabled={isLoading || isUpdateOnProgress}
                  onClick={(e) => {
                    e.preventDefault()
                    onSubmit()
                  }}
                >
                  {strings.button.save}
                </button>
              </ButtonContainer>
              <ButtonContainer>
                <button
                  className={`btn ${
                    (isUpdateOnProgress || isLoading) && "btn-disabled"
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsConfirmDeleteOpen(true)
                  }}
                  disabled={false}
                >
                  {strings.button.delete}
                </button>
              </ButtonContainer>
            </ButtonsContainer>
          </form>
        </ContainerHalfWidth>
        <ContainerHalfWidth>
          <UploadSection>
            <UploadTitleContainer>
              <CETextPoppins>{s.tourRouteImageTitle}</CETextPoppins>
            </UploadTitleContainer>

            <UploadSpecificationsContainer>
              <CETextPoppins styles={uploadSpecificationStyles}>
                {s.tourRouteImageSpecifications}
              </CETextPoppins>
            </UploadSpecificationsContainer>

            <ThumbnailImageDropzoneContainer>
              <CEDropzone
                type="image"
                entity={{
                  type: "tourRoutes",
                  field: "tourRoute",
                  id: tourRouteFormState._id!,
                }}
                isEntityLoading={isLoading}
                defaultFileUrl={tourRoute?.courseThumbnail || defaultImageUrl}
                setTourRouteFile={(value) => setTourRouteFile(value)}
              />
            </ThumbnailImageDropzoneContainer>
          </UploadSection>
        </ContainerHalfWidth>
      </Root>
      <CEArtworksForTourRouteModal
        isOpen={isOpenArtworksForTourRouteModal}
        artworks={artworks.filter(
          (item) =>
            tourRoute &&
            !tourRoute.artworks?.some(
              (excludeItem) => excludeItem._id === item.id
            )
        )}
        onClose={() => setIsOpenArtworksForTourRouteModal(false)}
        onClick={(value: DtoArtwork) =>
          setTourRouteFormState((prev) => ({
            ...prev,
            artworks: tourRouteFormState.artworks
              ? [...tourRouteFormState.artworks, value]
              : [value],
          }))
        }
      />
      {isConfirmDeleteOpen && tourRouteFormState._id && (
        <CEConfirmModal
          isOpen={isConfirmDeleteOpen}
          onCancel={() => {
            setIsConfirmDeleteOpen(false)
          }}
          onConfirm={async () => {
            setIsConfirmDeleteOpen(false)
            await deleteTourRoute({
              tourRouteId: tourRouteFormState._id!,
            })
          }}
          title={strings.CEConfirmModal.deleteTourRouteTitle}
          message={strings.CEConfirmModal.deleteTourRoute}
        />
      )}
    </>
  )
}

const Root = styled.div`
  display: flex;
  position: relative;
`

const FactualExplanationLabelContainer = styled.div`
  margin-bottom: 8px;
`
const ArtworkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`

const ArtworkContainer = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`
const ArtworkName = styled.div`
  width: 140px;
  font-size: 12px;
`

const ArtistName = styled.div`
  width: 140px;
  font-size: 12px;
  color: ${theme.colors.grey};
`
