import { FC, useContext } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"

import type { NavigationMenuTab } from "../../@types/interface"
import strings from "../../assets/strings"
import { getDateRangeString } from "../../functions"
import {
  useDuplicateExposition,
  useGetExposition,
} from "../../services/useQueries"
import styled, { css } from "../../styled-components"
import theme from "../../styled-components/theme"
import { TabActiveContext } from "../contexts/TabActiveContext"
import { ArrowLeft, Home, Camera, Music, Activity, Picture } from "../icons"
import Flag from "../icons/Flag"
import TourRoute from "../icons/TourRoute"
import { CENavigationMenuButton, CETextPoppins } from "../ui"

// DATA
const NAVIGATION_MENU_WIDTH = 250
const NAVIGATION_MENU_PADDING = 8
const NAVIGATION_RIGHT_BORDER_WIDTH = 2
export const NAVIGATION_MENU_FULL_WIDTH =
  NAVIGATION_MENU_WIDTH +
  NAVIGATION_MENU_PADDING * 2 +
  NAVIGATION_RIGHT_BORDER_WIDTH

// STYLED COMPONENTS
// ?: Stange that padding must be integrated in the height calculus
const rootPadding = "8px"
const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: ${NAVIGATION_MENU_WIDTH}px;
  min-height: calc(
    100vh - ${(props) => props.theme.height.header} - 2 * ${rootPadding}
  );
  background-color: ${(props) => props.theme.colors.white};
  border: 0px solid ${(props) => props.theme.colors.primary.main};
  border-right-width: ${NAVIGATION_RIGHT_BORDER_WIDTH}px;
  padding: ${NAVIGATION_MENU_PADDING}px;
  box-shadow: 4px 0 4px ${(props) => props.theme.colors.shadow};
`

const BackContainer = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 8px;
  outline: none;
  background: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`

const BackArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const backTextStyles = css`
  font-size: 16px;
`

const ExpositionSymmaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const expositionTitleStyles = css`
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const expositionLocationStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const expositionDateRangeStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NavigationMenuButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`

const NavigationMenuButtonContainer = styled.div`
  margin-bottom: 24px;
`

/**
 * Navigation menu to switch between different tabs when consulting / editing an exposition (general, medias, artworks, playlists, status)
 */
const CENavigationMenu: FC = () => {
  // STRINGS
  const s = strings.CENavigationMenu
  const navigate = useNavigate()

  const { expoId } = useParams()
  const { data: exposition } = useGetExposition(expoId)

  // CONTEXT
  const { tabActive, updateTabActive } = useContext(TabActiveContext)

  /**
   * Go back to home and reset current exposition
   */
  const handleBackToHome = () => {
    //resetExposition()
    navigate("/")
  }

  /**
   * Navigates to the selected navigation tab
   * @param tab {NavigationMenuTab} navigation tab ('general', 'medias', 'artworks', 'playlists', or 'status')
   */
  const handleNavigation = (tab: NavigationMenuTab) => {
    if (exposition?.id) {
      navigate(`/exposition/${exposition.id}/${tab}`)
      updateTabActive(tab)
    }
  }

  const { mutateAsync: duplicateExpo, isLoading } = useDuplicateExposition()

  const handleDuplicate = async () => {
    if (!exposition) {
      return
    }
    await duplicateExpo(exposition.id)
  }

  // RENDER
  return (
    <Root>
      {/* BACK TO HOME */}
      <BackContainer onClick={handleBackToHome}>
        <BackArrowContainer>
          <ArrowLeft />
        </BackArrowContainer>
        <CETextPoppins styles={backTextStyles}>{s.home}</CETextPoppins>
      </BackContainer>

      {/* EXPOSITION SUMMARY */}
      <ExpositionSymmaryContainer>
        {/* TITLE */}
        <CETextPoppins styles={expositionTitleStyles} fontType={"SemiBold"}>
          {exposition?.title?.fr || strings.placeholder.noTitle}
        </CETextPoppins>

        {/* LOCATION */}
        <CETextPoppins styles={expositionLocationStyles}>
          {exposition?.address?.location || strings.placeholder.noLocation}
        </CETextPoppins>

        {/* DATE RANGE */}
        <CETextPoppins styles={expositionDateRangeStyles}>
          {getDateRangeString(exposition?.startDate, exposition?.endDate) ||
            strings.placeholder.noDate}
        </CETextPoppins>
      </ExpositionSymmaryContainer>
      <DuplicateCOntainer>
        <DuplicateButton onClick={handleDuplicate} disabled={isLoading}>
          {strings.button.duplicate}
        </DuplicateButton>
      </DuplicateCOntainer>

      {/* NAVIGATION MENU BUTTONS */}
      <NavigationMenuButtonsContainer>
        {/* GENERAL */}
        <NavigationMenuButtonContainer>
          <CENavigationMenuButton
            title={s.tabGeneral}
            Icon={Home}
            onClick={() => handleNavigation("general")}
            selected={tabActive === "general"}
          />
        </NavigationMenuButtonContainer>
        {/* LANGUES */}
        <NavigationMenuButtonContainer>
          <CENavigationMenuButton
            title={s.tabLanguages}
            Icon={Flag}
            onClick={() => handleNavigation("langues")}
            selected={tabActive === "langues"}
          />
        </NavigationMenuButtonContainer>

        {/* MEDIAS */}
        <NavigationMenuButtonContainer>
          <CENavigationMenuButton
            title={s.tabMedias}
            Icon={Camera}
            onClick={() => handleNavigation("medias")}
            selected={tabActive === "medias"}
          />
        </NavigationMenuButtonContainer>

        {/* ARTWORKS */}
        {!exposition?.isExternal && (
          <NavigationMenuButtonContainer>
            <CENavigationMenuButton
              title={s.tabArtworks}
              Icon={Picture}
              onClick={() => handleNavigation("artworks")}
              selected={tabActive === "artworks"}
            />
          </NavigationMenuButtonContainer>
        )}

        {/* PLAYLISTS */}
        {!exposition?.isExternal && (
          <NavigationMenuButtonContainer>
            <CENavigationMenuButton
              title={s.tabPlaylists}
              Icon={Music}
              onClick={() => handleNavigation("playlists")}
              selected={tabActive === "playlists"}
            />
          </NavigationMenuButtonContainer>
        )}

        {/* TOUR ROUTES */}
        {!exposition?.isExternal && (
          <NavigationMenuButtonContainer>
            <CENavigationMenuButton
              title={s.tabTourRoutes}
              Icon={TourRoute}
              onClick={() => handleNavigation("tourRoutes")}
              selected={tabActive === "tourRoutes"}
            />
          </NavigationMenuButtonContainer>
        )}

        {/* STATUS */}
        <NavigationMenuButtonContainer>
          <CENavigationMenuButton
            title={s.tabStatus}
            Icon={Activity}
            onClick={() => handleNavigation("status")}
            selected={tabActive === "status"}
          />
        </NavigationMenuButtonContainer>
      </NavigationMenuButtonsContainer>
    </Root>
  )
}

const DuplicateCOntainer = styled.div`
  padding: 10px;
  margin: 0 auto;
`

const DuplicateButton = styled.button`
  border: none;
  background: none;
  color: ${theme.colors.primary.main};
  cursor: pointer;
  font-family: Poppins-Regular, serif;
  font-size: 14px;
  text-transform: uppercase;
`

export default CENavigationMenu
