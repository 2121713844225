import dompurify from "dompurify"
import type { FC } from "react"

import styled from "../../styled-components"
import CEInput from "./CEInput"

// TYPES
interface CEHtmlInputProps {
  id?: string
  placeholder: string
  value: string
  onChange: (validation: string) => void
  readOnly?: boolean
}

// STYLED COMPONENTS
const Root = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const InputContainer = styled.div`
  display: flex;
  width: 49%;
`

const PreviewContainer = styled.div`
  display: flex;
  width: 49%;
`

const PreviewTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 18px;
`

/**
 * Text input that allows <br>, <i>, and <b>
 * Display a html preview
 */
const CEHtmlInput: FC<CEHtmlInputProps> = ({
  id,
  placeholder,
  value,
  onChange,
  readOnly = false,
}) => {
  // RENDER
  return (
    <Root>
      <InputContainer>
        <CEInput
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          rows={10}
        />
      </InputContainer>
      <PreviewContainer>
        <PreviewTextContainer>
          <div
            dangerouslySetInnerHTML={{ __html: dompurify.sanitize(value) }}
          />
        </PreviewTextContainer>
      </PreviewContainer>
    </Root>
  )
}

export default CEHtmlInput
