import type { FC } from "react"

import strings from "../assets/strings"
import { Main } from "../components/layout/Main"
import { VersionScreen } from "../components/screens/Configuration/VersionScreen"
import ErrorScreen from "../components/screens/ErrorScreen"
import { CETextPoppins } from "../components/ui"
import { useGetCurrentVersion } from "../services/useQueries"

export const ManageVersion: FC = () => {
  const { data, isLoading, isError } = useGetCurrentVersion()
  if (isLoading) {
    return (
      <Main>
        <div className="row-container">
          <CETextPoppins>{strings.general.loading}</CETextPoppins>
        </div>
      </Main>
    )
  }

  if (isError) {
    return (
      <Main>
        <div className="row-container">
          <ErrorScreen />
        </div>
      </Main>
    )
  }

  return (
    <Main>
      <div className="row-container">
        <VersionScreen currentVersion={data} />
      </div>
    </Main>
  )
}
