import type { DropResult } from "@hello-pangea/dnd"
import { useState, type FC, useMemo, useEffect } from "react"
import styled from "styled-components"

import type {
  DtoArtwork,
  DtoLanguage,
  DtoTourRoute,
} from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import { useSaveTourRoutesOrder } from "../../../services/useQueries"
import { NAVIGATION_MENU_FULL_WIDTH } from "../../layout/CENavigationMenu"
import { CEAddButton, CEButton, CECarousel, CETextPoppins } from "../../ui"
import type { CarouselItem } from "../../ui/CECarousel"
import { CECreateTourRouteModal } from "../../ui/CECreateTourRouteModal"
import { CEPublishedExpoModal } from "../../ui/CEPublishedExpoModal"
import {
  AddButtonContainer,
  CarouselContainer,
  TAB_ARTWORK_PADDING,
  TabTitleContainer,
  tabTitleStyles,
} from "../TabArtworks/TabArtworks"
import { ButtonContainer, ButtonsContainer } from "../TabGeneral/TabGeneralForm"
import { TabTourRouteForm } from "./TabTourRoutesForm"

const TabTourRoutes: FC<{
  expositionId: string
  isPublishedExpo: boolean
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
  tourRoutes: DtoTourRoute[]
  artworks: DtoArtwork[]
}> = ({
  expositionId,
  languagesAvailable,
  isLoading,
  isPublishedExpo,
  tourRoutes,
  artworks,
}) => {
  const s = strings.TabTourRoutesList

  const [isOpenNewTourRouteModal, setIsOpenNewTourRouteModal] = useState(false)
  const [tourRoutesOrdered, setTourRoutesOrdered] =
    useState<DtoTourRoute[]>(tourRoutes)
  const [tourRouteSelected, setTourRouteSelected] = useState<
    string | undefined
  >(undefined)

  const currentTourRoute = useMemo(
    () => tourRoutesOrdered.find((a) => a._id === tourRouteSelected),
    [tourRoutesOrdered, tourRouteSelected]
  )

  const { dispatch, isOrderChanged } = useOrderChanged()
  const { mutateAsync: updateOrder } = useSaveTourRoutesOrder()

  useEffect(() => {
    setTourRoutesOrdered(tourRoutes)
  }, [tourRoutes])

  useEffect(() => {
    for (let i = 0; i < tourRoutes.length; i++) {
      if (
        tourRoutesOrdered.length &&
        tourRoutesOrdered[i]._id !== tourRoutes[i]._id
      ) {
        dispatch("ORDER_CHANGED")
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tourRoutesOrdered])

  const tourRoutesToCarouselItems: CarouselItem[] = useMemo(
    () =>
      tourRoutesOrdered.map((tourRoute) => ({
        id: tourRoute._id,
        imageUrl: tourRoute.courseThumbnail,
        alt: tourRoute.title?.fr,
      })),
    [tourRoutesOrdered]
  )

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (destinationIndex !== undefined) {
      setTourRoutesOrdered((prev) => {
        const movedTourRoute = prev.find(
          (_item, index) => index === sourceIndex
        )
        let newCarouselPlaylists = prev.filter(
          (_item, index) => index !== sourceIndex
        )

        if (movedTourRoute) {
          newCarouselPlaylists.splice(destinationIndex, 0, movedTourRoute)
          return newCarouselPlaylists
        }
        return prev
      })
    }
  }

  return (
    <>
      <Root>
        <AddButtonContainer>
          <CEAddButton onClick={() => setIsOpenNewTourRouteModal(true)} />
        </AddButtonContainer>
        <TabTitleContainer>
          <CETextPoppins styles={tabTitleStyles}>{s.tabTitle}</CETextPoppins>
        </TabTitleContainer>
        <CarouselContainer>
          <CECarousel
            items={tourRoutesToCarouselItems}
            activeId={tourRouteSelected}
            droppableId="tourRoutesDroppable"
            onDragEnd={onDragEnd}
            onClick={setTourRouteSelected}
          />
        </CarouselContainer>
        {isOrderChanged && (
          <ButtonsContainer>
            <ButtonContainer>
              <CEButton
                title={strings.button.cancel}
                onClick={() => {
                  setTourRoutesOrdered(tourRoutes)
                  dispatch("ORDER_CANCELED")
                }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <CEButton
                title={s.edit}
                onClick={async () => {
                  await updateOrder({
                    expositionId,
                    tourRoutesOrdered: tourRoutesOrdered.map((item, index) => {
                      return { courseId: item._id ?? "", order: index }
                    }),
                  })
                  if (isOrderChanged) {
                    dispatch("ORDER_SAVED")
                  }
                }}
              />
            </ButtonContainer>
          </ButtonsContainer>
        )}
        {currentTourRoute && (
          <TabTourRouteForm
            key={currentTourRoute._id}
            isPublishedExposition={isPublishedExpo}
            expositionId={expositionId}
            onCancel={() => setTourRouteSelected(undefined)}
            isLoading={isLoading}
            languagesAvailable={languagesAvailable}
            tourRoute={currentTourRoute}
            artworks={artworks}
          />
        )}
      </Root>
      {isPublishedExpo ? (
        <CEPublishedExpoModal
          isOpen={isOpenNewTourRouteModal}
          onClose={() => setIsOpenNewTourRouteModal(false)}
        />
      ) : (
        <CECreateTourRouteModal
          expositionId={expositionId}
          isOpen={isOpenNewTourRouteModal}
          onClose={() => setIsOpenNewTourRouteModal(false)}
          onConfirmSave={(createdTourRoute) => {
            setTourRoutesOrdered([...tourRoutesOrdered, createdTourRoute])
            setTourRouteSelected(createdTourRoute._id)
            setIsOpenNewTourRouteModal(false)
          }}
        />
      )}
    </>
  )
}

const Root = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(
    100% - ${NAVIGATION_MENU_FULL_WIDTH}px - 2 * ${TAB_ARTWORK_PADDING}
  );
  padding: ${TAB_ARTWORK_PADDING};
`
export default TabTourRoutes
