import type { DropResult } from "@hello-pangea/dnd"
import { FC, useEffect, useMemo, useState } from "react"

import type { DtoLanguage, DtoPlaylist } from "../../../@types/dto/DtoObjects"
import strings from "../../../assets/strings/fr"
import { useOrderChanged } from "../../../hooks/useOrderChanged"
import { useSaveExpositionPlaylistOrder } from "../../../services/useQueries"
import styled from "../../../styled-components"
import theme from "../../../styled-components/theme"
import { NAVIGATION_MENU_FULL_WIDTH } from "../../layout/CENavigationMenu"
import { CEAddButton, CEButton, CECarousel, CETextPoppins } from "../../ui"
import type { CarouselItem } from "../../ui/CECarousel"
import { CECreatePlaylistModal } from "../../ui/CECreatePlaylistModal"
import { CEPublishedExpoModal } from "../../ui/CEPublishedExpoModal"
import {
  AddButtonContainer,
  CarouselContainer,
  TabTitleContainer,
  tabTitleStyles,
} from "../TabArtworks/TabArtworks"
import { ButtonContainer, ButtonsContainer } from "../TabGeneral/TabGeneralForm"
import { TabPlaylistForm } from "./TabPlaylistsForm"

const TAB_ARTWORK_PADDING = theme.padding.root

const Root = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(
    100% - ${NAVIGATION_MENU_FULL_WIDTH}px - 2 * ${TAB_ARTWORK_PADDING}
  );
  padding: ${TAB_ARTWORK_PADDING};
`

export const TabPlaylists: FC<{
  playlists: DtoPlaylist[]
  expositionId: string
  isPublishedExpo: boolean
  isLoading: boolean
  languagesAvailable: DtoLanguage[]
}> = ({
  expositionId,
  playlists,
  languagesAvailable,
  isLoading,
  isPublishedExpo,
}) => {
  const s = strings.TabPlaylistsPlaylists

  const [playlistsOrdered, setPlaylistsOrdered] = useState(playlists)
  const [playlistSelected, setPlaylistSelected] = useState<string | undefined>(
    undefined
  )

  const [isOpenNewPlaylistModal, setIsOpenNewPlaylistModal] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setPlaylistsOrdered(playlists)
    }
  }, [playlists, isLoading])

  const { mutateAsync: updateOrder } = useSaveExpositionPlaylistOrder()

  const { dispatch, isOrderChanged } = useOrderChanged()

  useEffect(() => {
    for (let i = 0; i < playlists.length; i++) {
      if (playlistsOrdered[i].id !== playlists[i].id) {
        dispatch("ORDER_CHANGED")
        break
      }
    }
  }, [dispatch, playlists, playlistsOrdered])

  const playlistsToCarouselItems: CarouselItem[] = useMemo(
    () =>
      playlistsOrdered.map((playlist) => ({
        id: playlist.id,
        imageUrl: playlist.playlistPictureUrl,
        alt: playlist.expositionMoment?.fr,
      })),
    [playlistsOrdered]
  )

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index

    if (destinationIndex !== undefined) {
      setPlaylistsOrdered((prev) => {
        const movedPlaylist = prev.find((_item, index) => index === sourceIndex)
        let newCarouselPlaylists = prev.filter(
          (_item, index) => index !== sourceIndex
        )

        if (movedPlaylist) {
          newCarouselPlaylists.splice(destinationIndex, 0, movedPlaylist)
          return newCarouselPlaylists
        }
        return prev
      })
    }
  }

  const currentPlaylist = useMemo(
    () => playlistsOrdered.find((a) => a.id === playlistSelected),
    [playlistsOrdered, playlistSelected]
  )

  return (
    <>
      <Root>
        <AddButtonContainer>
          <CEAddButton onClick={() => setIsOpenNewPlaylistModal(true)} />
        </AddButtonContainer>
        <TabTitleContainer>
          <CETextPoppins styles={tabTitleStyles}>{s.tabTitle}</CETextPoppins>
        </TabTitleContainer>
        <CarouselContainer>
          <CECarousel
            items={playlistsToCarouselItems}
            activeId={playlistSelected}
            droppableId="playlistsDroppable"
            onDragEnd={onDragEnd}
            onClick={setPlaylistSelected}
          />
        </CarouselContainer>
        {isOrderChanged && (
          <ButtonsContainer>
            <ButtonContainer>
              <CEButton
                title={strings.button.cancel}
                onClick={() => {
                  setPlaylistsOrdered(playlists)
                  dispatch("ORDER_CANCELED")
                }}
              />
            </ButtonContainer>
            <ButtonContainer>
              <CEButton
                title={s.edit}
                onClick={async () => {
                  await updateOrder({
                    expositionId,
                    playlistIds: playlistsOrdered.map((p) => p.id!),
                  })
                  if (isOrderChanged) {
                    dispatch("ORDER_SAVED")
                  }
                }}
              />
            </ButtonContainer>
          </ButtonsContainer>
        )}
        {currentPlaylist && (
          <TabPlaylistForm
            key={currentPlaylist.id}
            isPublishedExposition={isPublishedExpo}
            expositionId={expositionId}
            onCancel={() => setPlaylistSelected(undefined)}
            isLoading={isLoading}
            languagesAvailable={languagesAvailable}
            playlist={currentPlaylist}
          />
        )}
      </Root>
      {isPublishedExpo ? (
        <CEPublishedExpoModal
          isOpen={isOpenNewPlaylistModal}
          onClose={() => setIsOpenNewPlaylistModal(false)}
        />
      ) : (
        <CECreatePlaylistModal
          onConfirmSave={(createdPlaylist) => {
            setPlaylistsOrdered([...playlistsOrdered, createdPlaylist])
            setPlaylistSelected(createdPlaylist.id)
            setIsOpenNewPlaylistModal(false)
          }}
          expositionId={expositionId}
          isOpen={isOpenNewPlaylistModal}
          onClose={() => setIsOpenNewPlaylistModal(false)}
        />
      )}
    </>
  )
}
