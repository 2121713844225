import type { FC, ReactNode } from "react"

import styled from "../../styled-components"

// STYLED COMPONENTS
const ModalBodyContainer = styled.div`
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.primary.main};
  border-top: none;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.modal};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.modal};
`

const ModalBody = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
  overflow: auto;
`

/**
 * Modal body with custom style
 * Div with custom style to wrap the body of a modal
 * Just some padding and a border
 */
const CEModalBody: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ModalBodyContainer>
      <ModalBody>{children}</ModalBody>
    </ModalBodyContainer>
  )
}

export default CEModalBody
